const l = {
  language: "Deutsch",
  "app.error": "Fehler",
  "app.close": "Schließen",
  "app.start": "Start",
  "app.noConversations": "Noch keine Gespräche",
  "app.conversation": "Gespräch",
  "app.conversations": "Gespräche",
  "app.loading": "Lädt …",
  "app.tryAgain": "Bitte versuchen Sie es erneut",
  "app.signOut": "Abmelden",
  "app.next": "Weiter",
  "app.previous": "Zurück",
  "app.finish": "Beenden",
  "app.cancel": "Abbrechen",
  "app.restart": "Neu starten",
  "layout.unableToConnect": "Verbindung zum Server nicht möglich",
  "layout.home": "Startseite",
  "layout.chat": "Tägliche Sitzung",
  "layout.wordbook": "Vokabelbuch",
  "layout.pronunciation": "Aussprache",
  "layout.programs": "Programme",
  "layout.settings": "Einstellungen",
  "layout.account": "Konto",
  "layout.manageAccount": "Konto verwalten",
  "layout.leaveReview": "Eine Bewertung abgeben",
  "dashboard.title": "Startseite",
  "dashboard.subtitle":
    "Wählen Sie aus, wie Sie mit Ihrem Tutor interagieren möchten.",
  "dashboard.aiTutors.title": "KI-Tutoren",
  "dashboard.aiTutors.description":
    "Ihr ausgewählter Tutor wird in der nächsten Chat-Sitzung auf Sie warten. Bis bald!",
  "dashboard.template.freeTalk.title": "Neue Sitzung",
  "dashboard.template.freeTalk.description":
    "Sprechen Sie mit Ihrem Tutor über alles, was Sie möchten",
  "dashboard.template.rolePlay.title": "Rollenspiel",
  "dashboard.template.rolePlay.description":
    "Versetzen Sie sich in verschiedene Rollen und spielen Sie die Szenarien durch",
  "dashboard.template.topics.title": "Thema",
  "dashboard.template.topics.description": "Über ein Thema Ihrer Wahl sprechen",
  "dashboard.template.caseStudy.title": "Falldiskussion",
  "dashboard.template.caseStudy.description":
    "Besprechen Sie einen Fall und beantworten Sie Fragen dazu",
  "dashboard.template.groupDiscussion.title": "Gruppendiskussion",
  "dashboard.template.groupDiscussion.description":
    "Gruppendiskussionen ohne Leiter üben",
  "dashboard.template.quiz.title": "Quiz",
  "dashboard.template.quiz.description":
    "Wörter und Sätze mit spannenden Quiz üben",
  "dashboard.template.game.title": "Spiele",
  "dashboard.template.game.description":
    "Vokabeln mit lustigen Wortspielen üben",
  "dashboard.template.modal.curated": "Kuratiert",
  "dashboard.template.modal.custom": "Benutzerdefiniert",
  "dashboard.template.modal.inputContent": "Geben Sie Ihre eigenen Inhalte ein",
  "dashboard.conversations.description":
    "Ihre vollständige Liste der Gespräche",
  "chat.index.failedConnection":
    "Der Server konnte nicht erreicht und die Nachricht nicht gesendet werden",
  "chat.index.audioFailedToSend": "Fehler beim Senden der Sprachnachricht",
  "chat.index.usageCapMessageTrialTitle": "Wow, machen Sie weiter so!",
  "chat.index.usageCapMessageTrialText":
    "Es sieht so aus, als hätten Sie das Beste aus Ihrem 7-tägigen kostenlosen Probe-Abo gemacht – das ist großartig! Sie haben das Zeichenlimit für diesen Zeitraum erreicht. Möchten Sie weitermachen? Sie können Ihr Abonnement jederzeit upgraden und genau dort weitermachen, wo Sie aufgehört haben.",
  "chat.index.usageCapMessageTrialFooterText":
    "Wir sind hier, um Sie bei jedem Schritt auf Ihrem Weg zu unterstützen.",
  "chat.index.usageCapMessagePlanTitle": "Achtung!",
  "chat.index.usageCapMessagePlanText":
    "Sie haben diesen Monat alle Funktionen ausgiebig genutzt – gut gemacht! Sie haben Ihr Nutzungslimit für das aktuelle Abo erreicht. Damit Sie weiterhin ein nahtloses Erlebnis haben, sollten Sie sich einen Moment Zeit nehmen, um unsere Tarifoptionen zu prüfen.",
  "chat.index.usageCapMessagePlanFooterText":
    "Keine Sorge – Ihr aktueller Plan wird nächsten Monat zurückgesetzt. Wir freuen uns, dass Sie bei uns sind!",
  "chat.index.visitAccount": "Konto besuchen",
  "chat.index.noMicTitle": "Mikrofon nicht erlaubt",
  "chat.index.noMicText":
    "Der Mikrofonzugriff wird von Ihrem Browser blockiert",
  "chat.asideMenu.voice": "Stimme",
  "chat.asideMenu.text": "Text",
  "chat.asideMenu.newChat": "Neuer Chat",
  "chat.asideMenu.pastConversations": "Vergangene Gespräche",
  "chat.asideMenu.noConversations": "Keine Gespräche",
  "chat.asideMenu.autoTranslate": "Automatische Übersetzung",
  "chat.asideMenu.showRomanization": "Umschrift anzeigen",
  "chat.asideMenu.multiLingualSpeech": "Mehrsprachige Rede",
  "chat.suggestions.titleSm": "Antwort vorschlagen",
  "chat.suggestions.titleXs": "Vorschlag",
  "chat.suggestions.regenerate": "Neu generieren",
  "chat.suggestions.generating": "Generierung …",
  "chat.suggestions.failed":
    "Vorschlag konnte nicht abgerufen werden. Bitte versuchen Sie es erneut.",
  "chat.conversation.tryAgain": "Versuchen Sie es noch einmal",
  "chat.conversation.cancel": "Abbrechen",
  "chat.speakInput.speak": "Sprechen",
  "chat.speakInput.recording": "Aufnahme",
  "chat.textInput.sendMessage": "Nachricht senden",
  "chat.autoSubmitSwitch.handsFree": "Freihändig",
  "chat.shortcuts.hold": "Halten",
  "chat.shortcuts.press": "Drücken",
  "chat.shortcuts.release": "Loslassen",
  "chat.shortcuts.toRecord": "um aufzuzeichnen",
  "chat.shortcuts.toSend": "um zu senden",
  "chat.shortcuts.toCancel": "um abzubrechen",
  "chat.userMessageActions.speechPrecision": "Sprachgenauigkeit",
  "chat.userMessageActions.speechPrecisionDescription":
    "Die Sprachgenauigkeit gibt an, wie sicher der Tutor Ihre Nachricht korrekt interpretiert hat. Ein höherer Prozentsatz bedeutet eine höhere Genauigkeit beim Verstehen des Gesagten.",
  "chat.userMessageActions.speechPrecisionShowInfo": "Info anzeigen",
  "chat.userMessageActions.speechPrecisionHideInfo": "Info verbergen",
  "chat.userMessageActions.improveMyAnswer": "Meine Antwort verbessern",
  "chat.userMessageActions.retry": "Erneut versuchen",
  "chat.feedbackMessageButton.improveMyAnswer":
    "Meine letzte Antwort verbessern",
  "chat.feedbackMessageButton.feedbackOn": "Feedback zu",
  "chat.feedbackMessageButton.speechPrecision": "Sprachgenauigkeit",
  "chat.assessmentButton.title": "Gesprächsbewertung",
  "chat.assessmentButton.close": "Schließen",
  "chat.assessmentButton.analyzing": "Das gesamte Gespräch analysieren",
  "chat.assessmentButton.tooShort":
    "Das Gespräch ist zu kurz, um bewertet zu werden",
  "settings.title": "Einstellungen",
  "settings.subtitle": "Passen Sie Ihre Interaktionen mit dem Tutor an.",
  "settings.language": "Sprache",
  "settings.yourLanguage": "Ihre Sprache",
  "settings.uiLanguage": "Sprache der Benutzeroberfläche",
  "settings.practiceLanguage": "Übungssprache",
  "settings.proficiency": "Sprachkenntnisse",
  "settings.tutor": "Tutor",
  "settings.name": "Name",
  "settings.talkingSpeed": "Sprechgeschwindigkeit",
  "settings.speechInput": "Spracheingabe",
  "settings.precisionVoiceInputDescription":
    "Bietet außergewöhnliche Genauigkeit für die wichtigsten lateinischen und germanischen Sprachen, einschließlich Englisch, Deutsch, Spanisch, Französisch und Italienisch, sowie für asiatische Sprachen wie Koreanisch, Thai und Japanisch. Wenn Sie die Quergespräch-Methode verwenden und die mehrsprachige Spracherkennung aktivieren möchten, wählen Sie bitte die Standard-Engine. ",
  "settings.standardVoiceInputDescription":
    "Vielseitige Engine, die alle unsere Sprachen unterstützt. Sie ist ideal für Quergespräche und mehrsprachige Spracherkennung.",
  "settings.autoPunctuation": "Automatische Zeichensetzung",
  "settings.autoPunctuationDescription":
    "Automatisch Satzzeichen hinzufügen, wenn Sie eine Pause machen",
  "settings.multiLingualSpeechRecognition": "Mehrsprachige Spracherkennung",
  "settings.multiLingualSpeechRecognitionDescription":
    "Der Tutor erkennt die Sprache sowohl in der Übungssprache als auch in Ihrer Muttersprache",
  "settings.translations": "Übersetzungen",
  "settings.autoTranslate": "Automatische Übersetzung",
  "settings.autoTranslateDescription":
    "Nachrichten vom Tutor automatisch in Ihre Muttersprache übersetzen",
  "settings.showRomanization": "Umschrift anzeigen",
  "settings.showRomanizationDescription":
    "Umschrift der neuesten Chat-Nachricht anzeigen",
  "wordbook.title": "Vokabelbuch",
  "wordbook.subtitle":
    "Verfolgen Sie die Wörter, die Sie lernen; Ihr Tutor wird Ihnen helfen, sie zu verwenden, um Ihr Gedächtnis zu stärken",
  "wordbook.noWord": "Keine Wörter für die gewählte Sprache gespeichert",
  "wordbook.yourPracticeLanguage": "Ihre Übungssprache",
  "wordbook.word": "Wort",
  "wordbook.addToWordbook": "Zum Vokabelbuch hinzufügen",
  "wordbook.pleaseEnterIn":
    "Bitte geben Sie ein Wort auf {practice language} ein",
  "wordbook.selectWord": "Wählen Sie ein Wort aus",
  "pronunciation.title": "Aussprache",
  "pronunciation.subtitle": "Aussprache und flüssiges Sprechen üben",
  "pronunciation.intro":
    "Der Tutor wird geeignete Sätze generieren, die auf Ihr Kenntnisniveau abgestimmt sind, das auf {proficiency level} gesetzt ist",
  "pronunciation.startPracticing": "Mit dem Üben beginnen",
  "pronunciation.done": "Fertig",
  "pronunciation.cancel": "Abbrechen",
  "pronunciation.tryAgain": "Bitte versuchen Sie es erneut",
  "pronunciation.wordByWord.loading": "Lädt …",
  "pronunciation.wordByWord.stopReading": "Lesen beenden",
  "pronunciation.wordByWord.readSentence": "Satz lesen",
  "pronunciation.wordByWord.newSentence": "Neuer Satz",
  "pronunciation.wordByWord.retrySentence": "Satz wiederholen",
  "pronunciation.saveProgress.progressSaved":
    "Ihr Aussprachefortschritt wurde gespeichert",
  "pronunciation.saveProgress.progressNotSaved":
    "Ihr Aussprachefortschritt konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.",
  "pronunciation.saveProgress.saveProgress":
    "Satz und Punktzahl speichern, um Fortschritte zu verfolgen, und es später erneut versuchen.",
  "pronunciation.saveProgress.saved": "Gespeichert",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Satz und Bewertung speichern",
  "pronunciation.assessmentStats.noResults": "Kein Ergebnis anzuzeigen",
  "pronunciation.assessmentStats.pronunciation": "Aussprache",
  "pronunciation.assessmentStats.pronunciationScore": "Aussprachenpunktzahl",
  "pronunciation.assessmentStats.pronunciationDescription":
    "Die Aussprachenpunktzahl repräsentiert die Qualität der Aussprache in der gegebenen Rede. Sie wird berechnet, indem die Punktzahlen für Genauigkeit, Sprachflüssigkeit und  Vollständigkeit kombiniert werden, wobei jede mit einer bestimmten Gewichtung versehen ist.",
  "pronunciation.assessmentStats.accuracy": "Genauigkeit",
  "pronunciation.assessmentStats.accuracyScore": "Aussprachegenauigkeit",
  "pronunciation.assessmentStats.accuracyDescription":
    "Die Aussprachegenauigkeit misst, wie gut gesprochene Wörter mit denen eines Muttersprachlers übereinstimmen. Genauigkeitspunkte für Silben, Wörter und vollständigen Text basieren auf phonemischer Genauigkeit und werden gemäß den Bewertungszielen angepasst.",
  "pronunciation.assessmentStats.completeness": "Vollständigkeit",
  "pronunciation.assessmentStats.completenessScore": "Vollständigkeit",
  "pronunciation.assessmentStats.completenessDescription":
    "Vollständigkeit der Rede wird bestimmt, indem die Anzahl der ausgesprochenen Wörter mit der Gesamtanzahl der Wörter im Referenztext verglichen wird.",
  "pronunciation.assessmentStats.fluency": "Sprachflüssigkeit",
  "pronunciation.assessmentStats.fluencyScore": "Sprachflüssigkeit",
  "pronunciation.assessmentStats.fluencyDescription":
    "Sprachflüssigkeit der gegebenen Rede. Sprachflüssigkeit zeigt an, wie eng die Rede den stillen Pausen zwischen den Wörtern eines Muttersprachlers entspricht.",
  "pronunciation.assessmentStats.keepPracticing": "Üben Sie weiter!",
  "pronunciation.assessmentStats.makingProgress": "Sie machen Fortschritte!",
  "pronunciation.assessmentStats.wellDone": "Gut gemacht!",
  "pronunciation.assessmentStats.almostThere": "Fast geschafft!",
  "pronunciation.assessmentStats.excellentJob": "Hervorragende Arbeit!",
  "pronunciation.history.saveAssessments": "Gespeicherte Bewertungen",
  "pronunciation.history.noAssessments": "Noch keine Bewertungen",
  "pronunciation.history.score": "Punktzahl",
  "pronunciation.history.tryAgain": "Erneut versuchen",
  "account.unableToLoad": "Sprache",
  "account.title": "Was ist Ihre Muttersprache?",
  "account.renewFailed": "Sprache",
  "account.reviewPaymentDetails": "Welche Sprache möchten Sie üben?",
  "account.subscriptionInactive": "Kenntnis",
  "account.creditCardDeclined":
    "Wie ist Ihr Kenntnisstand in {practiceLanguage}?",
  "account.updateCreditCard": "Sie sind startklar!",
  "account.subscriptionManagementPage": "Zeit, zu sprechen",
  "account.questionContactUs":
    "Sprache nicht erkannt oder Lautstärke zu niedrig",
  "account.billing": "Pinyin konnte nicht abgerufen werden",
  "account.plan": "Verbindung zum Aussprachebewertungsserver nicht möglich",
  "account.amount": "Keine Sprache erkannt",
  "account.usage": "Fehler",
  "account.usageDescription":
    "Gespeicherte Bewertung konnte nicht geladen werden",
  "account.requestRefund":
    "Sind Sie sicher, dass Sie dieses Wort löschen möchten?",
  "account.manageSubscription":
    "Wort konnte nicht gelöscht werden. Bitte versuchen Sie es in wenigen Sekunden erneut.",
  "intro.title": "Willkommen bei {serviceName}",
  "intro.subtitle": "Lassen Sie uns mit ein paar Fragen beginnen",
  "intro.userLanguageTitle": "Sprache",
  "intro.userLanguageSubtitle": "Was ist Ihre Muttersprache?",
  "intro.practiceLanguageTitle": "Sprache",
  "intro.practiceLanguageSubtitle": "Welche Sprache möchten Sie üben?",
  "intro.proficiencyTitle": "Kenntnisstand",
  "intro.proficiencyDescription":
    "Wie ist Ihr Kenntnisstand in {practiceLanguage}?",
  "intro.completionModal.title": "Sie sind startklar!",
  "intro.completionModal.buttonText": "Zeit, zu sprechen",
  "hooks.useConversation.noSpeech":
    "Sprache nicht erkannt oder Lautstärke zu niedrig",
  "hooks.useAssessment.unableToFetchPinyin":
    "Pinyin konnte nicht abgerufen werden",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Verbindung zum Aussprachebewertungsserver nicht möglich",
  "hooks.useAssessment.noSpeech": "Keine Sprache erkannt",
  "hooks.useAssessment.retryError": "Fehler",
  "hooks.useAssessment.retryErrorDescription":
    "Gespeicherte Bewertung konnte nicht geladen werden",
  "hooks.useWordbook.deleteConfirm":
    "Sind Sie sicher, dass Sie dieses Wort löschen möchten?",
  "hooks.useWordbook.unableToDeleteWord":
    "Wort konnte nicht gelöscht werden. Bitte versuchen Sie es in wenigen Sekunden erneut.",
  "components.conversationDownloadButton.export": "Chat exportieren",
  "components.micPermissionButton.notAllowed": "Mikrofonzugriff nicht erlaubt",
  "components.micPermissionButton.turnOnMicAndSound":
    "Mikrofon und Ton einschalten",
  "components.micPermissionButton.pleaseAllow":
    "Bitte Mikrofonzugriff erlauben",
  "components.languageSelector.managedByOrganization":
    "Von der Organisation verwaltet",
  "components.languageSelector.selectLanguage": "Sprache auswählen",
  "components.languageSelector.fullList": "Vollständige Liste anzeigen",
  "components.languageSelector.simpleList": "Vereinfachte Liste anzeigen",
  "components.proficiencySelector.helpMeChoose":
    "Helfen Sie mir bei der Auswahl",
  "components.proficiencySelector.descriptionsTitle":
    "Beschreibungen der Kenntnisse",
  "components.proficiencySelector.descriptionsSubtitle":
    "Hier sind Beschreibungen der verschiedenen Sprachkenntnisstufen.",
  "components.proficiencySelector.beginnerLabel": "Einsteiger",
  "components.proficiencySelector.beginnerDescription":
    "Die Person kann grundlegende Ausdrücke verwenden, um praktische Bedürfnisse mit klarer Kommunikation und Bereitschaft von anderen zu erfüllen.",
  "components.proficiencySelector.elementaryLabel": "Anfänger",
  "components.proficiencySelector.elementaryDescription":
    "Die Person versteht wichtige Themen, kommuniziert effektiv in Routineangelegenheiten und kann ihren Hintergrund und ihre Erfordernisse erklären.",
  "components.proficiencySelector.intermediateLabel": "Mittleres Niveau",
  "components.proficiencySelector.intermediateDescription":
    "Die Person versteht und kommuniziert grundlegende Informationen zu allgemeinen Themen, bewältigt Reisesituationen und drückt persönliche Erfahrungen, Meinungen und Pläne aus.",
  "components.proficiencySelector.upperIntermediateLabel":
    "Höheres mittleres Niveau",
  "components.proficiencySelector.upperIntermediateDescription":
    "Die Person versteht komplexe praktische und theoretische Texte, kommuniziert fließend mit Muttersprachlern, erstellt umfassende Texte und drückt ihre Perspektive zu aktuellen Themen aus.",
  "components.proficiencySelector.advancedLabel": "Fortgeschritten",
  "components.proficiencySelector.advancedDescription":
    "Die Person versteht komplexe Texte mit impliziten Bedeutungen, kommuniziert fließend und versiert und erstellt gut strukturierte und schlüssige Texte zu komplizierten Themen.",
  "components.proficiencySelector.close": "Schließen",
  "components.readSpeedSlider.speed": "Geschwindigkeit",
  "components.readSpeedSlider.verySlow": "Sehr langsam",
  "components.readSpeedSlider.slow": "Langsam",
  "components.readSpeedSlider.normal": "Normal",
  "components.readSpeedSlider.fast": "Schnell",
  "components.readSpeedSlider.veryFast": "Sehr schnell",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Engine für Spracheingabe",
  "components.speechRecognitionSelector.default": "Standard",
  "components.speechRecognitionSelector.alternative": "Präzision",
  "components.saveWord.save": "Speichern",
  "components.saveWord.notUnderstood":
    "Die KI hat dieses Wort nicht verstanden. Bitte versuchen Sie es erneut.",
  "components.saveWord.failed":
    "Speichern fehlgeschlagen. Bitte versuchen Sie es erneut.",
  "components.saveWord.savedToWordbook": "im Wörterbuch gespeichert",
  "components.wordInformation.content.description": "Beschreibung",
  "components.wordInformation.content.sentences": "Sätze",
  "components.textWithActions.readAloud": "Laut vorlesen",
  "components.textWithActions.saveWord": "Wort speichern",
  "components.textWithActions.learnMore": "Mehr erfahren",
  "components.pwaPromptIos.title": "Installieren Sie {app} auf Ihrem Gerät",
  "components.pwaPromptIos.step1": "Tippen Sie auf das Teilen-Symbol",
  "components.pwaPromptIos.step2":
    "Wählen Sie „Zum Startbildschirm hinzufügen“",
  "components.pwaPromptIos.step3": "Kein App Store benötigt!",
  "components.pwaPromptAndroid.title": "App installieren",
  "chat.userMessage.perfectSentence1": "Großartiger Satz! Weiter so!",
  "chat.userMessage.perfectSentence2": "Gute Arbeit!",
  "chat.userMessage.perfectSentence3": "Gut gemacht!",
  "settings.liveFeedback": "Sofortiges Feedback",
  "settings.liveFeedbackDescription":
    "Zeigt nach jeder Nachricht Feedback an, wie Sie Ihre Antwort verbessern können.",
  "dashboard.template.dailySession.title": "Tägliche Sitzung",
  "dashboard.template.dailySession.with": "mit",
  "dashboard.template.dailySession.description":
    "Nehmen Sie an der heutigen Konversation teil, um Ihre Sprachkenntnisse zu trainieren und zu verbessern",
  "dashboard.template.dailySession.button": "Tägliche Sitzung beginnen",
  "feedbackCard.loader.analyzeGrammar": "Analysiere Grammatik …",
  "feedbackCard.loader.analyzeVocabulary": "Analysiere Vokabular …",
  "feedbackCard.loader.analyzeStructure": "Analysiere Satzstruktur …",
  "feedbackCard.error.message":
    "Es tut uns leid, aber wir haben ein Problem bei der Verarbeitung Ihres Feedbacks festgestellt. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
  "feedbackCard.improvedAnswerLabel": "Verbesserte Antwort",
  "feedbackCard.grammarLabel": "Grammatik",
  "feedbackCard.vocabularyLabel": "Vokabular",
  "feedbackCard.structureLabel": "Struktur",
};

export default l;
