const l = {
  "settings.title": "Configurações",
  "settings.subtitle": "Personalize sua experiência com o instrutor.",
  "settings.language": "Idioma",
  "settings.yourLanguage": "Seu Idioma",
  "settings.uiLanguage": "Idioma da interface",
  "settings.practiceLanguage": "Idioma de prática",
  "settings.proficiency": "proficiência",
  "settings.tutor": "Instrutor",
  "settings.name": "Nome",
  "settings.talkingSpeed": "Velocidade da fala",
  "settings.speechInput": "Entrada de voz",

  "settings.autoPunctuation": "Pontuação automática",
  "settings.autoPunctuationDescription":
    "Adicione automaticamente pontuação ao pausar",
  "settings.multiLingualSpeechRecognition":
    "Reconhecimento de fala multilíngue",
  "settings.multiLingualSpeechRecognitionDescription":
    "O instrutor reconhecerá a fala tanto no idioma de prática quanto no seu idioma nativo",
  "settings.translations": "Traduções",
  "settings.autoTranslate": "Tradução automática",
  "settings.autoTranslateDescription":
    "Traduza automaticamente as mensagens do instrutor para o seu idioma",
  "settings.showRomanization": "Mostrar Romanização",
  "settings.showRomanizationDescription":
    "Mostra a romanização da última mensagem do chat para idiomas de script",
  "wordbook.title": "Livro de Palavras",
  "wordbook.subtitle": "Acompanhe as palavras que você aprende",
  "wordbook.noWord": "Nenhuma palavra salva para o idioma escolhido",
  "wordbook.yourPracticeLanguage": "Seu idioma de prática",
  "wordbook.word": "Palavra",
  "wordbook.addToWordbook": "Adicionar à Livro de Palavras",
  "wordbook.pleaseEnterIn": "Por favor, digite uma palavra em",
  "wordbook.selectWord": "Selecione uma palavra",
  "pronunciation.title": "Pronúncia",
  "pronunciation.subtitle": "Pratique pronúncia e fluência",
  "pronunciation.intro":
    "O instrutor irá gerar frases adequadas, adaptadas ao seu nível de proficiência, que está configurado para",
  "pronunciation.startPracticing": "Comece a praticar",
  "pronunciation.done": "Concluído",
  "pronunciation.cancel": "Cancelar",
  "pronunciation.tryAgain": "Por favor, tente novamente",
  "pronunciation.wordByWord.loading": "Carregando...",
  "pronunciation.wordByWord.stopReading": "Parar leitura",
  "pronunciation.wordByWord.readSentence": "Ler frase",
  "pronunciation.wordByWord.newSentence": "Nova frase",
  "pronunciation.wordByWord.retrySentence": "Tentar novamente",
  "pronunciation.saveProgress.progressSaved":
    "Seu progresso de pronúncia foi salvo",
  "pronunciation.saveProgress.progressNotSaved":
    "Não foi possível salvar seu progresso de pronúncia. Por favor, verifique sua conexão e tente novamente.",
  "pronunciation.saveProgress.saveProgress":
    "Salvar frase e pontuação para acompanhar o progresso e tentar novamente depois.",
  "pronunciation.saveProgress.saved": "Salvo",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Salvar frase e avaliação",
  "pronunciation.assessmentStats.noResults": "Nenhum resultado para mostrar",
  "pronunciation.assessmentStats.pronunciation": "Pronúncia",
  "pronunciation.assessmentStats.pronunciationScore": "Pontuação de pronúncia",
  "pronunciation.assessmentStats.pronunciationDescription":
    "A Pontuação de Pronúncia representa a qualidade da pronúncia na fala fornecida. É calculada combinando a Pontuação de Precisão, Pontuação de Fluência e Pontuação de Compleição, cada uma atribuída com um peso específico.",
  "pronunciation.assessmentStats.accuracy": "Precisão",
  "pronunciation.assessmentStats.accuracyScore": "Precisão de pronúncia",
  "pronunciation.assessmentStats.accuracyDescription":
    "A precisão da pronúncia mede o quão bem as palavras faladas se assemelham às de um falante nativo. As pontuações de precisão para sílabas, palavras e texto completo são baseadas na precisão do fonema e ajustadas de acordo com os objetivos da avaliação.",
  "pronunciation.assessmentStats.completeness": "Completude",
  "pronunciation.assessmentStats.completenessScore": "Completude",
  "pronunciation.assessmentStats.completenessDescription":
    "A completude da fala é determinada comparando o número de palavras pronunciadas com o número total de palavras no texto de referência.",
  "pronunciation.assessmentStats.fluency": "Fluência",
  "pronunciation.assessmentStats.fluencyScore": "Fluência",
  "pronunciation.assessmentStats.fluencyDescription":
    "Fluência da fala fornecida. A fluência indica o quão próxima a fala corresponde ao uso de pausas silenciosas entre palavras de um falante nativo.",
  "pronunciation.assessmentStats.keepPracticing": "Continue praticando!",
  "pronunciation.assessmentStats.makingProgress": "Fazendo progresso!",
  "pronunciation.assessmentStats.wellDone": "Bem feito!",
  "pronunciation.assessmentStats.excellentJob": "Trabalho excelente!",
  "pronunciation.history.saveAssessments": "Avaliações salvas",
  "pronunciation.history.noAssessments": "Nenhuma avaliação ainda",
  "pronunciation.history.score": "Pontuação",
  "pronunciation.history.tryAgain": "Tente novamente",
  "account.unableToLoad": "Não é possível carregar detalhes do usuário",
  "account.title": "Conta",
  "account.renewFailed": "Falha ao renovar a assinatura",
  "account.reviewPaymentDetails": "Por favor, revise seus dados de pagamento",
  "account.subscriptionInactive": "Assinatura inativa",
  "account.creditCardDeclined":
    "A transação do seu cartão de crédito foi recusada.",
  "account.updateCreditCard":
    "Para continuar usando Talkio AI, forneça outro método de pagamento ou atualize seus dados de pagamento na",
  "account.subscriptionManagementPage": "página de gerenciamento de assinatura",
  "account.questionContactUs":
    "Se você tiver alguma dúvida ou comentário, não hesite em entrar em contato conosco em",
  "account.billing": "Faturamento",
  "account.plan": "Plano",
  "account.amount": "Valor",
  "account.usage": "Uso",
  "account.usageDescription":
    "O limite de uso é determinado pelo total de caracteres envolvidos em conversas, que inclui tanto a entrada de texto enviada para o AI quanto o texto gerado pelo AI em resposta. Este limite é redefinido mensalmente. {limit} caracteres equivalem aproximadamente a {estHours} horas de conversação.",
  "account.requestRefund": "Solicitar reembolso",
  "account.manageSubscription": "Gerenciar assinatura",
  "account.subscriptionManagedBy": "Sua assinatura é gerenciada por",
  "account.contactAdmin":
    "Por favor, entre em contato com o administrador de sua organização para alterar ou cancelar sua assinatura.",
  "account.subscriptionManagedByPartner": "Sua assinatura é gerenciada por",
  "account.contactPartner":
    "Por favor, entre em contato com {userPlanId} para alterar ou cancelar sua assinatura.",
  "account.goToSubscriptionManagement": "Ir para o gerenciamento de assinatura",
  "account.feedback": "Suporte",
  "account.feedbackDescription":
    "Seu feedback é inestimável para nós! Se você tiver alguma sugestão ou áreas que acha que poderíamos melhorar, por favor, não hesite em",
  "account.feedbackCallToAction": "compartilhar seus pensamentos",
  "account.reviewDescription":
    "Se você está gostando do aplicativo, ficaríamos gratos por",
  "account.reviewCallToAction": "uma avaliação!",
  "account.signOutDescription":
    "Faça logout de sua conta usando o botão abaixo.",
  "account.connection": "Conexão",
  "intro.title": "Bem-vindo à {serviceName}",
  "intro.subtitle": "Vamos começar com algumas perguntas",
  "intro.userLanguageTitle": "Idioma",
  "intro.userLanguageSubtitle": "Qual é o seu idioma nativo?",
  "intro.practiceLanguageTitle": "Idioma",
  "intro.practiceLanguageSubtitle": "Qual idioma você quer praticar?",
  "intro.proficiencyTitle": "Proficiência",
  "intro.proficiencyDescription":
    "Qual é a sua proficiência em {practiceLanguage}",
  "intro.completionModal.title": "Você está pronto(a)!",
  "intro.completionModal.buttonText": "Hora de começar a falar",
  "hooks.useConversation.noSpeech": "Fala não detectada ou volume muito baixo",
  "hooks.useAssessment.unableToFetchPinyin": "Não é possível buscar o Pinyin",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Não é possível se conectar ao servidor de avaliação de pronúncia",
  "hooks.useAssessment.noSpeech": "Nenhuma fala detectada",
  "hooks.useAssessment.retryError": "Erro",
  "hooks.useAssessment.retryErrorDescription":
    "Não é possível carregar a avaliação salva",
  "hooks.useWordbook.deleteConfirm":
    "Tem certeza de que deseja excluir esta palavra?",
  "hooks.useWordbook.unableToDeleteWord":
    "Não é possível excluir a palavra. Por favor, tente novamente em alguns segundos.",
  "components.miniTranslator.translate": "Traduzir",
  "components.miniTranslator.to": "para",
  "components.conversationDownloadButton.export": "Exportar Chat",
  "components.micPermissionButton.notAllowed":
    "Acesso ao microfone não permitido",
  "components.micPermissionButton.turnOnMicAndSound": "Ativar microfone e som",
  "components.micPermissionButton.pleaseAllow":
    "Por favor, permita o acesso ao microfone",
  "components.languageSelector.managedByOrganization":
    "Gerenciado pela organização",
  "components.languageSelector.selectLanguage": "Selecionar Idioma",
  "components.languageSelector.fullList": "Mostrar lista completa",
  "components.languageSelector.simpleList": "Mostrar lista simplificada",
  "components.proficiencySelector.helpMeChoose": "Me ajude a escolher",
  "components.proficiencySelector.descriptionsTitle":
    "Descrições de proficiência",
  "components.proficiencySelector.descriptionsSubtitle":
    "Aqui estão descrições dos diferentes níveis de proficiência em idiomas.",
  "components.proficiencySelector.beginnerLabel": "Iniciante",
  "components.proficiencySelector.beginnerDescription":
    "O indivíduo pode usar expressões básicas para atender às necessidades práticas com comunicação clara e disposição dos outros.",
  "components.proficiencySelector.elementaryLabel": "Elementar",
  "components.proficiencySelector.elementaryDescription":
    "O indivíduo entende tópicos importantes, comunica efetivamente em assuntos de rotina e pode explicar seu histórico e necessidades.",
  "components.proficiencySelector.intermediateLabel": "Intermediário",
  "components.proficiencySelector.intermediateDescription":
    "O indivíduo entende e comunica informações básicas sobre assuntos comuns, gerencia situações de viagem e expressa experiências pessoais, opiniões e planos.",
  "components.proficiencySelector.upperIntermediateLabel":
    "Intermediário Avançado",
  "components.proficiencySelector.upperIntermediateDescription":
    "O indivíduo compreende textos práticos e teóricos complexos, se comunica fluentemente com falantes nativos, gera textos abrangentes e expressa sua perspectiva sobre questões atuais.",
  "components.proficiencySelector.advancedLabel": "Avançado",
  "components.proficiencySelector.advancedDescription":
    "O indivíduo compreende textos complexos com significado implícito, comunica-se fluentemente e proficientemente, e gera texto bem construído e coerente sobre tópicos complicados.",
  "components.proficiencySelector.close": "Fechar",
  "components.readSpeedSlider.speed": "Velocidade",
  "components.readSpeedSlider.verySlow": "Muito lento",
  "components.readSpeedSlider.slow": "Lento",
  "components.readSpeedSlider.normal": "Normal",
  "components.readSpeedSlider.fast": "Rápido",
  "components.readSpeedSlider.veryFast": "Muito rápido",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Motor de Entrada de Voz",
  "components.speechRecognitionSelector.default": "Padrão",
  "components.speechRecognitionSelector.alternative": "Alternativo",
  "components.saveWord.save": "Salvar",
  "components.saveWord.notUnderstood":
    "O AI não entendeu esta palavra. Por favor, tente novamente.",
  "components.saveWord.failed": "Falha ao salvar. Por favor, tente novamente.",
  "components.saveWord.savedToWordbook": "salvo na sua livro de palavras",
  "components.wordInformation.content.description": "Descrição",
  "components.wordInformation.content.sentences": "Frases",
  "components.textWithActions.readAloud": "Ler em voz alta",
  "components.textWithActions.saveWord": "Salvar palavra",
  "components.textWithActions.learnMore": "Saiba mais",
};

export default l;
