import { Router } from "next/router";
import LayoutApp from "./layout-app";
import LayoutHub from "./layout-hub";
import LayoutLanding from "./layout-landing";

interface Layout {
  children: React.ReactNode;
  router: Router;
}

export default function Layout({ children, router }: Layout) {
  if (router.pathname.startsWith("/hub")) {
    return <LayoutHub>{children}</LayoutHub>;
  }

  if (router.pathname.startsWith("/app")) {
    return <LayoutApp>{children}</LayoutApp>;
  }

  return <LayoutLanding>{children}</LayoutLanding>;
}
