import { Quiz } from "../../../services/prompts/templates-v2/quizzes";

export const quizzes: Quiz[] = [
  {
    id: "science-trivia",
    title: "Quiz de Curiosidades sobre Ciência",
    description: "Teste seus conhecimentos sobre vários fatos científicos.",
    prompt:
      "I will ask you a series of questions about science. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "What planet is known as the Red Planet?",
          options: ["Earth", "Mars", "Jupiter"],
          correctAnswer: "Mars",
        },
        {
          question: "What is the chemical symbol for water?",
          options: ["H2O", "O2", "CO2"],
          correctAnswer: "H2O",
        },
        {
          question: "What gas do plants absorb from the atmosphere?",
          options: ["Oxygen", "Nitrogen", "Carbon Dioxide"],
          correctAnswer: "Carbon Dioxide",
        },
        {
          question: "What is the hardest natural substance on Earth?",
          options: ["Gold", "Iron", "Diamond"],
          correctAnswer: "Diamond",
        },
        {
          question: "How many bones are in the human body?",
          options: ["206", "208", "210"],
          correctAnswer: "206",
        },
        {
          question: "What is the center of an atom called?",
          options: ["Proton", "Neutron", "Nucleus"],
          correctAnswer: "Nucleus",
        },
        {
          question: "What planet is closest to the Sun?",
          options: ["Venus", "Mercury", "Earth"],
          correctAnswer: "Mercury",
        },
        {
          question: "What is the most abundant gas in Earth's atmosphere?",
          options: ["Oxygen", "Carbon Dioxide", "Nitrogen"],
          correctAnswer: "Nitrogen",
        },
        {
          question: "What is the largest organ in the human body?",
          options: ["Heart", "Skin", "Liver"],
          correctAnswer: "Skin",
        },
        {
          question: "What force keeps us on the ground?",
          options: ["Magnetism", "Friction", "Gravity"],
          correctAnswer: "Gravity",
        },
      ],
    },
  },
  {
    id: "basic-grammar",
    title: "Quiz de Gramática Básica",
    description:
      "Teste seus conhecimentos sobre as regras básicas da gramática.",
    prompt:
      "I will ask you a series of questions about basic grammar rules. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question:
            "What is the correct form of the verb in this sentence: 'She ___ to the store every day'?",
          options: ["go", "goes", "going"],
          correctAnswer: "goes",
        },
        {
          question: "Which of the following is a noun?",
          options: ["run", "quickly", "happiness"],
          correctAnswer: "happiness",
        },
        {
          question: "Which sentence is correct?",
          options: [
            "He don't like apples.",
            "He doesn't like apples.",
            "He not likes apples.",
          ],
          correctAnswer: "He doesn't like apples.",
        },
        {
          question: "What is the plural form of 'child'?",
          options: ["childs", "childes", "children"],
          correctAnswer: "children",
        },
        {
          question: "Which word is an adjective?",
          options: ["running", "beautiful", "quickly"],
          correctAnswer: "beautiful",
        },
        {
          question: "What is the past tense of 'go'?",
          options: ["went", "goes", "gone"],
          correctAnswer: "went",
        },
        {
          question: "Which sentence is in the future tense?",
          options: [
            "I went to the store.",
            "I am going to the store.",
            "I will go to the store.",
          ],
          correctAnswer: "I will go to the store.",
        },
        {
          question: "Which word is a conjunction?",
          options: ["and", "quickly", "happy"],
          correctAnswer: "and",
        },
        {
          question: "Which sentence uses a preposition correctly?",
          options: [
            "She is at the park.",
            "She is on the park.",
            "She is in the park.",
          ],
          correctAnswer: "She is at the park.",
        },
        {
          question: "Which word is a pronoun?",
          options: ["they", "running", "happy"],
          correctAnswer: "they",
        },
      ],
    },
  },
  {
    id: "world-capitals",
    title: "Quiz de Capitais do Mundo",
    description: "Teste seus conhecimentos sobre as capitais do mundo.",
    prompt:
      "I will ask you a series of questions about world capitals. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "What is the capital of Canada?",
          options: ["Toronto", "Ottawa", "Vancouver"],
          correctAnswer: "Ottawa",
        },
        {
          question: "What is the capital of Japan?",
          options: ["Kyoto", "Osaka", "Tokyo"],
          correctAnswer: "Tokyo",
        },
        {
          question: "What is the capital of Australia?",
          options: ["Sydney", "Melbourne", "Canberra"],
          correctAnswer: "Canberra",
        },
        {
          question: "What is the capital of Brazil?",
          options: ["Rio de Janeiro", "Brasilia", "São Paulo"],
          correctAnswer: "Brasilia",
        },
        {
          question: "What is the capital of South Africa?",
          options: ["Johannesburg", "Cape Town", "Pretoria"],
          correctAnswer: "Pretoria",
        },
        {
          question: "What is the capital of India?",
          options: ["Mumbai", "New Delhi", "Bangalore"],
          correctAnswer: "New Delhi",
        },
        {
          question: "What is the capital of Egypt?",
          options: ["Cairo", "Alexandria", "Giza"],
          correctAnswer: "Cairo",
        },
        {
          question: "What is the capital of Russia?",
          options: ["St. Petersburg", "Moscow", "Kazan"],
          correctAnswer: "Moscow",
        },
        {
          question: "What is the capital of Germany?",
          options: ["Munich", "Frankfurt", "Berlin"],
          correctAnswer: "Berlin",
        },
        {
          question: "What is the capital of Argentina?",
          options: ["Buenos Aires", "Córdoba", "Rosario"],
          correctAnswer: "Buenos Aires",
        },
      ],
    },
  },
  {
    id: "literature-quiz",
    title: "Quiz de Literatura",
    description: "Teste seus conhecimentos sobre literatura clássica.",
    prompt:
      "I will ask you a series of questions about classic literature. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "Who wrote 'Pride and Prejudice'?",
          options: ["Charlotte Bronte", "Jane Austen", "Emily Bronte"],
          correctAnswer: "Jane Austen",
        },
        {
          question: "What is the title of the first Harry Potter book?",
          options: [
            "The Chamber of Secrets",
            "The Goblet of Fire",
            "The Philosopher's Stone",
          ],
          correctAnswer: "The Philosopher's Stone",
        },
        {
          question: "Who is the author of '1984'?",
          options: ["Aldous Huxley", "George Orwell", "Ray Bradbury"],
          correctAnswer: "George Orwell",
        },
        {
          question:
            "In which novel would you find the character 'Atticus Finch'?",
          options: [
            "The Catcher in the Rye",
            "To Kill a Mockingbird",
            "Of Mice and Men",
          ],
          correctAnswer: "To Kill a Mockingbird",
        },
        {
          question: "Who wrote 'Moby-Dick'?",
          options: ["Herman Melville", "Mark Twain", "Ernest Hemingway"],
          correctAnswer: "Herman Melville",
        },
        {
          question: "Which book begins with the line 'Call me Ishmael'?",
          options: ["Moby-Dick", "The Great Gatsby", "War and Peace"],
          correctAnswer: "Moby-Dick",
        },
        {
          question: "Who is the author of 'The Great Gatsby'?",
          options: [
            "F. Scott Fitzgerald",
            "Ernest Hemingway",
            "John Steinbeck",
          ],
          correctAnswer: "F. Scott Fitzgerald",
        },
        {
          question:
            "What is the title of the sequel to 'To Kill a Mockingbird'?",
          options: [
            "Go Set a Watchman",
            "The Catcher in the Rye",
            "East of Eden",
          ],
          correctAnswer: "Go Set a Watchman",
        },
        {
          question: "Which novel features the character 'Holden Caulfield'?",
          options: [
            "The Great Gatsby",
            "The Catcher in the Rye",
            "Lord of the Flies",
          ],
          correctAnswer: "The Catcher in the Rye",
        },
        {
          question: "Who wrote 'Brave New World'?",
          options: ["George Orwell", "Aldous Huxley", "Ray Bradbury"],
          correctAnswer: "Aldous Huxley",
        },
      ],
    },
  },
  {
    id: "music-history",
    title: "Quiz de História da Música",
    description: "Teste seus conhecimentos sobre a história da música.",
    prompt:
      "I will ask you a series of questions about music history. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "Who is known as the 'King of Pop'?",
          options: ["Elvis Presley", "Michael Jackson", "Prince"],
          correctAnswer: "Michael Jackson",
        },
        {
          question: "Which classical composer was deaf?",
          options: [
            "Wolfgang Amadeus Mozart",
            "Johann Sebastian Bach",
            "Ludwig van Beethoven",
          ],
          correctAnswer: "Ludwig van Beethoven",
        },
        {
          question: "What band was John Lennon a member of?",
          options: ["The Rolling Stones", "The Beatles", "The Who"],
          correctAnswer: "The Beatles",
        },
        {
          question: "Who is known as the 'Queen of Soul'?",
          options: ["Whitney Houston", "Aretha Franklin", "Diana Ross"],
          correctAnswer: "Aretha Franklin",
        },
        {
          question:
            "What is the title of the song that begins with 'Is this the real life? Is this just fantasy?'",
          options: [
            "Stairway to Heaven",
            "Hotel California",
            "Bohemian Rhapsody",
          ],
          correctAnswer: "Bohemian Rhapsody",
        },
        {
          question: "Which composer wrote the Four Seasons?",
          options: [
            "Antonio Vivaldi",
            "Johann Sebastian Bach",
            "George Frideric Handel",
          ],
          correctAnswer: "Antonio Vivaldi",
        },
        {
          question: "Who was known as the 'King of Rock and Roll'?",
          options: ["Elvis Presley", "Chuck Berry", "Little Richard"],
          correctAnswer: "Elvis Presley",
        },
        {
          question:
            "What year did the first Woodstock Music Festival take place?",
          options: ["1967", "1969", "1971"],
          correctAnswer: "1969",
        },
        {
          question: "Which band released the album 'Dark Side of the Moon'?",
          options: ["Led Zeppelin", "The Rolling Stones", "Pink Floyd"],
          correctAnswer: "Pink Floyd",
        },
        {
          question: "Who composed the opera 'The Magic Flute'?",
          options: [
            "Ludwig van Beethoven",
            "Wolfgang Amadeus Mozart",
            "Franz Schubert",
          ],
          correctAnswer: "Wolfgang Amadeus Mozart",
        },
      ],
    },
  },
  {
    id: "sports-trivia",
    title: "Quiz de Curiosidades sobre Esportes",
    description:
      "Teste seus conhecimentos sobre a história e fatos dos esportes.",
    prompt:
      "I will ask you a series of questions about sports history and facts. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "Which country won the FIFA World Cup in 2018?",
          options: ["Brazil", "Germany", "France"],
          correctAnswer: "France",
        },
        {
          question:
            "Who holds the record for the most home runs in a single MLB season?",
          options: ["Barry Bonds", "Babe Ruth", "Hank Aaron"],
          correctAnswer: "Barry Bonds",
        },
        {
          question: "In what year were the first modern Olympic Games held?",
          options: ["1896", "1900", "1924"],
          correctAnswer: "1896",
        },
        {
          question: "Who is known as 'The Great One' in ice hockey?",
          options: ["Bobby Orr", "Mario Lemieux", "Wayne Gretzky"],
          correctAnswer: "Wayne Gretzky",
        },
        {
          question: "Which tennis player has won the most Grand Slam titles?",
          options: ["Roger Federer", "Rafael Nadal", "Novak Djokovic"],
          correctAnswer: "Rafael Nadal",
        },
        {
          question: "Which NFL team has won the most Super Bowls?",
          options: [
            "Dallas Cowboys",
            "New England Patriots",
            "Pittsburgh Steelers",
          ],
          correctAnswer: "New England Patriots",
        },
        {
          question: "In which sport would you perform a 'slam dunk'?",
          options: ["Baseball", "Basketball", "Volleyball"],
          correctAnswer: "Basketball",
        },
        {
          question:
            "Which country has won the most Olympic gold medals in total?",
          options: ["Russia", "China", "United States"],
          correctAnswer: "United States",
        },
        {
          question:
            "Who was the first female gymnast to score a perfect 10 in the Olympics?",
          options: ["Nadia Comaneci", "Simone Biles", "Mary Lou Retton"],
          correctAnswer: "Nadia Comaneci",
        },
        {
          question: "Which sport is known as 'the beautiful game'?",
          options: ["Tennis", "Basketball", "Soccer"],
          correctAnswer: "Soccer",
        },
      ],
    },
  },
  {
    id: "animal-trivia",
    title: "Quiz de Curiosidades sobre Animais",
    description: "Teste seus conhecimentos sobre o reino animal.",
    prompt:
      "I will ask you a series of questions about animals. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "What is the fastest land animal?",
          options: ["Lion", "Cheetah", "Leopard"],
          correctAnswer: "Cheetah",
        },
        {
          question: "Which animal is known as the 'King of the Jungle'?",
          options: ["Tiger", "Elephant", "Lion"],
          correctAnswer: "Lion",
        },
        {
          question: "Which animal is the largest mammal in the world?",
          options: ["Elephant", "Blue Whale", "Giraffe"],
          correctAnswer: "Blue Whale",
        },
        {
          question:
            "Which bird is known for its colorful plumage and ability to mimic sounds?",
          options: ["Parrot", "Peacock", "Flamingo"],
          correctAnswer: "Parrot",
        },
        {
          question: "What is the only mammal capable of true flight?",
          options: ["Bat", "Flying Squirrel", "Sugar Glider"],
          correctAnswer: "Bat",
        },
        {
          question: "Which animal is known to have the longest lifespan?",
          options: ["Elephant", "Blue Whale", "Tortoise"],
          correctAnswer: "Tortoise",
        },
        {
          question:
            "Which animal is known for its impressive memory and intelligence?",
          options: ["Chimpanzee", "Dolphin", "Elephant"],
          correctAnswer: "Elephant",
        },
        {
          question: "What is the primary diet of a panda?",
          options: ["Bamboo", "Fruits", "Insects"],
          correctAnswer: "Bamboo",
        },
        {
          question:
            "Which fish is known for its ability to inflate itself as a defense mechanism?",
          options: ["Clownfish", "Pufferfish", "Angelfish"],
          correctAnswer: "Pufferfish",
        },
        {
          question: "Which bird is famous for its courtship dance?",
          options: ["Penguin", "Bird of Paradise", "Swan"],
          correctAnswer: "Bird of Paradise",
        },
      ],
    },
  },
  {
    id: "movie-trivia",
    title: "Quiz de Curiosidades sobre Filmes",
    description:
      "Teste seus conhecimentos sobre a história e fatos dos filmes.",
    prompt:
      "I will ask you a series of questions about movies. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question:
            "Which movie won the Academy Award for Best Picture in 1994?",
          options: ["Forrest Gump", "Pulp Fiction", "The Shawshank Redemption"],
          correctAnswer: "Forrest Gump",
        },
        {
          question: "Who directed the movie 'Inception'?",
          options: ["Steven Spielberg", "Christopher Nolan", "James Cameron"],
          correctAnswer: "Christopher Nolan",
        },
        {
          question:
            "Which actor played the character of Jack Dawson in 'Titanic'?",
          options: ["Brad Pitt", "Leonardo DiCaprio", "Johnny Depp"],
          correctAnswer: "Leonardo DiCaprio",
        },
        {
          question:
            "What is the highest-grossing film of all time (as of 2021)?",
          options: ["Avatar", "Avengers: Endgame", "Titanic"],
          correctAnswer: "Avengers: Endgame",
        },
        {
          question:
            "Who starred as the lead character in the 'Harry Potter' film series?",
          options: ["Daniel Radcliffe", "Elijah Wood", "Rupert Grint"],
          correctAnswer: "Daniel Radcliffe",
        },
        {
          question: "Which movie features the song 'My Heart Will Go On'?",
          options: ["The Bodyguard", "Titanic", "Pretty Woman"],
          correctAnswer: "Titanic",
        },
        {
          question: "In which year was the first 'Star Wars' movie released?",
          options: ["1975", "1977", "1980"],
          correctAnswer: "1977",
        },
        {
          question: "Who played the character of Forrest Gump in the movie?",
          options: ["Tom Hanks", "Robert Downey Jr.", "Harrison Ford"],
          correctAnswer: "Tom Hanks",
        },
        {
          question:
            "What is the name of the kingdom where the 2013 animated movie 'Frozen' is set?",
          options: ["Arendelle", "Narnia", "Westeros"],
          correctAnswer: "Arendelle",
        },
        {
          question: "Who directed the film 'Schindler's List'?",
          options: ["Martin Scorsese", "Steven Spielberg", "Quentin Tarantino"],
          correctAnswer: "Steven Spielberg",
        },
      ],
    },
  },
  {
    id: "food-drink-trivia",
    title: "Quiz de Curiosidades sobre Comidas e Bebidas",
    description:
      "Teste seus conhecimentos sobre comidas e bebidas do mundo todo.",
    prompt:
      "I will ask you a series of questions about food and drink. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "Which country is famous for inventing pizza?",
          options: ["France", "Italy", "Greece"],
          correctAnswer: "Italy",
        },
        {
          question: "What is sushi traditionally wrapped in?",
          options: ["Seaweed", "Rice Paper", "Lettuce"],
          correctAnswer: "Seaweed",
        },
        {
          question: "Which spice is known as the most expensive by weight?",
          options: ["Saffron", "Vanilla", "Cinnamon"],
          correctAnswer: "Saffron",
        },
        {
          question: "Which fruit is known as the 'king of fruits'?",
          options: ["Mango", "Apple", "Banana"],
          correctAnswer: "Mango",
        },
        {
          question: "What is the main ingredient in guacamole?",
          options: ["Tomato", "Avocado", "Onion"],
          correctAnswer: "Avocado",
        },
        {
          question:
            "Which beverage is known as 'the drink of the gods' in ancient Greek mythology?",
          options: ["Wine", "Water", "Tea"],
          correctAnswer: "Wine",
        },
        {
          question: "What is the primary ingredient in hummus?",
          options: ["Chickpeas", "Lentils", "Black Beans"],
          correctAnswer: "Chickpeas",
        },
        {
          question:
            "Which country is the largest producer of coffee in the world?",
          options: ["Colombia", "Vietnam", "Brazil"],
          correctAnswer: "Brazil",
        },
        {
          question:
            "What type of pastry is used to make a traditional baklava?",
          options: ["Puff Pastry", "Phyllo Dough", "Shortcrust Pastry"],
          correctAnswer: "Phyllo Dough",
        },
        {
          question: "What is the national dish of Spain?",
          options: ["Pizza", "Paella", "Sushi"],
          correctAnswer: "Paella",
        },
      ],
    },
  },
  {
    id: "historical-figures-trivia",
    title: "Quiz de Curiosidades sobre Figuras Históricas",
    description: "Teste seus conhecimentos sobre figuras históricas famosas.",
    prompt:
      "I will ask you a series of questions about famous historical figures. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "Who was the first President of the United States?",
          options: ["George Washington", "Thomas Jefferson", "Abraham Lincoln"],
          correctAnswer: "George Washington",
        },
        {
          question:
            "Who was the leader of the Indian independence movement against British rule?",
          options: [
            "Nelson Mandela",
            "Mahatma Gandhi",
            "Martin Luther King Jr.",
          ],
          correctAnswer: "Mahatma Gandhi",
        },
        {
          question: "Who is known as the 'Father of Modern Physics'?",
          options: ["Isaac Newton", "Albert Einstein", "Galileo Galilei"],
          correctAnswer: "Albert Einstein",
        },
        {
          question: "Who was the first woman to win a Nobel Prize?",
          options: ["Marie Curie", "Rosalind Franklin", "Ada Lovelace"],
          correctAnswer: "Marie Curie",
        },
        {
          question:
            "Who was the famous nurse known as 'The Lady with the Lamp'?",
          options: ["Florence Nightingale", "Clara Barton", "Mary Seacole"],
          correctAnswer: "Florence Nightingale",
        },
        {
          question: "Who wrote the play 'Romeo and Juliet'?",
          options: ["William Shakespeare", "Charles Dickens", "Mark Twain"],
          correctAnswer: "William Shakespeare",
        },
        {
          question: "Who was the first human to journey into outer space?",
          options: ["Neil Armstrong", "Yuri Gagarin", "Buzz Aldrin"],
          correctAnswer: "Yuri Gagarin",
        },
        {
          question: "Who was the British Prime Minister during World War II?",
          options: [
            "Winston Churchill",
            "Neville Chamberlain",
            "Margaret Thatcher",
          ],
          correctAnswer: "Winston Churchill",
        },
        {
          question: "Who painted the Mona Lisa?",
          options: ["Vincent van Gogh", "Pablo Picasso", "Leonardo da Vinci"],
          correctAnswer: "Leonardo da Vinci",
        },
        {
          question:
            "Who was the Egyptian queen famous for her beauty and political acumen?",
          options: ["Nefertiti", "Hatshepsut", "Cleopatra"],
          correctAnswer: "Cleopatra",
        },
      ],
    },
  },
  {
    id: "space-trivia",
    title: "Quiz de Curiosidades sobre o Espaço",
    description: "Teste seus conhecimentos sobre o espaço e astronomia.",
    prompt:
      "I will ask you a series of questions about space and astronomy. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "What is the largest planet in our solar system?",
          options: ["Earth", "Jupiter", "Saturn"],
          correctAnswer: "Jupiter",
        },
        {
          question:
            "What is the name of the galaxy that contains our Solar System?",
          options: ["Andromeda Galaxy", "Milky Way Galaxy", "Whirlpool Galaxy"],
          correctAnswer: "Milky Way Galaxy",
        },
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Mars", "Venus", "Mercury"],
          correctAnswer: "Mars",
        },
        {
          question: "Who was the first person to walk on the Moon?",
          options: ["Buzz Aldrin", "Yuri Gagarin", "Neil Armstrong"],
          correctAnswer: "Neil Armstrong",
        },
        {
          question: "What is the closest star to Earth?",
          options: ["Alpha Centauri", "Sirius", "The Sun"],
          correctAnswer: "The Sun",
        },
        {
          question: "Which planet has the most moons?",
          options: ["Mars", "Jupiter", "Saturn"],
          correctAnswer: "Saturn",
        },
        {
          question: "What is the name of the first artificial Earth satellite?",
          options: ["Voyager", "Hubble", "Sputnik"],
          correctAnswer: "Sputnik",
        },
        {
          question: "Which planet is known for its rings?",
          options: ["Uranus", "Neptune", "Saturn"],
          correctAnswer: "Saturn",
        },
        {
          question:
            "What is the name of the largest volcano in the solar system?",
          options: ["Mount Everest", "Olympus Mons", "Mauna Kea"],
          correctAnswer: "Olympus Mons",
        },
        {
          question: "Which planet is closest to the Sun?",
          options: ["Venus", "Mercury", "Earth"],
          correctAnswer: "Mercury",
        },
      ],
    },
  },
  {
    id: "technology-trivia",
    title: "Quiz de Curiosidades sobre Tecnologia",
    description: "Teste seus conhecimentos sobre tecnologia e inovações.",
    prompt:
      "I will ask you a series of questions about technology and innovations. Choose the correct answer from the given options. I will provide feedback after each question.",
    quizParams: {
      questions: [
        {
          question: "Who is known as the father of the computer?",
          options: ["Alan Turing", "Charles Babbage", "John von Neumann"],
          correctAnswer: "Charles Babbage",
        },
        {
          question: "What does HTTP stand for?",
          options: [
            "HyperText Transfer Protocol",
            "HighText Transfer Protocol",
            "HyperText Transmission Protocol",
          ],
          correctAnswer: "HyperText Transfer Protocol",
        },
        {
          question: "What year was the first iPhone released?",
          options: ["2005", "2007", "2009"],
          correctAnswer: "2007",
        },
        {
          question: "Who co-founded Microsoft with Bill Gates?",
          options: ["Steve Jobs", "Paul Allen", "Larry Page"],
          correctAnswer: "Paul Allen",
        },
        {
          question: "What is the most popular programming language as of 2021?",
          options: ["Python", "Java", "C++"],
          correctAnswer: "Python",
        },
        {
          question: "What does CPU stand for?",
          options: [
            "Central Processing Unit",
            "Central Power Unit",
            "Central Program Unit",
          ],
          correctAnswer: "Central Processing Unit",
        },
        {
          question: "Which company developed the Android operating system?",
          options: ["Apple", "Google", "Microsoft"],
          correctAnswer: "Google",
        },
        {
          question: "What was the first programmable computer called?",
          options: ["ENIAC", "UNIVAC", "Z1"],
          correctAnswer: "Z1",
        },
        {
          question: "What does RAM stand for?",
          options: [
            "Random Access Memory",
            "Read Access Memory",
            "Run Access Memory",
          ],
          correctAnswer: "Random Access Memory",
        },
        {
          question: "What is the name of the world's first website?",
          options: ["www.example.com", "www.google.com", "www.info.cern.ch"],
          correctAnswer: "www.info.cern.ch",
        },
      ],
    },
  },
];
