import { ToastPosition, useToast } from "@chakra-ui/react";

export function useSimpleToast() {
  const toast = useToast();

  const toastSuccess = (message: string, position?: ToastPosition) => {
    toast({
      title: message,
      status: "success",
      isClosable: true,
      duration: 3000,
      position: position || "bottom",
    });
  };

  const toastInfo = (message: string, position?: ToastPosition) => {
    toast({
      title: message,
      status: "info",
      isClosable: true,
      duration: 3000,
      position: position || "bottom",
    });
  };

  const toastWarn = (
    message: string,
    position?: ToastPosition,
    small?: boolean
  ) => {
    const smallStyle = small
      ? {
          fontSize: "13px!important",
          padding: "8px 20px 8px 13px",
        }
      : {};

    toast({
      title: message,
      status: "warning",
      isClosable: true,
      duration: 3000,
      position: position || "bottom",
      ...smallStyle,
    });
  };

  const toastFail = (message: string, position?: ToastPosition) => {
    toast({
      title: message,
      status: "error",
      isClosable: true,
      duration: 3000,
      position: position || "bottom",
    });
  };

  return { toastSuccess, toastFail, toastWarn, toastInfo };
}
