import { useSession } from "next-auth/react";
import useSWR from "swr";
import { User } from "../types/user";
import { AppError } from "../utils/app-error";
import { fetchApi } from "../services/fetch-api";
import base64url from "base64url";
import { logger } from "../utils/logger";
import { useEffect, useMemo, useState } from "react";
import { InvitationStatusResponse } from "../pages/api/invitation/status";
import { SessionUser } from "../utils/session-user";

type UserMerged = User & {
  name?: string | undefined;
  email?: string | null | undefined;
  image?: string | null | undefined;
};

export async function fetchUser<T>(url: string): Promise<T> {
  try {
    const res = await fetch(url);
    const json = await res.json();

    if (json.statusCode === 404) {
      throw new AppError(
        json.message,
        "No user found",
        undefined,
        undefined,
        false
      );
    } else if (!res.ok) {
      throw new AppError(json?.message, "Error fetching user");
    }

    return json;
  } catch (err) {
    throw AppError.fromUnknownError(err);
  }
}

export function useUser(shouldRetryOnError = true, checkPending = false) {
  const [userDidLoad, setUserDidLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { data, status } = useSession();
  const userId = (data?.user as any)?.id;
  const response = useSWR<User, AppError>(
    userId ? `/api/user/${userId}` : null,
    fetchUser,
    { shouldRetryOnError }
  );

  const systemUser = response.data;
  const sessionUser = data?.user as SessionUser | undefined;
  const invitationStatus = useSWR<InvitationStatusResponse>(
    sessionUser && !systemUser && checkPending ? "/invitation/status" : null,
    fetchApi
  );

  let user: UserMerged | undefined = undefined;
  const isLoading = status === "loading" || response.isLoading;

  useEffect(() => {
    if (!isLoading && !userDidLoad) {
      setUserDidLoad(true);
    }
  }, [isLoading, userDidLoad]);

  if (systemUser && sessionUser) {
    user = {
      ...systemUser,
      ...sessionUser,
    };
  }

  const updateUsage = async (newUsageCount: number) => {
    const result = await fetchApi<User>("/user", {
      method: "POST",
      body: JSON.stringify({ newUsageCount }),
    });

    response.mutate(result);
  };

  const updateConversionTracking = async (
    conversionParams: string,
    conversionReferrer: string
  ) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const result = await fetchApi<User>(`/user/${userId}`, {
      method: "PUT",
      body: JSON.stringify({
        conversion: {
          params: conversionParams,
          referrer: conversionReferrer,
        },
      }),
    });

    logger.info("updateConversionTracking");
    setIsSaving(false);
    response.mutate(result);
  };

  const subscriptionIsActive = useMemo(() => {
    if (!systemUser) {
      return false;
    }

    if (systemUser.primaryOrganization?.billing?.active) {
      return true;
    }

    // ignore that subscriptionActive is deprecated... this is where we should be checking
    return systemUser.subscriptionActive;
  }, [systemUser]);

  return {
    mutateConversation: response.mutate,
    user,
    systemUser,
    sessionUser,
    userId: userId as string | undefined,
    base64sessionUser: userId ? base64url(userId) : undefined,
    userError: response.error,
    userIsLoading: isLoading,
    userDidLoad,
    subscriptionIsActive,
    pendingInvitation:
      invitationStatus.data?.status === "pending"
        ? invitationStatus.data
        : undefined,
    updateUsage,
    updateConversionTracking,
    // subscription: response.data
    //   ? subscriptionHandler(response.data)
    //   : undefined,
  };
}
