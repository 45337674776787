import {
  Box,
  Show,
  Flex,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Center,
  Button,
  Link,
  Text,
  Image,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import useWindowHeight from "../hooks/use-window-height";
import { useAuth } from "../use-auth";
import { isChina } from "../utils/is-china";
import Analytics from "./analytics";
import Logo from "./logo";
import { siteConfig } from "../configuration/config";
import { logout } from "../utils/logout";
import { labelToId } from "../utils/label-to-id";

interface Props {
  children: React.ReactNode;
}

export default function LayoutHub({ children }: Props) {
  const router = useRouter();
  const { id } = router.query;
  useAuth();
  const innerHeight = useWindowHeight();

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.classList.add("hub");
    }
  }, []);

  const menuItems = [
    { title: "Home", link: "/hub/", visible: true },
    {
      title: "Content Library",
      link: `/hub/organizations/${id}/content/prompt-templates`,
      visible: Boolean(id),
    },
    {
      title: "Users",
      link: `/hub/organizations/${id}/users`,
      visible: Boolean(id),
    },
    // {
    //   title: "Account",
    //   link: `/hub/organizations/${id}/account`,
    //   visible: Boolean(id),
    // },
    { title: "Go to App", link: "/app", visible: true },
  ];

  return (
    <>
      {!isChina() && <Analytics gKey={"G-38FBRZ326Q"} />}

      <Show below="md">
        <Flex flexDir={"column"} id="mobile-wrapper" height={innerHeight}>
          <Box h="50px"></Box>
          <Flex
            h={"50px"}
            backgroundColor={"brand.gray.900"}
            justifyContent={"space-between"}
            alignItems="center"
            position="fixed"
            w="full"
            zIndex={20}
            id="mobile-header"
            top="0"
          >
            <Box flex={1}>
              <Menu>
                <MenuButton
                  colorScheme="brand.secondary"
                  bg="transparent"
                  color="white"
                  border="1px solid"
                  borderColor="white"
                  mx={5}
                  as={IconButton}
                  size={"md"}
                  icon={<HiOutlineMenuAlt2 fontSize="25px" />}
                ></MenuButton>
                <MenuList zIndex={20}>
                  {menuItems.map((m, i) =>
                    m.visible ? (
                      <MenuItem key={i} as="a" href={m.link}>
                        {m.title}
                      </MenuItem>
                    ) : (
                      <></>
                    )
                  )}
                </MenuList>
              </Menu>
            </Box>
            <Center flex={1}>
              <Logo fontSize={30} />
            </Center>
            <Box flex={1}></Box>
          </Flex>

          <Box h={innerHeight - 50} bg="brand.light.main">
            {children}
          </Box>
        </Flex>
      </Show>
      <Show above="md">
        <Flex>
          <Flex
            id="box"
            flex="1"
            h="100vh"
            bg="white"
            maxW="200px"
            backgroundColor={"gray.900"}
            color={"white"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Flex flexDir="column" alignItems={"center"}>
              <Center mt={5}>
                <Image
                  maxH={{ base: "100%" }}
                  h={{ base: "20px", sm: "20px" }}
                  my={{ base: "8px", sm: "10px" }}
                  src={siteConfig.hubLogoPath ?? "/talkio-logo-white.svg"}
                  alt={siteConfig.serviceName || "Talkio AI"}
                />
              </Center>
              <Box fontSize="11px" opacity={0.5}>
                Learning Hub
              </Box>

              <Box mt={10} px="20px" ml="1">
                {menuItems.map((m, i) =>
                  m.visible ? (
                    <Button
                      data-tid={labelToId(m.title)}
                      as="a"
                      href={m.link}
                      key={i}
                      color="brand.gray.200"
                      _hover={{ backgroundColor: "gray.600" }}
                      variant="ghost"
                      size="sm"
                      mb={2}
                    >
                      <Text w={"110px"} textAlign={"left"}>
                        {m.title}
                      </Text>
                    </Button>
                  ) : (
                    <></>
                  )
                )}
              </Box>
            </Flex>

            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              py={3}
              borderTop={"1px solid #eee"}
            >
              <Link
                color={"gray.400"}
                display={"flex"}
                fontWeight={"bold"}
                fontSize={"small"}
                onClick={() => {
                  logout({ callbackUrl: "/" });
                }}
              >
                Sign out
              </Link>

              <Text pt={2} fontSize={"xs"} color={"gray.500"}>
                ©Aidia ApS {new Date().getFullYear()}
              </Text>
            </Flex>
          </Flex>

          <Box flex="2" h="100vh" bg="brand.gray.100">
            {children}
          </Box>
        </Flex>
      </Show>
    </>
  );
}
