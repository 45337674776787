import { siteConfig } from "../configuration/config";
import { AvatarType } from "../hooks/use-avatars";
import { Voice } from "../services/speech/text-to-speech-config";

/**
 * Eventually we can remove the globalExcludeList and remove the avatars from the
 * static-character-list, however, we need to make sure that no active subscribers are using the
 * avatar that we are removing.
 */
const globalExcludeList = [
  "Microsoft Server Speech Text to Speech Voice (en-US, RyanMultilingualNeural)",
  "Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualV2Neural)",
];

export function avatarConfigFilter(avatars: AvatarType[]) {
  return avatars.filter((a) => {
    if (siteConfig.avatarIncludeList) {
      return siteConfig.avatarIncludeList.some((v) => v === a.id);
    }

    const excludeList = globalExcludeList.concat(
      siteConfig.avatarExcludeList || []
    );

    const shouldExclude = excludeList.some((v) => v === a.id);
    return !shouldExclude;
  });
}

export function voiceConfigFilter(voices: Voice[]) {
  return voices.filter((v) => {
    if (siteConfig.avatarIncludeList) {
      return siteConfig.avatarIncludeList.some((a) => a === v.id);
    }

    const shouldExclude = siteConfig.avatarExcludeList?.some((a) => a === v.id);
    return !shouldExclude;
  });
}
