const l = {
  language: "Polski",
  "app.error": "Błąd",
  "app.close": "Zamknij",
  "app.start": "Start",
  "app.noConversations": "Brak rozmów",
  "app.conversation": "Rozmowa",
  "app.conversations": "Rozmowy",
  "app.loading": "Ładowanie...",
  "app.tryAgain": "Spróbuj ponownie",
  "app.signOut": "Wyloguj",
  "app.next": "Następny",
  "app.previous": "Poprzedni",
  "app.finish": "Zakończ",
  "layout.unableToConnect": "Nie można połączyć się z serwerem",
  "layout.home": "Strona główna",
  "layout.chat": "Czat",
  "layout.wordbook": "Słownik",
  "layout.pronunciation": "Wymowa",
  "layout.programs": "Programy",
  "layout.settings": "Ustawienia",
  "layout.account": "Konto",
  "layout.manageAccount": "Zarządzaj kontem",
  "layout.leaveReview": "Zostaw opinię",
  "dashboard.title": "Strona główna",
  "dashboard.subtitle":
    "Wybierz, w jaki sposób chcesz komunikować się z swoim korepetytorem.",
  "dashboard.learningStreaks.title": "Serie nauki",
  "dashboard.learningStreaks.description":
    "Śledź swój postęp i utrzymaj regularne nawyki uczenia się",
  "dashboard.learningStreaks.dayStreak": "Serię dni",
  "dashboard.learningStreaks.longestStreak": "Najdłuższą serię",
  "dashboard.learningStreaks.conversations": "Rozmowy",
  "dashboard.learningStreaks.achievement1": "Rozpoczęcie podróży",
  "dashboard.learningStreaks.achievement3": "Trzy dni ciągłej nauki",
  "dashboard.learningStreaks.achievement4": "Cztery dni ciągłej nauki",
  "dashboard.learningStreaks.achievement5": "Solidna pięciodniowa seria",
  "dashboard.learningStreaks.achievement6": "Solidna sześciodniowa seria",
  "dashboard.learningStreaks.achievement7": "Tydzień nauki",
  "dashboard.learningStreaks.achievement8": "Ponad tydzień nauki",
  "dashboard.learningStreaks.achievement10": "Podwójne cyfry!",
  "dashboard.learningStreaks.achievement14": "Dwa tygodnie wiedzy",
  "dashboard.learningStreaks.achievement20": "Osiągnięcie 20-dniowe",
  "dashboard.learningStreaks.achievement25": "Pierwsza kwarta setki!",
  "dashboard.learningStreaks.achievement30": "Miesiąc poświęcenia",
  "dashboard.learningStreaks.achievement40": "Czterdzieści dni mądrości",
  "dashboard.learningStreaks.achievement50": "Połowa drogi do setki",
  "dashboard.learningStreaks.achievement60": "Dwa miesiące wytrwałości",
  "dashboard.learningStreaks.achievement75": "Trzy czwarte setki",
  "dashboard.learningStreaks.achievement90": "Trzy miesiące zaangażowania",
  "dashboard.learningStreaks.achievement100": "100 dni nauki",
  "dashboard.learningStreaks.achievement120":
    "Cztery miesiące, magiczny dotyk!",
  "dashboard.learningStreaks.achievement150": "150 dni, solidnej wiedzy",
  "dashboard.learningStreaks.achievement180": "Pół roku oświecenia",
  "dashboard.learningStreaks.achievement200": "200 dni, wysokie loty",
  "dashboard.learningStreaks.achievement250": "Jedna czwarta tysiąca dni!",
  "dashboard.learningStreaks.achievement300": "300 dni, świetny przykład",
  "dashboard.learningStreaks.conversation1": "Rozpoczęcie!",
  "dashboard.learningStreaks.conversation3": "Już trzy rozmowy!",
  "dashboard.learningStreaks.conversation5": "Pięć solidnych rozmów!",
  "dashboard.learningStreaks.conversation10":
    "Dziesięć dyskusji, dobra robota!",
  "dashboard.learningStreaks.conversation15": "15 rozmów! Znajdź swój rytm!",
  "dashboard.learningStreaks.conversation16": "Szukając swojego rytmu!",
  "dashboard.learningStreaks.conversation20": "20 zanurzeń w naukę!",
  "dashboard.learningStreaks.conversation21": "Solidne zanurzenia w naukę!",
  "dashboard.learningStreaks.conversation30":
    "30 rozmów! Świat jest jaśniejszy!",
  "dashboard.learningStreaks.conversation31": "Świat jest jaśniejszy!",
  "dashboard.learningStreaks.conversation40": "40 rozmów! Rosnąca wiedza!",
  "dashboard.learningStreaks.conversation41": "Rosnąca wiedza!",
  "dashboard.learningStreaks.conversation50": "Pół setki dni rozmów!",
  "dashboard.learningStreaks.conversation75": "75 oświecających rozmów!",
  "dashboard.learningStreaks.conversation100":
    "Sto rozmów! Naprawdę zaangażowany!",
  "dashboard.learningStreaks.conversation150":
    "150 rozmów! Kamień milowy zaangażowania!",
  "dashboard.learningStreaks.conversation200":
    "200 rozmów! Komunikacja na światowym poziomie!",
  "dashboard.learningStreaks.conversation250": "250 rozmów! Mistrz dialogów!",
  "dashboard.learningStreaks.conversation300":
    "300 rozmów! Przyszłość jest jasna!",
  "dashboard.learningStreaks.conversation400": "400 rozmów! Poza tym światem!",
  "dashboard.learningStreaks.conversation500": "500 rozmów! Legendarny!",
  "dashboard.learningStreaks.conversation750": "750 rozmów! Galaktyka wiedzy!",
  "dashboard.learningStreaks.conversation1000":
    "1000 rozmów! Królestwo mądrości!",
  "dashboard.learningStreaks.conversation1500":
    "1500 rozmów! Jesteś królem konwersacji!",
  "dashboard.learningStreaks.conversation2000":
    "2000 rozmów! Jak szybująca Gwiazda!",
  "dashboard.aiTutors.title": "AI Korepetytorzy",
  "dashboard.aiTutors.description":
    "Twój wybrany korepetytor będzie czekał na Ciebie w kolejnej sesji czatu. Do zobaczenia wkrótce!",
  "dashboard.template.rolePlay.title": "Symulacja rozmowy",
  "dashboard.template.rolePlay.description":
    "Wciel się w różne role i odgrywaj scenariusze",
  "dashboard.template.topics.title": "Temat",
  "dashboard.template.topics.description":
    "Porozmawiajmy o wybranym przez Ciebie temacie",
  "dashboard.template.caseStudy.title": "Przykładowe dyskusje",
  "dashboard.template.caseStudy.description":
    "Omów przypadek i odpowiedz na pytania",
  "dashboard.template.groupDiscussion.title": "Dyskusja grupowa",
  "dashboard.template.groupDiscussion.description": "Ćwicz dyskusje bez lidera",
  "dashboard.template.hkuProficiencyTest.title": "Test sprawności HKU",
  "dashboard.template.hkuProficiencyTest.description":
    "Przygotuj się do testu sprawności",
  "dashboard.template.hkustAdmissionInterview.title":
    "Rozmowa kwalifikacyjna HKUST",
  "dashboard.template.hkustAdmissionInterview.description":
    "Przygotuj się do rozmowy kwalifikacyjnej",
  "dashboard.template.hku.title":
    "Opanuj proces rekrutacji na Uniwersytet w Hongkongu",
  "dashboard.template.hku.description":
    "Zmierz się ze swoją pewnością siebie podczas egazminu umiejętności na Uniwersytecie w Hongkongu i rozwijaj umiejętności w wymaganej kategorii dyskusji grupowej.",
  "dashboard.template.hkust.title":
    "Ćwiczenia prowadzące do sukcesu w rozmowie kwalifikacyjnej na HKUST",
  "dashboard.template.hkust.description":
    "Ćwicz z autentycznymi pytaniami z rozmowy kwalifikacyjnej z The Hong Kong University of Science and Technology. Rozwijaj umiejętności w samoorientacji, motywacji oraz prowadzeniu dyskusji bez lidera.",
  "dashboard.template.modal.curated": "Zredagowane",
  "dashboard.template.modal.custom": "Niestandardowe",
  "dashboard.template.modal.inputContent": "Wprowadź własne treści",
  "dashboard.conversations.description": "Twoja pełna lista rozmów",
  "chat.index.failedConnection":
    "Nie można skontaktować się z serwerem i wysłać wiadomości",
  "chat.index.audioFailedToSend":
    "Błąd podczas wysyłania wiadomości dźwiękowej",
  "chat.index.usageCapMessageTrialTitle": "O jejku, jesteś świetny/a!",
  "chat.index.usageCapMessageTrialText":
    "Wygląda na to, że wyczerpałeś/aś limit 7-dniowego bezpłatnego okresu próbnego - to niesamowite! Osiągnąłeś limit znaków na ten okres. Chcesz kontynuować teraz? Możesz wybrać aktualizację swojego planu w dowolnym momencie i kontynuować tam, gdzie skończyłeś/aś.",
  "chat.index.usageCapMessageTrialFooterText":
    "Jesteśmy tutaj, aby wspierać Cię w Twojej nauce na każdym kroku.",
  "chat.index.usageCapMessagePlanTitle": "Uwaga!",
  "chat.index.usageCapMessagePlanText":
    "Bardzo dużo korzystałeś/aś z wszystkich funkcji w tym miesiącu, co nas bardzo cieszy! Osiągnąłeś limit użytkowania dla bieżącego planu. Aby zachować płynność doświadczenia, poświęć chwilę, żeby przejrzeć nasze opcje planów.",
  "chat.index.usageCapMessagePlanFooterText":
    "Bez pośpiechu jednak — Twój obecny plan zresetuje się w następnym miesiącu. Miło, że jesteś z nami!",
  "chat.index.visitAccount": "Przejdź do konta",
  "chat.index.noMicTitle": "Brak zgody na mikrofon",
  "chat.index.noMicText":
    "Dostęp do mikrofonu jest zablokowany przez Twoją przeglądarkę",
  "chat.asideMenu.voice": "Głos",
  "chat.asideMenu.text": "Tekst",
  "chat.asideMenu.newChat": "Nowa rozmowa",
  "chat.asideMenu.pastConversations": "Poprzednie rozmowy",
  "chat.asideMenu.noConversations": "Brak rozmów",
  "chat.asideMenu.autoTranslate": "Auto tłumaczenie",
  "chat.asideMenu.showRomanization": "Pokaż transkrypcję foniczną",
  "chat.asideMenu.multiLingualSpeech": "Mówienie wielojęzyczne",
  "chat.suggestions.titleSm": "Sugestia, o czym można powiedzieć",
  "chat.suggestions.titleXs": "Sugestia",
  "chat.suggestions.regenerate": "Regeneruj",
  "chat.suggestions.failed":
    "Nie udało się uzyskać sugestii. Spróbuj ponownie zregenerować.",
  "chat.speakInput.speak": "Mów",
  "chat.speakInput.recording": "Nagrywanie",
  "chat.textInput.sendMessage": "Wyślij wiadomość",
  "chat.autoSubmitSwitch.handsFree": "Wolne ręce",
  "chat.shortcuts.hold": "Przytrzymaj",
  "chat.shortcuts.press": "Naciśnij",
  "chat.shortcuts.release": "Zwolnij",
  "chat.shortcuts.toRecord": "aby nagrać",
  "chat.shortcuts.toSend": "aby wysłać",
  "chat.shortcuts.toCancel": "aby anulować",
  "chat.userMessageActions.speechPrecision": "Precyzja mowy",
  "chat.userMessageActions.improveMyAnswer": "Popraw moją odpowiedź",
  "chat.userMessageActions.retry": "Ponów",
  "chat.feedbackMessageButton.improveMyAnswer": "Popraw moją odpowiedź",
  "chat.feedbackMessageButton.feedbackOn": "Opinia na temat",
  "chat.feedbackMessageButton.speechPrecision": "Precyzja mowy",
  "chat.assessmentButton.title": "Ocena rozmowy",
  "chat.assessmentButton.close": "Zamknij",
  "chat.assessmentButton.analyzing": "Analiza całej rozmowy",
  "chat.assessmentButton.tooShort": "Rozmowa jest zbyt krótka, aby ją ocenić",
};

export default l;
