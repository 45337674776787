import { createContext, useEffect, useMemo, useState } from "react";
import { AppLocale, locales, overrides } from "../localization";
import { logger } from "../utils/logger";
import { useSettings } from "./use-settings";

export type Localization = ReturnType<typeof useLocalization>;

export const LocalizationContext = createContext<Localization>(
  {} as Localization
);

export const langCodeToLocale = (langCode?: string) => {
  return langCode?.replace("-", "_") || "en_US";
};

export function useLocalization() {
  const { settings, settingsAreLoading } = useSettings();
  const defaultLocale = langCodeToLocale(settings.uiLanguage);
  const [localeKey, setLocaleKey] = useState<string>(defaultLocale);

  useEffect(() => {
    if (settingsAreLoading) {
      return;
    }

    setLocaleKey(langCodeToLocale(settings.uiLanguage));
  }, [settingsAreLoading, settings.uiLanguage]);

  const locale: AppLocale = useMemo(() => {
    const locale = locales[localeKey];
    const override = overrides[localeKey];

    if (!locale) {
      logger.error(`Locale not found: ${localeKey}`);
      return locales[defaultLocale];
    }

    return {
      ...locale,
      ...override,
    };
  }, [localeKey, defaultLocale]);

  return { l: locale };
}
