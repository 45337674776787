const l = {
  "base.and": "and",
  "base.toc": "Terms & Conditions",
  "base.privacyPolicy": "Privacy Policy",
  "base.createAccount": "Create account",
  "base.signin": "Sign in",
  "base.signup": "Sign up",
  "base.logOut": "Log out",

  "authentication.description": "Sign in or create an account",
  "invitation.wrongEmailTitle": "Already logged in",
  "invitation.wrongEmailText":
    "It looks like you're already logged into another account. Please log out and try again.",

  "invitation.invalidLinkTitle": "Invalid invitation",
  "invitation.invalidLinkText": "The invitation link is invalid",
  "invitation.completeSignup": "Please complete the signup to ",
  "invitation.completeSignupDescription":
    "A verification email has been sent to your address. If you do not see it in your inbox, please check your spam folder.",
  "invitation.completeSignupButton": "Complete Signup",
  "invitation.mustSignupTitle": "You have been invited to ",
  "invitation.signInToAccept":
    "Please sign in to accept the invitation. In the invitation email you received, you will find a temporary password.",
  "invitation.acceptTermsOnSignup":
    "When signing in you automatically accept our",
  "invitation.accept": "Sign In to Accept the Invitation",
  "invitation.failed":
    "Failed to accept invitation. Please try to logout and login - or ask the administrator to invite you again.",
  "invitation.accepted": "Invitation accepted",
  "invitation.emailNotVerified":
    "Email not verified. Please verify your email and try again.",
};

export default l;
