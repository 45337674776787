const l = {
  language: "한국어",
  "app.error": "오류",
  "app.close": "닫기",
  "app.start": "시작",
  "app.noConversations": "아직 대화가 없습니다.",
  "app.conversation": "대화",
  "app.conversations": "대화",
  "app.loading": "로딩 중...",
  "app.tryAgain": "다시 시도해 주세요.",
  "app.signOut": "로그아웃",
  "app.next": "다음",
  "app.previous": "이전",
  "app.finish": "완료",
  "app.cancel": "취소",
  "app.restart": "다시 시작",
  "layout.unableToConnect": "서버에 연결할 수 없습니다.",
  "layout.home": "홈",
  "layout.chat": "일일 세션",
  "layout.wordbook": "단어장",
  "layout.pronunciation": "발음",
  "layout.programs": "프로그램",
  "layout.settings": "설정",
  "layout.account": "계정",
  "layout.manageAccount": "계정 관리",
  "layout.leaveReview": "리뷰 남기기",
  "dashboard.title": "홈",
  "dashboard.subtitle": "튜터와의 상호 작용 방식을 선택하세요.",
  "dashboard.aiTutors.title": "AI 튜터",
  "dashboard.aiTutors.description":
    "선택하신 튜터가 다음 채팅 세션에서 기다리고 있을 것입니다. 곧 뵙겠습니다!",
  "dashboard.template.freeTalk.title": "새로운 세션",
  "dashboard.template.freeTalk.description":
    "튜터와 원하는 모든 이야기를 나누세요.",
  "dashboard.template.rolePlay.title": "역할극",
  "dashboard.template.rolePlay.description":
    "다양한 역할을 맡아 시나리오를 연기해 보세요.",
  "dashboard.template.topics.title": "주제",
  "dashboard.template.topics.description":
    "선택한 주제에 대해 이야기해 봅시다.",
  "dashboard.template.caseStudy.title": "사례 토론",
  "dashboard.template.caseStudy.description":
    "사례에 대해 논의하고 이에 대한 질문에 답하세요.",
  "dashboard.template.groupDiscussion.title": "그룹 토론",
  "dashboard.template.groupDiscussion.description":
    "리더 없는 그룹 토론을 연습하세요.",
  "dashboard.template.quiz.title": "퀴즈",
  "dashboard.template.quiz.description":
    "흥미로운 퀴즈로 단어와 문장을 연습하세요.",
  "dashboard.template.game.title": "게임",
  "dashboard.template.game.description":
    "재미있는 단어 게임으로 어휘를 연습하세요.",
  "dashboard.template.modal.curated": "전문가가 선별한",
  "dashboard.template.modal.custom": "사용자 지정",
  "dashboard.template.modal.inputContent": "자신의 콘텐츠를 입력하세요.",
  "dashboard.conversations.description": "전체 대화 목록",
  "chat.index.failedConnection":
    "서버에 연결할 수 없으며 메시지를 전송할 수 없습니다.",
  "chat.index.audioFailedToSend": "오디오 메시지 전송 오류",
  "chat.index.usageCapMessageTrialTitle": "와, 정말 잘하고 있어요!",
  "chat.index.usageCapMessageTrialText":
    "7일 무료 체험을 최대한 활용하신 것 같네요! 정말 멋져요! 이번 기간의 글자 수 한도에 도달했습니다. 지금 바로 계속 이용하고 싶으신가요? 언제든지 플랜을 업그레이드하고 중단한 부분에서 바로 이어서 진행할 수 있습니다.",
  "chat.index.usageCapMessageTrialFooterText":
    "저희는 모든 단계에서 당신의 여정을 지원하겠습니다.",
  "chat.index.usageCapMessagePlanTitle": "주의하세요!",
  "chat.index.usageCapMessagePlanText":
    "이번 달 모든 기능을 많이 사용하셨네요! 정말 기쁩니다! 현재 플랜의 사용 한도에 도달했습니다. 원활한 경험을 위해 플랜 옵션을 검토해 보기시를 권장합니다.",
  "chat.index.usageCapMessagePlanFooterText":
    "서두르실 필요는 없습니다. 현재 플랜은 다음 달에 초기화됩니다. 함께 해주셔서 기쁩니다!",
  "chat.index.visitAccount": "계정 방문하기",
  "chat.index.noMicTitle": "마이크 사용 불가",
  "chat.index.noMicText": "브라우저에서 마이크 접근이 차단되었습니다.",
  "chat.asideMenu.voice": "음성",
  "chat.asideMenu.text": "텍스트",
  "chat.asideMenu.newChat": "새 채팅",
  "chat.asideMenu.pastConversations": "지난 대화",
  "chat.asideMenu.noConversations": "대화 없음",
  "chat.asideMenu.autoTranslate": "자동 번역",
  "chat.asideMenu.showRomanization": "로마자 표기 보기",
  "chat.asideMenu.multiLingualSpeech": "다국어 음성",
  "chat.suggestions.titleSm": "말할 내용에 대한 제안",
  "chat.suggestions.titleXs": "제안",
  "chat.suggestions.regenerate": "다시 생성하기",
  "chat.suggestions.generating": "생성 중...",
  "chat.suggestions.failed":
    "제안을 가져오는 데 실패했습니다. 다시 생성해 주세요.",
  "chat.conversation.tryAgain": "계속해서 다시 시도해 보세요.",
  "chat.conversation.cancel": "취소",
  "chat.speakInput.speak": "말하기",
  "chat.speakInput.recording": "녹음",
  "chat.textInput.sendMessage": "메시지 보내기",
  "chat.autoSubmitSwitch.handsFree": "핸즈프리",
  "chat.shortcuts.hold": "길게 누르기",
  "chat.shortcuts.press": "누르기",
  "chat.shortcuts.release": "놓기",
  "chat.shortcuts.toRecord": "녹음하기",
  "chat.shortcuts.toSend": "보내기",
  "chat.shortcuts.toCancel": "취소하기",
  "chat.userMessageActions.speechPrecision": "음성 정밀도",
  "chat.userMessageActions.speechPrecisionDescription":
    "음성 정밀도는 튜터가 당신의 메시지를 얼마나 자신 있게 해석했는지를 나타냅니다. 비율이 높을수록 말한 내용을 더 정확하게 이해한 것입니다.",
  "chat.userMessageActions.speechPrecisionShowInfo": "정보 표시",
  "chat.userMessageActions.speechPrecisionHideInfo": "정보 숨기기",
  "chat.userMessageActions.improveMyAnswer": "내 답변 개선하기",
  "chat.userMessageActions.retry": "다시 시도하기",
  "chat.feedbackMessageButton.improveMyAnswer": "내 마지막 답변 개선하기",
  "chat.feedbackMessageButton.feedbackOn": "피드백 제공",
  "chat.feedbackMessageButton.speechPrecision": "음성 정밀도",
  "chat.assessmentButton.title": "대화 평가",
  "chat.assessmentButton.close": "닫기",
  "chat.assessmentButton.analyzing": "전체 대화를 분석하는 중입니다.",
  "chat.assessmentButton.tooShort": "대화가 너무 짧아 평가할 수 없습니다.",
  "settings.title": "설정",
  "settings.subtitle": "튜터와의 경험을 맞춤 설정하세요.",
  "settings.language": "언어",
  "settings.yourLanguage": "당신의 언어",
  "settings.uiLanguage": "UI 언어",
  "settings.practiceLanguage": "연습 언어",
  "settings.proficiency": "숙련도",
  "settings.tutor": "튜터",
  "settings.name": "이름",
  "settings.talkingSpeed": "말하기 속도",
  "settings.speechInput": "음성 입력",
  "settings.precisionVoiceInputDescription":
    "영어, 독일어, 스페인어, 프랑스어, 이탈리아어를 포함한 주요 라틴어 및 게르만어뿐만 아니라 한국어, 태국어, 일본어와 같은 아시아 언어에 대해 뛰어난 정확성을 제공합니다. 크로스 토크 방식 사용과 다국어 음성 인식을 활성화하려면 표준 엔진을 선택하세요.",
  "settings.standardVoiceInputDescription":
    "모든 언어를 지원하는 다목적 엔진입니다. 크로스 토크 및 다국어 음성 인식에 적합합니다.",
  "settings.autoPunctuation": "자동 구두점",
  "settings.autoPunctuationDescription":
    "일시 중지 시 자동으로 구두점을 추가합니다",
  "settings.multiLingualSpeechRecognition": "다국어 음성 인식",
  "settings.multiLingualSpeechRecognitionDescription":
    "튜터는 연습 언어와 당신의 모국어 음성을 모두 인식합니다.",
  "settings.translations": "번역",
  "settings.autoTranslate": "자동 번역",
  "settings.autoTranslateDescription":
    "튜터의 메시지를 귀하의 언어로 자동 번역합니다.",
  "settings.showRomanization": "로마자 표기 보기",
  "settings.showRomanizationDescription":
    "스크립트 언어의 최신 채팅 메시지에 대한 로마자 표기를 표시합니다.",
  "wordbook.title": "단어장",
  "wordbook.subtitle":
    "학습한 단어를 기록하세요. 튜터가 이를 활용해 암기력을 높이는 데 도움을 줄 것입니다.",
  "wordbook.noWord": "선택한 언어에 저장된 단어가 없습니다.",
  "wordbook.yourPracticeLanguage": "당신의 연습 언어",
  "wordbook.word": "단어",
  "wordbook.addToWordbook": "단어장에 추가",
  "wordbook.pleaseEnterIn": "단어를 입력하세요.",
  "wordbook.selectWord": "단어를 선택하세요.",
  "pronunciation.title": "발음",
  "pronunciation.subtitle": "발음과 유창하게 말하는 연습을 하세요.",
  "pronunciation.intro":
    "튜터는 당신의 숙련도에 맞춘 적절한 문장을 생성합니다. 현재 다음과 같이 숙련도가 설정되어 있습니다.",
  "pronunciation.startPracticing": "연습 시작",
  "pronunciation.done": "완료",
  "pronunciation.cancel": "취소",
  "pronunciation.tryAgain": "다시 시도해 주세요.",
  "pronunciation.wordByWord.loading": "로딩 중...",
  "pronunciation.wordByWord.stopReading": "읽기 중지",
  "pronunciation.wordByWord.readSentence": "문장 읽기",
  "pronunciation.wordByWord.newSentence": "새로운 문장",
  "pronunciation.wordByWord.retrySentence": "문장 다시 시도하기",
  "pronunciation.saveProgress.progressSaved":
    "발음 진행 상황이 저장되었습니다.",
  "pronunciation.saveProgress.progressNotSaved":
    "발음 진행 상황을 저장할 수 없습니다. 연결 상태를 확인하고 다시 시도해 주세요.",
  "pronunciation.saveProgress.saveProgress":
    "문장과 점수를 저장하여 진행 상황을 추적하고 나중에 다시 시도하세요.",
  "pronunciation.saveProgress.saved": "저장됨",
  "pronunciation.saveProgress.saveSentenceAndAssessment": "문장 및 평가 저장",
  "pronunciation.assessmentStats.noResults": "표시할 결과가 없습니다.",
  "pronunciation.assessmentStats.pronunciation": "발음",
  "pronunciation.assessmentStats.pronunciationScore": "발음 점수",
  "pronunciation.assessmentStats.pronunciationDescription":
    "발음 점수는 주어진 음성의 발음 품질을 나타냅니다. 이는 각각 특정 가중치가 부여된 정확도 점수, 유창성 점수, 완전성 점수를 결합하여 계산됩니다.",
  "pronunciation.assessmentStats.accuracy": "정확도",
  "pronunciation.assessmentStats.accuracyScore": "발음 정확도",
  "pronunciation.assessmentStats.accuracyDescription":
    "발음 정확도는 발음된 단어가 원어민의 발음과 얼마나 잘 일치하는지를 측정합니다. 음절, 단어 및 전체 텍스트에 대한 정확도 점수는 음소 수준의 정확도를 기반으로 하며 평가 목표에 따라 조정됩니다.",
  "pronunciation.assessmentStats.completeness": "완전성",
  "pronunciation.assessmentStats.completenessScore": "완전성",
  "pronunciation.assessmentStats.completenessDescription":
    "발음의 완전성은 발음된 단어 수를 기준 텍스트의 총 단어 수와 비교하여 결정됩니다.",
  "pronunciation.assessmentStats.fluency": "유창성",
  "pronunciation.assessmentStats.fluencyScore": "유창성",
  "pronunciation.assessmentStats.fluencyDescription":
    "주어진 발음의 유창성. 유창성은 원어민이 단어 사이에 사용하는 침묵의 간격과 얼마나 유사한지를 나타냅니다.",
  "pronunciation.assessmentStats.keepPracticing": "계속 연습하세요!",
  "pronunciation.assessmentStats.makingProgress": "발전하고 있어요!",
  "pronunciation.assessmentStats.wellDone": "잘했어요!",
  "pronunciation.assessmentStats.almostThere": "거의 다 왔어요!",
  "pronunciation.assessmentStats.excellentJob": "훌륭해요!",
  "pronunciation.history.saveAssessments": "저장된 평가",
  "pronunciation.history.noAssessments": "아직 평가가 없습니다.",
  "pronunciation.history.score": "점수",
  "pronunciation.history.tryAgain": "다시 시도하세요.",
  "intro.title": "{serviceName}에 오신 것을 환영합니다.",
  "intro.subtitle": "몇 가지 질문으로 시작하겠습니다.",
  "intro.userLanguageTitle": "언어",
  "intro.userLanguageSubtitle": "당신의 모국어는 무엇인가요?",
  "intro.practiceLanguageTitle": "언어",
  "intro.practiceLanguageSubtitle": "어떤 언어를 연습하고 싶으신가요?",
  "intro.proficiencyTitle": "숙련도",
  "intro.proficiencyDescription":
    "{practiceLanguage}의 숙련도는 어느 정도인가요?",
  "intro.completionModal.title": "모든 준비가 완료되었습니다!",
  "intro.completionModal.buttonText": "이제 말하기를 시작할 시간입니다.",
  "hooks.useConversation.noSpeech":
    "음성이 감지되지 않거나 볼륨이 너무 낮습니다.",
  "hooks.useAssessment.unableToFetchPinyin": "병음을 가져올 수 없습니다.",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "발음 평가 서버에 연결할 수 없습니다.",
  "hooks.useAssessment.noSpeech": "음성이 감지되지 않았습니다.",
  "hooks.useAssessment.retryError": "오류",
  "hooks.useAssessment.retryErrorDescription":
    "저장된 평가를 로드할 수 없습니다.",
  "hooks.useWordbook.deleteConfirm": "이 단어를 삭제하시겠습니까?",
  "hooks.useWordbook.unableToDeleteWord":
    "단어를 삭제할 수 없습니다. 몇 초 후에 다시 시도해 주세요.",
  "components.conversationDownloadButton.export": "채팅 내보내기",
  "components.micPermissionButton.notAllowed": "마이크 액세스가 허용되지 않음",
  "components.micPermissionButton.turnOnMicAndSound": "마이크 및 소리 켜기",
  "components.micPermissionButton.pleaseAllow":
    "마이크 액세스를 허용해 주세요.",
  "components.languageSelector.managedByOrganization": "조직에서 관리됨",
  "components.languageSelector.selectLanguage": "언어 선택",
  "components.languageSelector.fullList": "전체 목록 보기",
  "components.languageSelector.simpleList": "간단 목록 보기",
  "components.proficiencySelector.helpMeChoose": "선택을 도와주세요",
  "components.proficiencySelector.descriptionsTitle": "숙련도 설명",
  "components.proficiencySelector.descriptionsSubtitle":
    "다음은 다양한 언어 숙련도 수준에 대한 설명입니다.",
  "components.proficiencySelector.beginnerLabel": "초보",
  "components.proficiencySelector.beginnerDescription":
    "기본적인 표현을 사용하여 실질적인 필요를 충족시킬 수 있으며, 명확한 의사소통과 다른 사람들의 협조가 필요합니다.",
  "components.proficiencySelector.elementaryLabel": "초급",
  "components.proficiencySelector.elementaryDescription":
    "중요한 주제를 이해하고, 일상적인 문제에 대해 효과적으로 의사소통하며, 자신의 배경과 필요 사항을 설명할 수 있습니다.",
  "components.proficiencySelector.intermediateLabel": "중급",
  "components.proficiencySelector.intermediateDescription":
    "일반적인 주제에 대한 기본적인 정보를 이해하고 소통하며, 여행 상황을 관리하고 개인적인 경험, 의견, 계획을 표현할 수 있습니다.",
  "components.proficiencySelector.upperIntermediateLabel": "중상급",
  "components.proficiencySelector.upperIntermediateDescription":
    "복잡한 실용적이고 이론적인 텍스트를 이해하고, 원어민과 유창하게 의사소통하며, 포괄적인 텍스트를 생성하고, 현재 이슈에 대한 자신의 관점을 표현할 수 있습니다.",
  "components.proficiencySelector.advancedLabel": "고급",
  "components.proficiencySelector.advancedDescription":
    "함축적인 의미를 가진 복잡한 텍스트를 이해하고, 유창하고 능숙하게 의사소통하며, 복잡한 주제에 대해 잘 구성되고 일관된 텍스트를 생성할 수 있습니다.",
  "components.proficiencySelector.close": "닫기",
  "components.readSpeedSlider.speed": "속도",
  "components.readSpeedSlider.verySlow": "매우 느림",
  "components.readSpeedSlider.slow": "느림",
  "components.readSpeedSlider.normal": "보통",
  "components.readSpeedSlider.fast": "빠름",
  "components.readSpeedSlider.veryFast": "매우 빠름",
  "components.speechRecognitionSelector.voiceInputEngine": "음성 입력 엔진",
  "components.speechRecognitionSelector.default": "표준",
  "components.speechRecognitionSelector.alternative": "정밀도",
  "components.saveWord.save": "저장",
  "components.saveWord.notUnderstood":
    "AI가 이 단어를 이해하지 못했습니다. 다시 시도해 주세요.",
  "components.saveWord.failed": "저장하지 못했습니다. 다시 시도해 주세요.",
  "components.saveWord.savedToWordbook": "단어장에 저장되었습니다.",
  "components.wordInformation.content.description": "설명",
  "components.wordInformation.content.sentences": "문장",
  "components.textWithActions.readAloud": "소리 내어 읽기",
  "components.textWithActions.saveWord": "단어 저장",
  "components.textWithActions.learnMore": "자세히 알아보기",
  "components.pwaPromptIos.title": "{app}을 기기에 설치하세요.",
  "components.pwaPromptIos.step1": "공유 아이콘을 누르세요.",
  "components.pwaPromptIos.step2": "홈 화면에 추가'를 선택하세요.",
  "components.pwaPromptIos.step3": "앱 스토어가 필요 없습니다!",
  "components.pwaPromptAndroid.title": "앱 설치",
  "chat.userMessage.perfectSentence1": "훌륭한 문장입니다! 계속 노력하세요!",
  "chat.userMessage.perfectSentence2": "잘했어요!",
  "chat.userMessage.perfectSentence3": "훌륭해요!",
  "settings.liveFeedback": "즉각적인 피드백",
  "settings.liveFeedbackDescription":
    "각 메시지 후에 답변을 개선하는 방법에 대한 피드백을 표시합니다.",
  "dashboard.template.dailySession.title": "일일 세션",
  "dashboard.template.dailySession.with": "와 함께",
  "dashboard.template.dailySession.description":
    "오늘의 대화에 참여하여 언어 실력을 연습하고 향상시키세요.",
  "dashboard.template.dailySession.button": "일일 세션 시작",
  "feedbackCard.loader.analyzeGrammar": "문법 분석 중...",
  "feedbackCard.loader.analyzeVocabulary": "어휘 분석 중...",
  "feedbackCard.loader.analyzeStructure": "문장 구조 분석 중...",
  "feedbackCard.error.message":
    "죄송합니다, 피드백을 처리하는 중에 문제가 발생했습니다. 새로 고침하여 다시 시도해 주세요.",
  "feedbackCard.improvedAnswerLabel": "개선된 답변",
  "feedbackCard.grammarLabel": "문법",
  "feedbackCard.vocabularyLabel": "어휘",
  "feedbackCard.structureLabel": "구조",
};

export default l;
