import { MutableRefObject, useRef, useState } from "react";
import { logger } from "../../../utils/logger";

import { ConversationMessage } from "../../../types/conversation";
import { TtsAudio } from "./use-tts-audio";
import { useSimpleToast } from "../../../hooks/use-simple-toast";

export type ReadAloudState = "active" | "inactive";

export function useTextToSpeech(
  ttsAudio: TtsAudio,
  ttsAudioElementRef: MutableRefObject<HTMLAudioElement | undefined>
) {
  const { toastFail } = useSimpleToast();

  const [isLoadingAudioForMessageId, setIsLoadingAudioForMessageId] =
    useState<string>();
  const [readAloudMessage, setReadAloudMessage] =
    useState<ConversationMessage>();

  const [isReadingLatest, setIsReadingLatest] = useState(false);

  const startAiSpeak = async (
    message: ConversationMessage,
    isReadingLatest = false,
    overrideVoice: string | undefined = undefined // TODO!!
  ): Promise<void> => {
    if (!ttsAudioElementRef.current) {
      // we create the audio element here where the user has interacted and the we can route the audio afterwards
      // ensuring that the audio is not blocked by the browser on iOS
      ttsAudioElementRef.current = new Audio();
    }

    setIsLoadingAudioForMessageId(message.id);
    await stopAiSpeak(false);

    const onAudioStart = () => {
      logger.info(`Start audio for ID: ${message.id}`);
      setIsReadingLatest(isReadingLatest);
      setReadAloudMessage(message);
      setIsLoadingAudioForMessageId(undefined);
    };

    const onAudioEnd = () => {
      logger.info("Audio ended " + message.id);
      setReadAloudMessage(undefined);
      setIsLoadingAudioForMessageId((prev) => {
        if (prev === message.id) {
          return undefined;
        }

        return prev;
      });
      setIsReadingLatest(false);

      // I don't think this is necessary since we're reusing the same audio element for better ios support
      // if (ttsAudioElementRef.current) {
      //   ttsAudioElementRef.current.onended = null;
      //   ttsAudioElementRef.current.onpause = null;
      //   ttsAudioElementRef.current = undefined;
      // }
    };

    let audioBlob = ttsAudio.audioBlobsRef.current.find(
      (a) => a.messageId === message.id
    );

    // We skip the cache if the voice is overridden (e.g. for group discussions)
    // This is wasteful because we generate the audio twice, but we'll keep it for now
    if (!audioBlob || overrideVoice) {
      logger.info(`Fetch new audio blob: ${message.id}`);
      try {
        audioBlob = await ttsAudio.getAudioFromServer(
          message.content,
          message.id,
          overrideVoice
        );
      } catch (error) {
        logger.error("Error getting audio from server", error);
        toastFail("Error getting audio from server");

        // we need to start before stopping to trigger the loader reset in dependent components
        onAudioStart();
        setTimeout(onAudioEnd, 1000);
        return;
      }
    } else {
      logger.info(`Play audio from stored blob: ${message.id}`);
    }

    const audioUrl = URL.createObjectURL(audioBlob.blob);

    ttsAudioElementRef.current.src = audioUrl;
    ttsAudioElementRef.current.autoplay = true;
    ttsAudioElementRef.current.play().catch((error) => {
      logger.error("Error playing the audio:", error);
      onAudioEnd();
    });

    onAudioStart();
    ttsAudioElementRef.current.onended = onAudioEnd;
    ttsAudioElementRef.current.onpause = onAudioEnd;
    return;
  };

  const stopAiSpeak = (clearLoader = true) => {
    return new Promise<void>((resolve) => {
      logger.info("Stop AI Speak");
      const timer = setTimeout(() => onResolve(), 1000);

      // This is an escape hatch in case the onAudioEnd event doesn't fire
      const onResolve = () => {
        clearTimeout(timer);

        if (clearLoader) {
          logger.info("clearLoader");
          setIsLoadingAudioForMessageId(undefined);
        }

        setReadAloudMessage(undefined);
        resolve();
      };

      if (ttsAudioElementRef.current) {
        ttsAudioElementRef.current.pause();
        return;
      }
    });
  };

  return {
    readAloudMessage,
    isLoadingAudioForMessageId,
    isReadingLatest,
    startAiSpeak,
    stopAiSpeak,
  };
}
