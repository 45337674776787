const l = {
  language: "Italiano",
  "app.error": "Errore",
  "app.close": "Chiudi",
  "app.start": "Inizia",
  "app.noConversations": "Nessuna conversazione ancora",
  "app.conversation": "Conversazione",
  "app.conversations": "Conversazioni",
  "app.loading": "Caricamento...",
  "app.tryAgain": "Per favore riprova",
  "app.signOut": "Disconnettiti",
  "app.next": "Successivo",
  "app.previous": "Precedente",
  "app.finish": "Termina",
  "app.cancel": "Annulla",
  "app.restart": "Riavvia",
  "layout.unableToConnect": "Impossibile connettersi al server",
  "layout.home": "Pagina iniziale",
  "layout.chat": "Sessione quotidiana",
  "layout.wordbook": "Vocabolario",
  "layout.pronunciation": "Pronuncia",
  "layout.programs": "Programmi",
  "layout.settings": "Impostazioni",
  "layout.account": "Account",
  "layout.manageAccount": "Gestisci account",
  "layout.leaveReview": "Lascia una recensione",
  "dashboard.title": "Pagina iniziale",
  "dashboard.subtitle": "Seleziona come vuoi interagire con il tuo tutor.",
  "dashboard.aiTutors.title": "Tutor IA",
  "dashboard.aiTutors.description":
    "Il tutor selezionato ti aspetterà nella prossima sessione di chat. A presto!",
  "dashboard.template.freeTalk.title": "Nuova sessione",
  "dashboard.template.freeTalk.description":
    "Parla di tutto quello che vuoi con il tuo tutor",
  "dashboard.template.rolePlay.title": "Gioco di ruolo",
  "dashboard.template.rolePlay.description":
    "Mettiti in diversi ruoli e recita gli scenari",
  "dashboard.template.topics.title": "Argomento",
  "dashboard.template.topics.description":
    "Parliamo di un argomento a tua scelta",
  "dashboard.template.caseStudy.title": "Discussione del caso",
  "dashboard.template.caseStudy.description":
    "Discuta un caso e rispondi alle domande su di esso",
  "dashboard.template.groupDiscussion.title": "Discussione di gruppo",
  "dashboard.template.groupDiscussion.description":
    "Pratica discussioni di gruppo senza leader",
  "dashboard.template.quiz.title": "Quiz",
  "dashboard.template.quiz.description":
    "Esercita parole e frasi con quiz coinvolgenti",
  "dashboard.template.game.title": "Giochi",
  "dashboard.template.game.description":
    "Esercita il vocabolario con giochi di parole divertenti",
  "dashboard.template.modal.curated": "Curato",
  "dashboard.template.modal.custom": "Personalizza",
  "dashboard.template.modal.inputContent": "Inserisci il tuo contenuto",
  "dashboard.conversations.description":
    "Il tuo elenco completo delle conversazioni",
  "chat.index.failedConnection":
    "Impossibile contattare il server e inviare il messaggio",
  "chat.index.audioFailedToSend": "Errore nell'invio del messaggio audio",
  "chat.index.usageCapMessageTrialTitle": "Wow, sei inarrestabile!",
  "chat.index.usageCapMessageTrialText":
    "Sembra che tu abbia sfruttato al massimo la tua prova gratuita di 7 giorni - è fantastico! Hai raggiunto il limite di caratteri per questo periodo. Desideri continuare subito? Puoi scegliere di aggiornare il tuo piano in qualsiasi momento e riprendere da dove avevi interrotto.",
  "chat.index.usageCapMessageTrialFooterText":
    "Siamo qui per supportarti nel tuo percorso, ad ogni passo.",
  "chat.index.usageCapMessagePlanTitle": "Attenzione!",
  "chat.index.usageCapMessagePlanText":
    "Hai utilizzato molto tutte le funzionalità questo mese, e noi ne siamo entusiasti! Hai raggiunto il limite di utilizzo per il piano attuale. Per mantenere l'esperienza fluida, prenditi un momento per rivedere le nostre opzioni di piano.",
  "chat.index.usageCapMessagePlanFooterText":
    "Nessuna fretta - il tuo piano attuale verrà ripristinato il mese prossimo. Siamo felici di averti con noi!",
  "chat.index.visitAccount": "Visita l'account",
  "chat.index.noMicTitle": "Microfono non consentito",
  "chat.index.noMicText": "L'accesso al microfono è bloccato dal tuo browser",
  "chat.asideMenu.voice": "Voce",
  "chat.asideMenu.text": "Testo",
  "chat.asideMenu.newChat": "Nuova chat",
  "chat.asideMenu.pastConversations": "Conversazioni passate",
  "chat.asideMenu.noConversations": "Nessuna conversazione",
  "chat.asideMenu.autoTranslate": "Traduzione automatica",
  "chat.asideMenu.showRomanization": "Mostra romanizzazione",
  "chat.asideMenu.multiLingualSpeech": "Voce multilingue",
  "chat.suggestions.titleSm": "Suggerimento su cosa dire",
  "chat.suggestions.titleXs": "Suggerimento",
  "chat.suggestions.regenerate": "Rigenera",
  "chat.suggestions.generating": "Generando...",
  "chat.suggestions.failed":
    "Impossibile ottenere il suggerimento. Si prega di rigenerare.",
  "chat.conversation.tryAgain": "Vai avanti e riprova",
  "chat.conversation.cancel": "Annulla",
  "chat.speakInput.speak": "Parla",
  "chat.speakInput.recording": "Registrazione",
  "chat.textInput.sendMessage": "Invia un messaggio",
  "chat.autoSubmitSwitch.handsFree": "Senza mani",
  "chat.shortcuts.hold": "Attendi",
  "chat.shortcuts.press": "Premi",
  "chat.shortcuts.release": "Rilascia",
  "chat.shortcuts.toRecord": "per registrare",
  "chat.shortcuts.toSend": "per inviare",
  "chat.shortcuts.toCancel": "per cancellare",
  "chat.userMessageActions.speechPrecision": "Precisione del discorso",
  "chat.userMessageActions.speechPrecisionDescription":
    "La precisione del discorso indica quanto il tutor ha interpretato con confidenza il tuo messaggio. Una percentuale più alta significa una maggiore precisione nella comprensione di ciò che è stato detto.",
  "chat.userMessageActions.speechPrecisionShowInfo": "Mostra informazioni",
  "chat.userMessageActions.speechPrecisionHideInfo": "Nascondi informazioni",
  "chat.userMessageActions.improveMyAnswer": "Migliora la mia risposta",
  "chat.userMessageActions.retry": "Riprova",
  "chat.feedbackMessageButton.improveMyAnswer":
    "Migliora la mia ultima risposta",
  "chat.feedbackMessageButton.feedbackOn": "Feedback su",
  "chat.feedbackMessageButton.speechPrecision": "Precisione del discorso",
  "chat.assessmentButton.title": "Valutazione della conversazione",
  "chat.assessmentButton.close": "Chiudi",
  "chat.assessmentButton.analyzing": "Analizzando l'intera conversazione",
  "chat.assessmentButton.tooShort":
    "La conversazione è troppo breve per essere valutata",
  "settings.title": "Impostazioni",
  "settings.subtitle": "Personalizza la tua esperienza con il tutor.",
  "settings.language": "Lingua",
  "settings.yourLanguage": "La tua lingua",
  "settings.uiLanguage": "Lingua dell'interfaccia",
  "settings.practiceLanguage": "Lingua di pratica",
  "settings.proficiency": "competenza",
  "settings.tutor": "Tutor",
  "settings.name": "Nome",
  "settings.talkingSpeed": "Velocità di conversazione",
  "settings.speechInput": "Input vocale",
  "settings.precisionVoiceInputDescription":
    "Fornisce un'eccezionale precisione per le principali lingue latine e germaniche, tra cui inglese, tedesco, spagnolo, francese e italiano, nonché per le lingue asiatiche come coreano, tailandese e giapponese. Se desideri utilizzare il metodo di conversazione incrociata e abilitare il riconoscimento vocale multilingue, seleziona il motore Standard.",
  "settings.standardVoiceInputDescription":
    "Motore versatile che supporta tutte le nostre lingue. È ideale per la conversazione incrociata e il riconoscimento vocale multilingue.",
  "settings.autoPunctuation": "Punteggiatura automatica",
  "settings.autoPunctuationDescription":
    "Aggiunge automaticamente la punteggiatura quando fai una pausa",
  "settings.multiLingualSpeechRecognition": "Riconoscimento vocale multilingue",
  "settings.multiLingualSpeechRecognitionDescription":
    "Il tutor riconoscerà il parlato sia nella lingua di pratica che nella tua lingua madre",
  "settings.translations": "Traduzioni",
  "settings.autoTranslate": "Traduzione automatica",
  "settings.autoTranslateDescription":
    "Traduci automaticamente i messaggi del tutor nella tua lingua",
  "settings.showRomanization": "Mostra romanizzazione",
  "settings.showRomanizationDescription":
    "Mostra la romanizzazione dell'ultimo messaggio di chat per le lingue con scrittura",
  "wordbook.title": "Vocabolario",
  "wordbook.subtitle":
    "Traccia le parole che impari; il tuo tutor ti aiuterà a usarle per potenziare la tua memoria",
  "wordbook.noWord": "Nessuna parola salvata per la lingua scelta",
  "wordbook.yourPracticeLanguage": "La tua lingua di pratica",
  "wordbook.word": "Parola",
  "wordbook.addToWordbook": "Aggiungi al Vocabolario",
  "wordbook.pleaseEnterIn": "Per favore inserisci una parola in",
  "wordbook.selectWord": "Seleziona una parola",
  "pronunciation.title": "Pronuncia",
  "pronunciation.subtitle": "Esercita la pronuncia e la fluidità",
  "pronunciation.intro":
    "Il tutor genererà frasi appropriate, adattate al tuo livello di competenza, impostato su",
  "pronunciation.startPracticing": "Inizia a praticare",
  "pronunciation.done": "Fatto",
  "pronunciation.cancel": "Annulla",
  "pronunciation.tryAgain": "Per favore riprova",
  "pronunciation.wordByWord.loading": "Caricamento in corso...",
  "pronunciation.wordByWord.stopReading": "Smetti di leggere",
  "pronunciation.wordByWord.readSentence": "Leggi la frase",
  "pronunciation.wordByWord.newSentence": "Nuova frase",
  "pronunciation.wordByWord.retrySentence": "Riprova la frase",
  "pronunciation.saveProgress.progressSaved":
    "I tuoi progressi di pronuncia sono stati salvati",
  "pronunciation.saveProgress.progressNotSaved":
    "Impossibile salvare i tuoi progressi di pronuncia. Controlla la tua connessione e riprova.",
  "pronunciation.saveProgress.saveProgress":
    "Salva la frase e il punteggio per monitorare i progressi e riprovare più tardi.",
  "pronunciation.saveProgress.saved": "Salvato",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Salva la frase e la valutazione",
  "pronunciation.assessmentStats.noResults": "Nessun risultato da mostrare",
  "pronunciation.assessmentStats.pronunciation": "Pronuncia",
  "pronunciation.assessmentStats.pronunciationScore": "Punteggio di pronuncia",
  "pronunciation.assessmentStats.pronunciationDescription":
    "Il punteggio di pronuncia rappresenta la qualità della pronuncia nel discorso dato. Viene calcolato combinando il punteggio di precisione, il punteggio di fluidità e il punteggio di completezza, ciascuno assegnato con un peso specifico.",
  "pronunciation.assessmentStats.accuracy": "Precisione",
  "pronunciation.assessmentStats.accuracyScore": "Precisione di pronuncia",
  "pronunciation.assessmentStats.accuracyDescription":
    "La precisione della pronuncia misura quanto bene le parole pronunciate corrispondono a quelle di un madrelingua. I punteggi di precisione per sillabe, parole e testo completo si basano sulla precisione fonemica e sono regolati in base agli obiettivi di valutazione.",
  "pronunciation.assessmentStats.completeness": "Completezza",
  "pronunciation.assessmentStats.completenessScore": "Completezza",
  "pronunciation.assessmentStats.completenessDescription":
    "La completezza della parola è determinata confrontando il numero di parole pronunciate con il numero totale di parole nel testo di riferimento.",
  "pronunciation.assessmentStats.fluency": "Fluidità",
  "pronunciation.assessmentStats.fluencyScore": "Fluidità",
  "pronunciation.assessmentStats.fluencyDescription":
    "La fluidità del discorso dato. La fluidità indica quanto da vicino il discorso corrisponde all'uso delle pause silenziose tra le parole di un madrelingua.",
  "pronunciation.assessmentStats.keepPracticing": "Continua a praticare!",
  "pronunciation.assessmentStats.makingProgress": "Stai facendo progressi!",
  "pronunciation.assessmentStats.wellDone": "Ben fatto!",
  "pronunciation.assessmentStats.almostThere": "Quasi arrivato!",
  "pronunciation.assessmentStats.excellentJob": "Ottimo lavoro!",
  "pronunciation.history.saveAssessments": "Valutazioni salvate",
  "pronunciation.history.noAssessments": "Nessuna valutazione ancora",
  "pronunciation.history.score": "Punteggio",
  "pronunciation.history.tryAgain": "Riprova",
  "intro.title": "Benvenuto su {serviceName}",
  "intro.subtitle": "Iniziamo con alcune domande",
  "intro.userLanguageTitle": "Lingua",
  "intro.userLanguageSubtitle": "Qual è la tua madrelingua?",
  "intro.practiceLanguageTitle": "Lingua",
  "intro.practiceLanguageSubtitle": "Quale lingua vuoi praticare?",
  "intro.proficiencyTitle": "Competenza",
  "intro.proficiencyDescription":
    "Qual è il tuo livello di competenza in {practiceLanguage}?",
  "intro.completionModal.title": "Sei pronto!",
  "intro.completionModal.buttonText": "È ora di iniziare a parlare",
  "hooks.useConversation.noSpeech":
    "Discorso non rilevato o volume troppo basso",
  "hooks.useAssessment.unableToFetchPinyin": "Impossibile recuperare il pinyin",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Impossibile connettersi al server di valutazione della pronuncia",
  "hooks.useAssessment.noSpeech": "Nessun discorso rilevato",
  "hooks.useAssessment.retryError": "Errore",
  "hooks.useAssessment.retryErrorDescription":
    "Impossibile caricare la valutazione salvata",
  "hooks.useWordbook.deleteConfirm":
    "Sei sicuro di voler eliminare questa parola?",
  "hooks.useWordbook.unableToDeleteWord":
    "Impossibile eliminare la parola. Per favore riprova tra qualche secondo.",
  "components.conversationDownloadButton.export": "Esporta chat",
  "components.micPermissionButton.notAllowed":
    "Accesso al microfono non consentito",
  "components.micPermissionButton.turnOnMicAndSound":
    "Attiva il microfono e il suono",
  "components.micPermissionButton.pleaseAllow":
    "Si prega di consentire l'accesso al microfono",
  "components.languageSelector.managedByOrganization":
    "Gestito dall'organizzazione",
  "components.languageSelector.selectLanguage": "Seleziona la lingua",
  "components.languageSelector.fullList": "Mostra elenco completo",
  "components.languageSelector.simpleList": "Mostra elenco semplificato",
  "components.proficiencySelector.helpMeChoose": "Aiutami a scegliere",
  "components.proficiencySelector.descriptionsTitle":
    "Descrizioni delle competenze",
  "components.proficiencySelector.descriptionsSubtitle":
    "Ecco le descrizioni dei diversi livelli di competenza linguistica.",
  "components.proficiencySelector.beginnerLabel": "Principiante",
  "components.proficiencySelector.beginnerDescription":
    "L'individuo può utilizzare espressioni di base per soddisfare bisogni pratici con una comunicazione chiara e la disponibilità degli altri.",
  "components.proficiencySelector.elementaryLabel": "Elementare",
  "components.proficiencySelector.elementaryDescription":
    "L'individuo comprende argomenti importanti, comunica efficacemente in questioni di routine e può spiegare il proprio background e le necessità.",
  "components.proficiencySelector.intermediateLabel": "Intermedio",
  "components.proficiencySelector.intermediateDescription":
    "L'individuo comprende e comunica informazioni di base su argomenti comuni, gestisce situazioni di viaggio ed esprime esperienze personali, opinioni e piani.",
  "components.proficiencySelector.upperIntermediateLabel":
    "Intermedio superiore",
  "components.proficiencySelector.upperIntermediateDescription":
    "L'individuo comprende testi pratici e teorici complessi, comunica fluentemente con i madrelingua, genera testi completi ed esprime la propria prospettiva su questioni attuali.",
  "components.proficiencySelector.advancedLabel": "Avanzato",
  "components.proficiencySelector.advancedDescription":
    "L'individuo comprende testi complessi con significati impliciti, comunica fluentemente e con competenza e genera testi ben costruiti e coerenti su argomenti complicati.",
  "components.proficiencySelector.close": "Chiudi",
  "components.readSpeedSlider.speed": "Velocità",
  "components.readSpeedSlider.verySlow": "Molto lento",
  "components.readSpeedSlider.slow": "Lento",
  "components.readSpeedSlider.normal": "Normale",
  "components.readSpeedSlider.fast": "Veloce",
  "components.readSpeedSlider.veryFast": "Molto veloce",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Motore di input vocale",
  "components.speechRecognitionSelector.default": "Standard",
  "components.speechRecognitionSelector.alternative": "Precisione",
  "components.saveWord.save": "Salva",
  "components.saveWord.notUnderstood":
    "L'IA non ha capito questa parola. Per favore riprova.",
  "components.saveWord.failed": "Salvataggio non riuscito. Per favore riprova.",
  "components.saveWord.savedToWordbook": "salvato nel tuo vocabolario",
  "components.wordInformation.content.description": "Descrizione",
  "components.wordInformation.content.sentences": "Frasi",
  "components.textWithActions.readAloud": "Leggere ad alta voce",
  "components.textWithActions.saveWord": "Salva parola",
  "components.textWithActions.learnMore": "Scopri di più",
  "components.pwaPromptIos.title": "Installa {app} sul tuo dispositivo",
  "components.pwaPromptIos.step1": "Tocca l'icona Condividi",
  "components.pwaPromptIos.step2":
    "Seleziona 'Aggiungi alla schermata principale'",
  "components.pwaPromptIos.step3": "Non è necessario l'App Store!",
  "components.pwaPromptAndroid.title": "Installa l'app",
  "chat.userMessage.perfectSentence1": "Ottima frase! Continua così!",
  "chat.userMessage.perfectSentence2": "Ottimo lavoro!",
  "chat.userMessage.perfectSentence3": "Ben fatto!",
  "settings.liveFeedback": "Feedback istantaneo",
  "settings.liveFeedbackDescription":
    "Mostra feedback su come migliorare la tua risposta dopo ogni messaggio.",
  "dashboard.template.dailySession.title": "Sessione giornaliera",
  "dashboard.template.dailySession.with": "con",
  "dashboard.template.dailySession.description":
    "Partecipa alla conversazione di oggi per praticare e migliorare le tue abilità linguistiche",
  "dashboard.template.dailySession.button": "Inizia la sessione giornaliera",
  "feedbackCard.loader.analyzeGrammar": "Analizzando la grammatica...",
  "feedbackCard.loader.analyzeVocabulary": "Analizzando il vocabolario...",
  "feedbackCard.loader.analyzeStructure":
    "Analizzando la struttura della frase...",
  "feedbackCard.error.message":
    "Siamo spiacenti, ma abbiamo riscontrato un problema durante l'elaborazione del tuo feedback. Ricarica la pagina per riprovare.",
  "feedbackCard.improvedAnswerLabel": "Risposta migliorata",
  "feedbackCard.grammarLabel": "Grammatica",
  "feedbackCard.vocabularyLabel": "Vocabolario",
  "feedbackCard.structureLabel": "Struttura",
};

export default l;
