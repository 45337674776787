import { SiteConfig } from "../../types";
import { cases } from "../clubedealemao.com/cases";
import { quizzes } from "../clubedealemao.com/quizzes";
import { roles } from "../clubedealemao.com/roles";
import { swPrompt } from "../clubedealemao.com/sw-prompt";
import { topics } from "../clubedealemao.com/topics";
import { invitationTemplate } from "./invitation-template";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "SWAI",
  appLogoPath: "/partner/punkify/logo-swai.png",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/punkify/logo-swai.png",
  landingLogoHeight: "40px",
  favicon: "/partner/punkify/favicon.png",
  pwaIcons: [
    {
      src: "/partner/punkify/app-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  useTokenAuth: true,
  authErrorPath: "https://swailogin.punkify.com/logout",
  uiLanguage: "pt-BR",
  adminEmail: "contato@punkify.com",
  adminPhoneNumber: "+55 35 9890-8169",
  whatsAppNumber: 553598908169,
  hideAccountUsage: true,
  tocLink: "https://escola.spracheundwissen.com/termos/",
  privacyLink: "https://escola.spracheundwissen.com/termos/",
  // emailsSender: "no-reply@talkio.ai",
  // emailInvitationHtml: invitationTemplate,
  // emailInvitationSubject: "Você foi convidado para fazer parte da SWAI!",
  copyright: "Sprache&Wissen",
  practiceLanguageLock: "en-US",
  userLanguageLock: "pt-BR",
  showLanguageGuides: true,
  subscriptionDefaults: {
    currency: "usd",
    price: 300,
    characterLimit: 1_050_000,
    productId: "punkify-1",
  },
  customContent: {
    baseLanguage: "pt-BR",
    roles: roles,
    topics: topics,
    cases: cases,
    quizzes: quizzes,
  },
  reviewLink: "https://depoimentos.punkify.com/r/C1ryCS",
  bioPrompt: `
    You are a tutor at SWAI, part of Sprache&Wissen, a Brazilian online English school that offers comprehensive language courses and specialized programs.
    ${swPrompt}
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (en-US, RyanMultilingualNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualV2Neural)",
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AmberNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, JennyNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, BrandonNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, ChristopherNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, ElizabethNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, EricNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, JacobNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, JaneNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, JasonNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, MichelleNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, MonicaNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, SteffanNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AriaNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, CoraNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#383339",
      speakInputIdle: "var(--chakra-colors-brand-secondary-700)",
      speakInputRecording: "var(--chakra-colors-brand-secondary-900)",
    },
  },
  colors: {
    primary: {
      main: "#FED700",
      mainContrast: "#000",
      "50": "#fff8d3",
      "100": "#fee038",
      "200": "#e7c300",
      "300": "#c2a400",
      "400": "#ad9300",
      "500": "#FED700",
      "600": "#7b6800",
      "700": "#635400",
      "800": "#544700",
      "900": "#3c3300",
    },
    secondary: {
      main: "#AF00CC",
      mainContrast: "#fff",
      "50": "#fcf5fd",
      "100": "#f3d8f7",
      "200": "#e8b4f0",
      "300": "#d987e7",
      "400": "#d16ce2",
      "500": "#AF00CC",
      "600": "#b20bce",
      "700": "#9100aa",
      "800": "#7c0091",
      "900": "#5c006b",
    },

    tertiary: {
      main: "#ff5659",
      mainContrast: "#000",
      "50": "#fff5f5",
      "100": "#ffd7d7",
      "200": "#ffb1b3",
      "300": "#ff7f81",
      "400": "#ff5c5e",
      "500": "#db4a4c",
      "600": "#b93e41",
      "700": "#953234",
      "800": "#7e2b2c",
      "900": "#5c1f20",
    },
    light: {
      main: "#ececec",
      alt: "#ececec",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    pink: {
      "50": "#fdf5f9",
      "100": "#f7d9e8",
      "200": "#f1bad5",
      "300": "#e88eba",
      "400": "#e26da6",
      "500": "#d83c88",
      "600": "#cd0566",
      "700": "#a90052",
      "800": "#860041",
      "900": "#650031",
    },
    red: {
      "50": "#fdf6f5",
      "100": "#f7d9d9",
      "200": "#f1b8b7",
      "300": "#e88d8b",
      "400": "#e37371",
      "500": "#db4b49",
      "600": "#d01916",
      "700": "#ad0300",
      "800": "#940200",
      "900": "#6e0200",
    },
    orange: {
      "50": "#fdfaf6",
      "100": "#f8ebdd",
      "200": "#f0d4b6",
      "300": "#e5b27d",
      "400": "#da9347",
      "500": "#d07413",
      "600": "#b65e00",
      "700": "#914b00",
      "800": "#733b00",
      "900": "#5e3100",
    },
    yellow: {
      "50": "#fefefc",
      "100": "#fbf9ed",
      "200": "#f4eec9",
      "300": "#ece09f",
      "400": "#e0cd62",
      "500": "#c4a600",
      "600": "#9d8500",
      "700": "#7a6800",
      "800": "#5c4e00",
      "900": "#4c4000",
    },
    green: {
      "50": "#f5fdf9",
      "100": "#c9f4e0",
      "200": "#8be8bb",
      "300": "#39d78b",
      "400": "#00bf62",
      "500": "#00a455",
      "600": "#008846",
      "700": "#006a37",
      "800": "#00572d",
      "900": "#004825",
    },
    teal: {
      "50": "#f1fcfc",
      "100": "#bff1f2",
      "200": "#82e5e6",
      "300": "#2ad2d4",
      "400": "#00b3b6",
      "500": "#00989b",
      "600": "#007c7e",
      "700": "#006062",
      "800": "#005052",
      "900": "#004243",
    },
    cyan: {
      "50": "#f4fbfd",
      "100": "#d0eff6",
      "200": "#bae7f1",
      "300": "#a1deec",
      "400": "#51c3dc",
      "500": "#27b4d4",
      "600": "#00a3c7",
      "700": "#0087a4",
      "800": "#006f87",
      "900": "#005669",
    },
    blue: {
      "50": "#f1f7fc",
      "100": "#cce0f5",
      "200": "#a7c9ed",
      "300": "#7db0e5",
      "400": "#5597dd",
      "500": "#3081d6",
      "600": "#0868ce",
      "700": "#004fa2",
      "800": "#004185",
      "900": "#00356d",
    },
    purple: {
      "50": "#f8f6fd",
      "100": "#e4daf8",
      "200": "#d1bff2",
      "300": "#b497ea",
      "400": "#a07be5",
      "500": "#8555dd",
      "600": "#7039d7",
      "700": "#5a1ad1",
      "800": "#4300c0",
      "900": "#330092",
    },
  },
};
