const l = {
  language: "Français",
  "app.error": "Erreur",
  "app.close": "Fermer",
  "app.start": "Commencer",
  "app.noConversations": "Pas encore de conversations",
  "app.conversation": "Conversation",
  "app.conversations": "Conversations",
  "app.loading": "Chargement...",
  "app.tryAgain": "Veuillez réessayer",
  "app.signOut": "Déconnexion",
  "app.next": "Suivant",
  "app.previous": "Précédent",
  "app.finish": "Terminer",
  "app.cancel": "Annuler",
  "app.restart": "Redémarrer",
  "layout.unableToConnect": "Impossible de se connecter au serveur",
  "layout.home": "Accueil",
  "layout.chat": "Session quotidienne",
  "layout.wordbook": "Vocabulaire",
  "layout.pronunciation": "Prononciation",
  "layout.programs": "Programmes",
  "layout.settings": "Paramètres",
  "layout.account": "Compte",
  "layout.manageAccount": "Gérer le compte",
  "layout.leaveReview": "Donner votre avis",
  "dashboard.title": "Accueil",
  "dashboard.subtitle":
    "Sélectionnez comment vous souhaitez interagir avec votre tuteur.",
  "dashboard.aiTutors.title": "Tuteurs IA",
  "dashboard.aiTutors.description":
    "Votre tuteur vous attendra dans la prochaine session de chat. À bientôt !",
  "dashboard.template.freeTalk.title": "Nouvelle session",
  "dashboard.template.freeTalk.description":
    "Parlez de tout ce que vous voulez avec votre tuteur",
  "dashboard.template.rolePlay.title": "Jeu de rôle",
  "dashboard.template.rolePlay.description":
    "Tenez différents rôles et jouez les scénarios",
  "dashboard.template.topics.title": "Sujet",
  "dashboard.template.topics.description": "Parlons d'un sujet de votre choix",
  "dashboard.template.caseStudy.title": "Discussion de cas",
  "dashboard.template.caseStudy.description":
    "Discutez d'un cas et répondez aux questions à ce sujet",
  "dashboard.template.groupDiscussion.title": "Discussion de groupe",
  "dashboard.template.groupDiscussion.description":
    "Entraînez-vous à participer à des discussions de groupe sans chef",
  "dashboard.template.quiz.title": "Quiz",
  "dashboard.template.quiz.description":
    "Entraînez-vous à utiliser des mots et des phrases avec des quiz engageants",
  "dashboard.template.game.title": "Jeux",
  "dashboard.template.game.description":
    "Entraînez-vous à utiliser du vocabulaire avec des jeux de mots amusants",
  "dashboard.template.modal.curated": "Curaté",
  "dashboard.template.modal.custom": "Personnalisé",
  "dashboard.template.modal.inputContent": "Entrez votre propre contenu",
  "dashboard.conversations.description":
    "Votre liste complète de conversations",
  "chat.index.failedConnection":
    "Impossible de contacter le serveur et d'envoyer le message",
  "chat.index.audioFailedToSend": "Erreur lors de l'envoi du message audio",
  "chat.index.usageCapMessageTrialTitle": "Wow, vous êtes en pleine forme !",
  "chat.index.usageCapMessageTrialText":
    "Il semble que vous ayez tiré le meilleur parti de votre essai gratuit de 7 jours – c'est incroyable ! Vous avez atteint la limite de caractères pour cette période. Envie de continuer dès maintenant ? Vous pouvez choisir de mettre à niveau votre formule à tout moment et reprendre là où vous vous êtes arrêté.",
  "chat.index.usageCapMessageTrialFooterText":
    "Nous sommes là pour vous soutenir dans votre parcours, à chaque étape.",
  "chat.index.usageCapMessagePlanTitle": "Attention !",
  "chat.index.usageCapMessagePlanText":
    "Vous avez beaucoup profité de toutes les fonctionnalités ce mois-ci, et nous sommes ravis ! Vous avez atteint votre limite d'utilisation de votre formule actuelle. Pour que l'expérience reste fluide, prenez un moment pour examiner nos différentes formules.",
  "chat.index.usageCapMessagePlanFooterText":
    "Pas de précipitation – votre formule actuelle sera réinitialisée le mois prochain. Heureux de vous avoir avec nous !",
  "chat.index.visitAccount": "Visiter le compte",
  "chat.index.noMicTitle": "Microphone non autorisé",
  "chat.index.noMicText":
    "L'accès au microphone est bloqué par votre navigateur",
  "chat.asideMenu.voice": "Voix",
  "chat.asideMenu.text": "Texte",
  "chat.asideMenu.newChat": "Nouveau chat",
  "chat.asideMenu.pastConversations": "Conversations passées",
  "chat.asideMenu.noConversations": "Pas de conversations",
  "chat.asideMenu.autoTranslate": "Traduction automatique",
  "chat.asideMenu.showRomanization": "Afficher la romanisation",
  "chat.asideMenu.multiLingualSpeech": "Discours multilingue",
  "chat.suggestions.titleSm": "Suggestion de ce que vous pouvez dire",
  "chat.suggestions.titleXs": "Suggestion",
  "chat.suggestions.regenerate": "Régénérer",
  "chat.suggestions.generating": "Génération...",
  "chat.suggestions.failed":
    "Échec de la récupération de la suggestion. Veuillez réessayer.",
  "chat.conversation.tryAgain": "Essayez à nouveau",
  "chat.conversation.cancel": "Annuler",
  "chat.speakInput.speak": "Parler",
  "chat.speakInput.recording": "Enregistrement",
  "chat.textInput.sendMessage": "Envoyer un message",
  "chat.autoSubmitSwitch.handsFree": "Mains libres",
  "chat.shortcuts.hold": "Patienter",
  "chat.shortcuts.press": "Appuyer",
  "chat.shortcuts.release": "Relâcher",
  "chat.shortcuts.toRecord": "pour enregistrer",
  "chat.shortcuts.toSend": "pour envoyer",
  "chat.shortcuts.toCancel": "pour annuler",
  "chat.userMessageActions.speechPrecision": "Précision du discours",
  "chat.userMessageActions.speechPrecisionDescription":
    "La précision du discours indique la confiance avec laquelle le tuteur a interprété votre message. Un pourcentage plus élevé signifie une plus grande précision dans la compréhension de ce qui a été dit.",
  "chat.userMessageActions.speechPrecisionShowInfo": "Afficher les infos",
  "chat.userMessageActions.speechPrecisionHideInfo": "Masquer les infos",
  "chat.userMessageActions.improveMyAnswer": "Améliorer ma réponse",
  "chat.userMessageActions.retry": "Réessayer",
  "chat.feedbackMessageButton.improveMyAnswer": "Améliorer ma dernière réponse",
  "chat.feedbackMessageButton.feedbackOn": "Retour sur",
  "chat.feedbackMessageButton.speechPrecision": "Précision du discours",
  "chat.assessmentButton.title": "Évaluation de la conversation",
  "chat.assessmentButton.close": "Fermer",
  "chat.assessmentButton.analyzing": "Analyse de toute la conversation",
  "chat.assessmentButton.tooShort":
    "La conversation est trop courte pour être évaluée",
  "settings.title": "Paramètres",
  "settings.subtitle": "Personnalisez votre expérience avec le tuteur.",
  "settings.language": "Langue",
  "settings.yourLanguage": "Votre langue",
  "settings.uiLanguage": "Langue de l'interface",
  "settings.practiceLanguage": "Langue de pratique",
  "settings.proficiency": "Compétence linguistique",
  "settings.tutor": "Tuteur",
  "settings.name": "Nom",
  "settings.talkingSpeed": "Vitesse de parole",
  "settings.speechInput": "Entrée vocale",
  "settings.precisionVoiceInputDescription":
    "Offre une précision exceptionnelle pour les principales langues latines et germaniques, y compris l'anglais, l'allemand, l'espagnol, le français et l'italien, ainsi que les langues asiatiques comme le coréen, le thaï et le japonais. Si vous souhaitez utiliser la méthode de conversation croisée et activer la reconnaissance vocale multilingue, veuillez sélectionner le moteur Standard.",
  "settings.standardVoiceInputDescription":
    "Moteur polyvalent qui prend en charge toutes nos langues. Il est idéal pour la conversation croisée et la reconnaissance vocale multilingue.",
  "settings.autoPunctuation": "Ponctuation automatique",
  "settings.autoPunctuationDescription":
    "Ajoutez automatiquement des signes de ponctuation lorsque vous faites une pause",
  "settings.multiLingualSpeechRecognition": "Reconnaissance vocale multilingue",
  "settings.multiLingualSpeechRecognitionDescription":
    "Le tuteur reconnaîtra les paroles à la fois dans la langue de pratique et dans votre langue maternelle",
  "settings.translations": "Traductions",
  "settings.autoTranslate": "Traduction automatique",
  "settings.autoTranslateDescription":
    "Traduisez automatiquement les messages du tuteur dans votre langue",
  "settings.showRomanization": "Afficher la romanisation",
  "settings.showRomanizationDescription":
    "Affichez la romanisation du dernier message de chat pour les langues à écriture",
  "wordbook.title": "Vocabulaire",
  "wordbook.subtitle":
    "Suivez les mots que vous apprenez ; votre tuteur vous aidera à les utiliser pour améliorer votre mémoire",
  "wordbook.noWord": "Aucun mot enregistré pour la langue choisie",
  "wordbook.yourPracticeLanguage": "Votre langue de pratique",
  "wordbook.word": "Mot",
  "wordbook.addToWordbook": "Ajouter au Vocabulaire",
  "wordbook.pleaseEnterIn": "Veuillez entrer un mot en",
  "wordbook.selectWord": "Sélectionnez un mot",
  "pronunciation.title": "Prononciation",
  "pronunciation.subtitle":
    "Entraînez-vous à bien prononcer et à vous exprimer avec fluidité",
  "pronunciation.intro":
    "Le tuteur générera des phrases appropriées, adaptées à votre niveau de compétence, qui est défini comme",
  "pronunciation.startPracticing": "Commencez à vous entraîner",
  "pronunciation.done": "Terminé",
  "pronunciation.cancel": "Annuler",
  "pronunciation.tryAgain": "Veuillez réessayer",
  "pronunciation.wordByWord.loading": "Chargement...",
  "pronunciation.wordByWord.stopReading": "Arrêtez de lire",
  "pronunciation.wordByWord.readSentence": "Lisez la phrase",
  "pronunciation.wordByWord.newSentence": "Nouvelle phrase",
  "pronunciation.wordByWord.retrySentence": "Réessayez la phrase",
  "pronunciation.saveProgress.progressSaved":
    "Vos progrès en prononciation ont été enregistrés",
  "pronunciation.saveProgress.progressNotSaved":
    "Impossible d'enregistrer vos progrès en prononciation. Veuillez vérifier votre connexion et réessayer.",
  "pronunciation.saveProgress.saveProgress":
    "Enregistrez la phrase et la note pour suivre vos progrès et réessayer plus tard.",
  "pronunciation.saveProgress.saved": "Enregistré",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Enregistrer la phrase et l'évaluation",
  "pronunciation.assessmentStats.noResults": "Aucun résultat à afficher",
  "pronunciation.assessmentStats.pronunciation": "Prononciation",
  "pronunciation.assessmentStats.pronunciationScore": "Note de prononciation",
  "pronunciation.assessmentStats.pronunciationDescription":
    "La note de prononciation représente la qualité de la prononciation dans le discours donné. Elle est calculée en combinant la note de précision, la note de fluidité et la note de complétude, chacune étant soumise à un coefficient spécifique.",
  "pronunciation.assessmentStats.accuracy": "Précision",
  "pronunciation.assessmentStats.accuracyScore":
    "Précision de la prononciation",
  "pronunciation.assessmentStats.accuracyDescription":
    "La précision de la prononciation indique dans quelle mesure les mots prononcés correspondent à ceux d'un locuteur natif. Les notes de précision pour les syllabes, les mots et le texte complet sont basées sur la précision phonémique et ajustées en fonction des objectifs d'évaluation.",
  "pronunciation.assessmentStats.completeness": "Complétude",
  "pronunciation.assessmentStats.completenessScore": "Complétude",
  "pronunciation.assessmentStats.completenessDescription":
    "La complétude du discours est déterminée en comparant le nombre de mots prononcés au nombre total de mots dans le texte de référence.",
  "pronunciation.assessmentStats.fluency": "Fluidité",
  "pronunciation.assessmentStats.fluencyScore": "Fluidité",
  "pronunciation.assessmentStats.fluencyDescription":
    "La fluidité de la parole donnée. La fluidité indique dans quelle mesure la parole correspond à l'utilisation des pauses silencieuses entre les mots par un locuteur natif.",
  "pronunciation.assessmentStats.keepPracticing": "Continuez à vous exercer !",
  "pronunciation.assessmentStats.makingProgress": "Vous faites des progrès !",
  "pronunciation.assessmentStats.wellDone": "Bravo !",
  "pronunciation.assessmentStats.almostThere": "Vous y êtes presque !",
  "pronunciation.assessmentStats.excellentJob": "Excellent travail !",
  "pronunciation.history.saveAssessments": "Évaluations enregistrées",
  "pronunciation.history.noAssessments": "Pas encore d'évaluations",
  "pronunciation.history.score": "Note",
  "pronunciation.history.tryAgain": "Réessayez",
  "account.unableToLoad": "Bienvenue chez {serviceName}",
  "account.title": "Commençons par quelques questions",
  "account.renewFailed": "Langue",
  "account.reviewPaymentDetails": "Quelle est votre langue maternelle?",
  "account.subscriptionInactive": "Langue",
  "account.creditCardDeclined": "Quelle langue voulez-vous pratiquer?",
  "account.updateCreditCard": "Compétence",
  "account.subscriptionManagementPage":
    "Quel est votre niveau de compétence en {practiceLanguage}?",
  "account.questionContactUs": "Vous êtes prêt!",
  "account.billing": "Il est temps de commencer à parler",
  "account.plan": "Parole non détectée ou volume trop bas",
  "account.amount": "Impossible de récupérer le pinyin",
  "account.usage":
    "Impossible de se connecter au serveur d'évaluation de la prononciation",
  "account.usageDescription": "Aucune parole détectée",
  "account.requestRefund": "Erreur",
  "account.manageSubscription":
    "Impossible de charger l'évaluation enregistrée",
  "account.subscriptionManagedBy": "Êtes-vous sûr de vouloir supprimer ce mot?",
  "account.contactAdmin":
    "Impossible de supprimer le mot. Veuillez réessayer dans quelques secondes.",
  "intro.title": "Bienvenue chez {serviceName}",
  "intro.subtitle": "Commençons par quelques questions",
  "intro.userLanguageTitle": "Langue",
  "intro.userLanguageSubtitle": "Quelle est votre langue maternelle?",
  "intro.practiceLanguageTitle": "Langue",
  "intro.practiceLanguageSubtitle": "Quelle langue voulez-vous pratiquer?",
  "intro.proficiencyTitle": "Compétence",
  "intro.proficiencyDescription":
    "Quel est votre niveau de compétence en {practiceLanguage}?",
  "intro.completionModal.title": "Vous êtes prêt !",
  "intro.completionModal.buttonText": "Il est temps de commencer à parler",
  "hooks.useConversation.noSpeech": "Paroles non détectées ou volume trop bas",
  "hooks.useAssessment.unableToFetchPinyin":
    "Impossible de récupérer le pinyin",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Impossible de se connecter au serveur d'évaluation de la prononciation",
  "hooks.useAssessment.noSpeech": "Aucune parole détectée",
  "hooks.useAssessment.retryError": "Erreur",
  "hooks.useAssessment.retryErrorDescription":
    "Impossible de charger l'évaluation enregistrée",
  "hooks.useWordbook.deleteConfirm":
    "Êtes-vous sûr de vouloir supprimer ce mot ?",
  "hooks.useWordbook.unableToDeleteWord":
    "Impossible de supprimer le mot. Veuillez réessayer dans quelques secondes.",
  "components.conversationDownloadButton.export": "Exporter la conversation",
  "components.micPermissionButton.notAllowed":
    "Accès au microphone non autorisé",
  "components.micPermissionButton.turnOnMicAndSound":
    "Activer le microphone et le son",
  "components.micPermissionButton.pleaseAllow":
    "Veuillez autoriser l'accès au microphone",
  "components.languageSelector.managedByOrganization":
    "Géré par l'organisation",
  "components.languageSelector.selectLanguage": "Sélectionner la langue",
  "components.languageSelector.fullList": "Afficher la liste complète",
  "components.languageSelector.simpleList": "Afficher la liste simplifiée",
  "components.proficiencySelector.helpMeChoose": "Aidez-moi à choisir",
  "components.proficiencySelector.descriptionsTitle":
    "Descriptions des compétences",
  "components.proficiencySelector.descriptionsSubtitle":
    "Voici des descriptions des différents niveaux de compétence linguistique.",
  "components.proficiencySelector.beginnerLabel": "Débutant",
  "components.proficiencySelector.beginnerDescription":
    "L'individu peut utiliser des expressions de base pour répondre à des besoins pratiques avec une communication claire et la bonne volonté des autres.",
  "components.proficiencySelector.elementaryLabel": "Élémentaire",
  "components.proficiencySelector.elementaryDescription":
    "L'individu comprend des sujets importants, communique efficacement dans les situations courantes et peut expliquer son parcours et ses besoins.",
  "components.proficiencySelector.intermediateLabel": "Intermédiaire",
  "components.proficiencySelector.intermediateDescription":
    "L'individu comprend et communique des informations de base sur des sujets courants, gère les situations de voyage et exprime ses expériences personnelles, opinions et projets.",
  "components.proficiencySelector.upperIntermediateLabel":
    "Intermédiaire supérieur",
  "components.proficiencySelector.upperIntermediateDescription":
    "L'individu comprend des textes pratiques et théoriques complexes, communique couramment avec les locuteurs natifs, génère des textes complets et exprime son point de vue sur les problèmes actuels.",
  "components.proficiencySelector.advancedLabel": "Avancé",
  "components.proficiencySelector.advancedDescription":
    "L'individu comprend des textes complexes avec des significations implicites, communique couramment et avec maîtrise, et génère des textes bien construits et cohérents sur des sujets compliqués.",
  "components.proficiencySelector.close": "Fermer",
  "components.readSpeedSlider.speed": "Vitesse",
  "components.readSpeedSlider.verySlow": "Très lent",
  "components.readSpeedSlider.slow": "Lent",
  "components.readSpeedSlider.normal": "Normal",
  "components.readSpeedSlider.fast": "Rapide",
  "components.readSpeedSlider.veryFast": "Très rapide",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Moteur de saisie vocale",
  "components.speechRecognitionSelector.default": "Standard",
  "components.speechRecognitionSelector.alternative": "Précision",
  "components.saveWord.save": "Sauvegarder",
  "components.saveWord.notUnderstood":
    "L'IA n'a pas compris ce mot. Veuillez réessayer.",
  "components.saveWord.failed": "Échec de la sauvegarde. Veuillez réessayer.",
  "components.saveWord.savedToWordbook": "enregistré dans votre vocabulaire",
  "components.wordInformation.content.description": "Description",
  "components.wordInformation.content.sentences": "Phrases",
  "components.textWithActions.readAloud": "Lire à haute voix",
  "components.textWithActions.saveWord": "Enregistrer le mot",
  "components.textWithActions.learnMore": "En savoir plus",
  "components.pwaPromptIos.title": "Installez {app} sur votre appareil",
  "components.pwaPromptIos.step1": "Appuyez sur l'icône de partage",
  "components.pwaPromptIos.step2":
    "Sélectionnez « Ajouter à l'écran d'accueil »",
  "components.pwaPromptIos.step3": "Pas besoin de l'App Store !",
  "components.pwaPromptAndroid.title": "Installer l'application",
  "chat.userMessage.perfectSentence1": "Super phrase ! Continuez comme ça !",
  "chat.userMessage.perfectSentence2": "Bon travail !",
  "chat.userMessage.perfectSentence3": "Bravo !",
  "settings.liveFeedback": "Retour instantané",
  "settings.liveFeedbackDescription":
    "Affiche des retours sur la façon d'améliorer votre réponse après chaque message.",
  "dashboard.template.dailySession.title": "Session quotidienne",
  "dashboard.template.dailySession.with": "avec",
  "dashboard.template.dailySession.description":
    "Rejoignez la conversation d'aujourd'hui pour pratiquer et améliorer vos compétences linguistiques",
  "dashboard.template.dailySession.button": "Commencer la session quotidienne",
  "feedbackCard.loader.analyzeGrammar": "Analyse de la grammaire...",
  "feedbackCard.loader.analyzeVocabulary": "Analyse du vocabulaire...",
  "feedbackCard.loader.analyzeStructure":
    "Analyse de la structure de la phrase...",
  "feedbackCard.error.message":
    "Nous sommes désolés, mais nous avons rencontré un problème lors du traitement de votre retour. Veuillez actualiser pour réessayer.",
  "feedbackCard.improvedAnswerLabel": "Réponse améliorée",
  "feedbackCard.grammarLabel": "Grammaire",
  "feedbackCard.vocabularyLabel": "Vocabulaire",
  "feedbackCard.structureLabel": "Structure",
};

export default l;
