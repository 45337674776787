import { useEffect } from "react";
import { useSettings } from "../../../hooks/use-settings";
import { detectIOS } from "../../../utils/detect-ios";
import { SpeechRecognitionProvider } from "../../../database/entity/Settings";
import { logger } from "../../../utils/logger";

export function useSpeechRecognitionProvider() {
  const { settings, saveSettingsPartial } = useSettings();

  // Todo: handle auto punctuation...

  useEffect(() => {
    let providerPreference: SpeechRecognitionProvider = "azure";
    // https://github.com/openai/whisper#available-models-and-languages
    const validCodes = [
      "en",
      "de",
      "fr",
      "it",
      "es",
      "ja",
      "ko",
      "pt",
      "pl",
      "ru",
      "nl",
    ];
    const codePrefix = settings.practiceLanguage.split("-")[0];
    const isIos = detectIOS(); // whisper runs slow on iOS due to bad audio codecs
    const prefersWhisper = validCodes.includes(codePrefix);
    const isMultilingual = settings.multiLingualSpeechRecognition;

    if (prefersWhisper && !isIos && !isMultilingual) {
      providerPreference = "whisper";
    }

    if (settings.speechRecognitionProvider !== providerPreference) {
      logger.verbose(`Save speechRecognitionProvider: ${providerPreference}`);
      saveSettingsPartial({ speechRecognitionProvider: providerPreference });
    }
  }, [
    settings.practiceLanguage,
    settings.multiLingualSpeechRecognition,
    settings.speechRecognitionProvider,
    saveSettingsPartial,
  ]);
}
