const l = {
  language: "Nederlands",
  "app.error": "Fout",
  "app.close": "Sluiten",
  "app.start": "Start",
  "app.noConversations": "Nog geen gesprekken",
  "app.conversation": "Gesprek",
  "app.conversations": "Gesprekken",
  "app.loading": "Laden...",
  "app.tryAgain": "Probeer het opnieuw",
  "app.signOut": "Uitloggen",
  "app.next": "Volgende",
  "app.previous": "Vorige",
  "app.finish": "Voltooien",
  "app.cancel": "Annuleren",
  "app.restart": "Opnieuw starten",
  "layout.unableToConnect": "Kan geen verbinding maken met de server",
  "layout.home": "Startpagina",
  "layout.chat": "Dagelijkse sessie",
  "layout.wordbook": "Woordenboek",
  "layout.pronunciation": "Uitspraak",
  "layout.programs": "Programma's",
  "layout.settings": "Instellingen",
  "layout.account": "Account",
  "layout.manageAccount": "Account beheren",
  "layout.leaveReview": "Laat een beoordeling achter",
  "dashboard.title": "Startpagina",
  "dashboard.subtitle": "Kies hoe u wilt communiceren met uw leraar.",
  "dashboard.aiTutors.title": "AI-leraren",
  "dashboard.aiTutors.description":
    "Uw geselecteerde leraar wacht op u in de volgende chatsessie. Tot snel!",
  "dashboard.template.freeTalk.title": "Nieuwe sessie",
  "dashboard.template.freeTalk.description":
    "Praat over wat u wilt met uw leraar",
  "dashboard.template.rolePlay.title": "Rollenspel",
  "dashboard.template.rolePlay.description":
    "Plaats uzelf in verschillende rollen en speel de scenario's na",
  "dashboard.template.topics.title": "Onderwerp",
  "dashboard.template.topics.description":
    "Laten we praten over een onderwerp naar keuze",
  "dashboard.template.caseStudy.title": "Casusbespreking",
  "dashboard.template.caseStudy.description":
    "Bespreek een casus en beantwoord de vragen hierover",
  "dashboard.template.groupDiscussion.title": "Groepsdiscussie",
  "dashboard.template.groupDiscussion.description":
    "Oefen groepsdiscussies zonder leider",
  "dashboard.template.quiz.title": "Quiz",
  "dashboard.template.quiz.description":
    "Oefen woorden en zinnen met boeiende quizzen",
  "dashboard.template.game.title": "Spelletjes",
  "dashboard.template.game.description":
    "Oefen woordenschat met leuke woordspelletjes",
  "dashboard.template.modal.curated": "Samengesteld",
  "dashboard.template.modal.custom": "Aangepast",
  "dashboard.template.modal.inputContent": "Voer uw eigen inhoud in",
  "dashboard.conversations.description": "Uw volledige lijst met gesprekken",
  "chat.index.failedConnection":
    "Kan geen contact maken met de server en het bericht verzenden",
  "chat.index.audioFailedToSend": "Fout bij het verzenden van audiobericht",
  "chat.index.usageCapMessageTrialTitle": "Wauw, u bent goed bezig!",
  "chat.index.usageCapMessageTrialText":
    "Het lijkt erop dat u het meeste uit uw 7-daagse gratis proefperiode heeft gehaald - dat is geweldig! U heeft de limiet voor deze periode bereikt. Wilt u nu meteen doorgaan? U kunt op elk moment uw plan upgraden en verdergaan waar u was gebleven.",
  "chat.index.usageCapMessageTrialFooterText":
    "We zijn hier om u te begeleiden tijdens uw reis, elke stap van de weg.",
  "chat.index.usageCapMessagePlanTitle": "Let op!",
  "chat.index.usageCapMessagePlanText":
    "U heeft deze maand veel gebruik gemaakt van alle functies en daar zijn we blij om! U heeft uw gebruikslimiet voor het huidige plan bereikt. Om uw ervaring probleemloos te laten verlopen, kunt u het beste even onze planopties bekijken.",
  "chat.index.usageCapMessagePlanFooterText":
    "Geen haast — uw huidige plan wordt volgende maand gereset. We zijn blij dat u bij ons bent!",
  "chat.index.visitAccount": "Bezoek account",
  "chat.index.noMicTitle": "Microfoon niet toegestaan",
  "chat.index.noMicText":
    "Toegang tot microfoon is geblokkeerd door uw browser",
  "chat.asideMenu.voice": "Stem",
  "chat.asideMenu.text": "Tekst",
  "chat.asideMenu.newChat": "Nieuwe chat",
  "chat.asideMenu.pastConversations": "Eerdere gesprekken",
  "chat.asideMenu.noConversations": "Geen gesprekken",
  "chat.asideMenu.autoTranslate": "Automatisch vertalen",
  "chat.asideMenu.showRomanization": "Toon Romeinse schrijfwijze",
  "chat.asideMenu.multiLingualSpeech": "Meertalige spraak",
  "chat.suggestions.titleSm": "Suggestie voor wat u kunt zeggen",
  "chat.suggestions.titleXs": "Suggestie",
  "chat.suggestions.regenerate": "Regenereren",
  "chat.suggestions.generating": "Genereren...",
  "chat.suggestions.failed":
    "Suggestie ophalen mislukt. Probeer opnieuw te genereren.",
  "chat.conversation.tryAgain": "Ga door en probeer opnieuw",
  "chat.conversation.cancel": "Annuleren",
  "chat.speakInput.speak": "Spreken",
  "chat.speakInput.recording": "Opnemen",
  "chat.textInput.sendMessage": "Een bericht sturen",
  "chat.autoSubmitSwitch.handsFree": "Handsfree",
  "chat.shortcuts.hold": "Inhouden",
  "chat.shortcuts.press": "Drukken",
  "chat.shortcuts.release": "Loslaten",
  "chat.shortcuts.toRecord": "om op te nemen",
  "chat.shortcuts.toSend": "om te verzenden",
  "chat.shortcuts.toCancel": "om te annuleren",
  "chat.userMessageActions.speechPrecision": "Spraakprecisie",
  "chat.userMessageActions.speechPrecisionDescription":
    "Spraakprecisie geeft aan hoe zelfverzekerd de leraar uw bericht heeft geïnterpreteerd. Een hoger percentage betekent een grotere nauwkeurigheid in het begrijpen van wat er is gezegd.",
  "chat.userMessageActions.speechPrecisionShowInfo": "Toon informatie",
  "chat.userMessageActions.speechPrecisionHideInfo": "Verberg informatie",
  "chat.userMessageActions.improveMyAnswer": "Mijn antwoord verbeteren",
  "chat.userMessageActions.retry": "Opnieuw proberen",
  "chat.feedbackMessageButton.improveMyAnswer":
    "Mijn laatste antwoord verbeteren",
  "chat.feedbackMessageButton.feedbackOn": "Feedback op",
  "chat.feedbackMessageButton.speechPrecision": "Spraakprecisie",
  "chat.assessmentButton.title": "Gespreksbeoordeling",
  "chat.assessmentButton.close": "Sluiten",
  "chat.assessmentButton.analyzing": "Het hele gesprek analyseren",
  "chat.assessmentButton.tooShort": "Het gesprek is te kort om te evalueren",
  "settings.title": "Instellingen",
  "settings.subtitle": "Pas uw ervaring met de leraar aan.",
  "settings.language": "Taal",
  "settings.yourLanguage": "Uw taal",
  "settings.uiLanguage": "UI-taal",
  "settings.practiceLanguage": "Oefentaal",
  "settings.proficiency": "bekwaamheid",
  "settings.tutor": "Leraar",
  "settings.name": "Naam",
  "settings.talkingSpeed": "Spreektempo",
  "settings.speechInput": "Spraakinvoer",
  "settings.precisionVoiceInputDescription":
    "Biedt uitzonderlijke nauwkeurigheid voor belangrijke Latijnse en Germaanse talen, waaronder Engels, Duits, Spaans, Frans en Italiaans, evenals Aziatische talen zoals Koreaans, Thais en Japans. Als u de kruisgesprekmethode wilt gebruiken en meertalige spraakherkenning wilt inschakelen, selecteer dan de Standaard beoordeling.",
  "settings.standardVoiceInputDescription":
    "Veelzijdige beoordeling die al onze talen ondersteunt. Het is ideaal voor kruisgesprekken en meertalige spraakherkenning.",
  "settings.autoPunctuation": "Automatische interpunctie",
  "settings.autoPunctuationDescription":
    "Voeg automatisch leestekens toe wanneer u pauzeert",
  "settings.multiLingualSpeechRecognition": "Meertalige spraakherkenning",
  "settings.multiLingualSpeechRecognitionDescription":
    "De leraar herkent spraak in zowel de oefentaal als uw moedertaal",
  "settings.translations": "Vertalingen",
  "settings.autoTranslate": "Automatisch vertalen",
  "settings.autoTranslateDescription":
    "Vertaal lerarenberichten automatisch naar uw taal",
  "settings.showRomanization": "Toon Romeinse schrijfwijze",
  "settings.showRomanizationDescription":
    "Toon Romeinse schrijfwijze van het laatste chatbericht voor schriftelijke talen",
  "wordbook.title": "Woordenboek",
  "wordbook.subtitle":
    "Houd de woorden die u leert bij; uw tutor helpt u om ze te gebruiken zodat u ze beter kunt onthouden",
  "wordbook.noWord": "Geen woorden opgeslagen voor de gekozen taal",
  "wordbook.yourPracticeLanguage": "Uw oefentaal",
  "wordbook.word": "Woord",
  "wordbook.addToWordbook": "Toevoegen aan Woordenboek",
  "wordbook.pleaseEnterIn": "Voer een woord in",
  "wordbook.selectWord": "Selecteer een woord",
  "pronunciation.title": "Uitspraak",
  "pronunciation.subtitle": "Oefen uitspraak en spreekvaardigheid",
  "pronunciation.intro":
    "De leraar zal geschikte zinnen genereren, aangepast aan uw vaardigheidsniveau, dat is ingesteld op",
  "pronunciation.startPracticing": "Begin met oefenen",
  "pronunciation.done": "Klaar",
  "pronunciation.cancel": "Annuleren",
  "pronunciation.tryAgain": "Probeer het opnieuw",
  "pronunciation.wordByWord.loading": "Laden...",
  "pronunciation.wordByWord.stopReading": "Stop met lezen",
  "pronunciation.wordByWord.readSentence": "Lees de zin",
  "pronunciation.wordByWord.newSentence": "Nieuwe zin",
  "pronunciation.wordByWord.retrySentence": "Zin opnieuw proberen",
  "pronunciation.saveProgress.progressSaved":
    "De vooruitgang van uw uitspraak is opgeslagen",
  "pronunciation.saveProgress.progressNotSaved":
    "De vooruitgang van uw uitspraak kan niet worden opgeslagen. Controleer uw verbinding en probeer het opnieuw.",
  "pronunciation.saveProgress.saveProgress":
    "Sla zin en score op om de vooruitgang bij te houden en later opnieuw te proberen.",
  "pronunciation.saveProgress.saved": "Opgeslagen",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Zin en beoordeling opslaan",
  "pronunciation.assessmentStats.noResults": "Geen resultaat om te laten zien",
  "pronunciation.assessmentStats.pronunciation": "Uitspraak",
  "pronunciation.assessmentStats.pronunciationScore": "Uitspraakscore",
  "pronunciation.assessmentStats.pronunciationDescription":
    "De Uitspraakscore vertegenwoordigt de kwaliteit van de uitspraak in de gegeven toespraak. Het wordt berekend door de Nauwkeurigheidsscore, Spreekvaardigheidsscore en Volledigheidsscore te combineren, die elk een specifiek gewicht hebben.",
  "pronunciation.assessmentStats.accuracy": "Nauwkeurigheid",
  "pronunciation.assessmentStats.accuracyScore": "Uitspraaknauwkeurigheid",
  "pronunciation.assessmentStats.accuracyDescription":
    "Uitspraaknauwkeurigheid meet hoe goed gesproken woorden overeenkomen met die van een moedertaalspreker. Nauwkeurigheidsscores voor lettergrepen, woorden en volledige tekst zijn gebaseerd op fonemische nauwkeurigheid en aangepast aan de beoordelingsdoelen.",
  "pronunciation.assessmentStats.completeness": "Volledigheid",
  "pronunciation.assessmentStats.completenessScore": "Volledigheid",
  "pronunciation.assessmentStats.completenessDescription":
    "De volledigheid van de spraak wordt bepaald door het aantal uitgesproken woorden te vergelijken met het totale aantal woorden in de referentietekst.",
  "pronunciation.assessmentStats.fluency": "Spreekvaardigheid",
  "pronunciation.assessmentStats.fluencyScore": "Spreekvaardigheid",
  "pronunciation.assessmentStats.fluencyDescription":
    "Spreekvaardigheid van de gegeven toespraak. Spreekvaardigheid geeft aan hoe nauw de spraak overeenkomt met het gebruik van stille pauzes tussen woorden door een moedertaalspreker.",
  "pronunciation.assessmentStats.keepPracticing": "Blijf oefenen!",
  "pronunciation.assessmentStats.makingProgress": "U boekt vooruitgang!",
  "pronunciation.assessmentStats.wellDone": "Goed gedaan!",
  "pronunciation.assessmentStats.almostThere": "Bijna daar!",
  "pronunciation.assessmentStats.excellentJob": "Uitstekend werk!",
  "pronunciation.history.saveAssessments": "Opgeslagen beoordelingen",
  "pronunciation.history.noAssessments": "Nog geen beoordelingen",
  "pronunciation.history.score": "Score",
  "pronunciation.history.tryAgain": "Probeer opnieuw",
  "intro.title": "Welkom bij {serviceName}",
  "intro.subtitle": "Laten we beginnen met een paar vragen",
  "intro.userLanguageTitle": "Taal",
  "intro.userLanguageSubtitle": "Wat is uw moedertaal?",
  "intro.practiceLanguageTitle": "Taal",
  "intro.practiceLanguageSubtitle": "Welke taal wilt u oefenen?",
  "intro.proficiencyTitle": "Beheersing",
  "intro.proficiencyDescription":
    "Wat is uw beheersing van het {practiceLanguage}?",
  "intro.completionModal.title": "U bent klaar!",
  "intro.completionModal.buttonText": "Tijd om te beginnen met praten",
  "hooks.useConversation.noSpeech":
    "Spraak niet gedetecteerd of volume te laag",
  "hooks.useAssessment.unableToFetchPinyin": "Kan pinyin niet ophalen",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Kan geen verbinding maken met de uitspraakbeoordelingsserver",
  "hooks.useAssessment.noSpeech": "Geen spraak gedetecteerd",
  "hooks.useAssessment.retryError": "Fout",
  "hooks.useAssessment.retryErrorDescription":
    "Kan de opgeslagen beoordeling niet laden",
  "hooks.useWordbook.deleteConfirm":
    "Weet u zeker dat u dit woord wilt verwijderen?",
  "hooks.useWordbook.unableToDeleteWord":
    "Kan woord niet verwijderen. Probeer het over een paar seconden opnieuw.",
  "components.conversationDownloadButton.export": "Chat exporteren",
  "components.micPermissionButton.notAllowed":
    "Toegang tot microfoon niet toegestaan",
  "components.micPermissionButton.turnOnMicAndSound":
    "Zet de microfoon en het geluid aan",
  "components.micPermissionButton.pleaseAllow":
    "Sta toegang tot de microfoon toe",
  "components.languageSelector.managedByOrganization":
    "Beheerd door organisatie",
  "components.languageSelector.selectLanguage": "Selecteer een taal",
  "components.languageSelector.fullList": "Toon volledige lijst",
  "components.languageSelector.simpleList": "Toon vereenvoudigde lijst",
  "components.proficiencySelector.helpMeChoose": "Help me kiezen",
  "components.proficiencySelector.descriptionsTitle":
    "Beschrijvingen van de vaardigheidsniveaus",
  "components.proficiencySelector.descriptionsSubtitle":
    "Hier zijn beschrijvingen van de verschillende taalvaardigheidsniveaus.",
  "components.proficiencySelector.beginnerLabel": "Beginner",
  "components.proficiencySelector.beginnerDescription":
    "De persoon kan basisuitdrukkingen gebruiken om praktische behoeften te vervullen met duidelijke communicatie en bereidheid van anderen.",
  "components.proficiencySelector.elementaryLabel": "Elementair",
  "components.proficiencySelector.elementaryDescription":
    "De persoon begrijpt belangrijke onderwerpen, communiceert effectief in routinematige zaken en kan zijn of haar achtergrond en behoeften uitleggen.",
  "components.proficiencySelector.intermediateLabel": "Gemiddeld",
  "components.proficiencySelector.intermediateDescription":
    "De persoon begrijpt en communiceert basisinformatie over algemene onderwerpen, beheert reissituaties en drukt persoonlijke ervaringen, meningen en plannen uit.",
  "components.proficiencySelector.upperIntermediateLabel": "Bovengemiddeld",
  "components.proficiencySelector.upperIntermediateDescription":
    "De persoon begrijpt complexe praktische en theoretische teksten, communiceert vloeiend met moedertaalsprekers, genereert uitgebreide teksten en drukt zijn of haar perspectief uit over actuele kwesties.",
  "components.proficiencySelector.advancedLabel": "Gevorderd",
  "components.proficiencySelector.advancedDescription":
    "De persoon begrijpt complexe teksten met impliciete betekenissen, communiceert vloeiend en vaardig, en genereert goed opgebouwde en coherente teksten over ingewikkelde onderwerpen.",
  "components.proficiencySelector.close": "Sluiten",
  "components.readSpeedSlider.speed": "Snelheid",
  "components.readSpeedSlider.verySlow": "Zeer langzaam",
  "components.readSpeedSlider.slow": "Langzaam",
  "components.readSpeedSlider.normal": "Normaal",
  "components.readSpeedSlider.fast": "Snel",
  "components.readSpeedSlider.veryFast": "Zeer snel",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Spraak Invoer Beoordeling",
  "components.speechRecognitionSelector.default": "Standaard",
  "components.speechRecognitionSelector.alternative": "Nauwkeurigheid",
  "components.saveWord.save": "Opslaan",
  "components.saveWord.notUnderstood":
    "De AI begreep dit woord niet. Probeer het opnieuw.",
  "components.saveWord.failed": "Opslaan mislukt. Probeer het opnieuw.",
  "components.saveWord.savedToWordbook": "opgeslagen in uw woordenboek",
  "components.wordInformation.content.description": "Beschrijving",
  "components.wordInformation.content.sentences": "Zinnen",
  "components.textWithActions.readAloud": "Lees hardop",
  "components.textWithActions.saveWord": "Woord opslaan",
  "components.textWithActions.learnMore": "Meer leren",
  "components.pwaPromptIos.title": "Installeer {app} op uw apparaat",
  "components.pwaPromptIos.step1": "Tik op het Delen-pictogram",
  "components.pwaPromptIos.step2": "Selecteer 'Voeg toe aan beginscherm'",
  "components.pwaPromptIos.step3": "Geen App Store nodig!",
  "components.pwaPromptAndroid.title": "Installeer de app",
  "chat.userMessage.perfectSentence1": "Geweldige zin! Ga zo door!",
  "chat.userMessage.perfectSentence2": "Goed gedaan!",
  "chat.userMessage.perfectSentence3": "Goed zo!",
  "settings.liveFeedback": "Directe feedback",
  "settings.liveFeedbackDescription":
    "Geeft feedback weer over hoe u uw antwoord na elk bericht kunt verbeteren.",
  "dashboard.template.dailySession.title": "Dagelijkse sessie",
  "dashboard.template.dailySession.with": "met",
  "dashboard.template.dailySession.description":
    "Doe mee aan het gesprek van vandaag om uw taalvaardigheden te oefenen en te verbeteren",
  "dashboard.template.dailySession.button": "Begin de dagelijkse sessie",
  "feedbackCard.loader.analyzeGrammar": "Grammatica analyseren...",
  "feedbackCard.loader.analyzeVocabulary": "Woordenschat analyseren...",
  "feedbackCard.loader.analyzeStructure": "Zinsstructuur analyseren...",
  "feedbackCard.error.message":
    "Het spijt ons, maar er is een probleem opgetreden bij het verwerken van uw feedback. Vernieuw de pagina om het opnieuw te proberen.",
  "feedbackCard.improvedAnswerLabel": "Verbeterd antwoord",
  "feedbackCard.grammarLabel": "Grammatica",
  "feedbackCard.vocabularyLabel": "Woordenschat",
  "feedbackCard.structureLabel": "Structuur",
};

export default l;
