import "@fontsource-variable/raleway";
import "@fontsource-variable/open-sans";
import Script from "next/script";
import "../styles/fugazone/stylesheet.css";
import "../styles/markdown.css";
import "../styles/chat.scss";
import "../styles/app.scss";
import "../styles/hub.scss";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { Session } from "next-auth";
import { ChakraProvider, useToast } from "@chakra-ui/react";
import Layout from "../components/layout";
import ErrorModal, {
  ErrorModalContext,
  ErrorModalState,
} from "../components/error-modal";
import { useState } from "react";
import { SWRConfig } from "swr";
import { theme } from "../styles/chakra-theme";
import CookieConsent from "react-cookie-consent";
import { AppError } from "../utils/app-error";
import * as Sentry from "@sentry/browser";
import { RewardfulTag } from "../components/rewardful-tag";
import { siteConfig } from "../configuration/config";
import { WhatsAppBubble } from "../components/whatsapp-bubble";
import { SupportBubble } from "../components/support-bubble";

function App({
  Component,
  pageProps,
  router,
}: AppProps<{
  session: Session;
}>) {
  const [errorModalState, setErrorModalState] = useState<ErrorModalState>();
  const toast = useToast();

  const hideCookieConsent =
    router.pathname.includes("app") || !siteConfig.isDefault;

  const pathNameArr = router.pathname.split("/");
  const pathname = pathNameArr[pathNameArr.length - 1];
  const isFrontpage = router.pathname === "/";
  let title = pathname?.replace("-", " ").trim();
  title = title.charAt(0).toUpperCase() + title.slice(1);
  title = isFrontpage
    ? `${siteConfig.serviceName} | Practice Language with AI`
    : title + ` |   ${siteConfig.serviceName}`;

  const enableSupport =
    router.pathname === "/" ||
    router.pathname === "/app/settings" ||
    router.pathname.includes("/hub") ||
    router.pathname.includes("/partners") ||
    router.pathname.includes("/authentication/signin") ||
    router.pathname.includes("/authentication/error") ||
    router.pathname.includes("/authentication/partner/error") ||
    router.pathname === "/app/account";

  const showTidio = siteConfig.showTidio && enableSupport;

  const showWhatsApp =
    siteConfig.whatsAppNumber && !siteConfig.showTidio && enableSupport;

  const showSupportBubble =
    siteConfig.supportLink &&
    !showWhatsApp &&
    !siteConfig.showTidio &&
    enableSupport;

  const image = "/open-graph.png";
  const url = `https://www.talkio.ai${router.asPath}`;
  const description =
    "The ultimate language training app that uses AI technology to help you improve your oral language skills.";

  return (
    <SessionProvider session={pageProps.session}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href={siteConfig.favicon} />

        <link rel="canonical" href={url} key="canonical" />

        {siteConfig.fontLink && (
          <link href={siteConfig.fontLink} rel="stylesheet" />
        )}

        {isFrontpage && (
          <>
            <link rel="alternate" href="https://www.talkio.ai/" hrefLang="en" />
            <link rel="alternate" href="https://www.talkio.pl/" hrefLang="pl" />
            <link
              rel="alternate"
              href="https://cn.talkio.ai/"
              hrefLang="zh-Hans"
            />
          </>
        )}

        {siteConfig.isDefault && (
          <>
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />

            {!router.pathname.includes("blog") && (
              <>
                <meta property="og:image" content={image} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
              </>
            )}

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="www.talkio.ai" />
            <meta property="twitter:url" content={url} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </>
        )}

        {!siteConfig.isDefault && <meta name="robots" content="noindex" />}
      </Head>

      {showTidio && (
        <Script src="//code.tidio.co/eymtfxyuxrq8uwqwesxn6vzpggoajzjx.js" />
      )}

      {showWhatsApp && siteConfig.whatsAppNumber && (
        <WhatsAppBubble whatsAppNumber={siteConfig.whatsAppNumber} />
      )}

      {showSupportBubble && siteConfig.supportLink && (
        <SupportBubble supportLink={siteConfig.supportLink} />
      )}

      <RewardfulTag />

      <div className={siteConfig?.hideChrome ? "partner-hide-chrome" : ""}>
        <ChakraProvider theme={theme}>
          <Layout router={router}>
            <ErrorModal state={errorModalState} />

            {hideCookieConsent ? null : (
              <CookieConsent
                flipButtons
                location="bottom"
                buttonText="I understand"
                style={{ background: "#333" }}
                buttonStyle={{
                  color: "#000",
                  fontSize: "15px",
                  backgroundColor: "#f6d78b",
                }}
                declineButtonStyle={{
                  margin: "10px 10px 10px 0",
                }}
                expires={365}
              >
                This website uses cookies.
                <a
                  href="/legal/privacy"
                  target="_blank"
                  style={{ color: "#f6d78b", fontWeight: "bold" }}
                >
                  {" "}
                  Read more.
                </a>
              </CookieConsent>
            )}

            <SWRConfig
              value={{
                onError: (error, key) => {
                  if (error instanceof AppError) {
                    if (error?.meta?.uiAlert === "fatal") {
                      setErrorModalState({
                        show: true,
                        message: error.publicMessage,
                      });
                    } else if (error?.meta?.uiAlert === "toast") {
                      toast({
                        title: error.publicMessage,
                        status: "error",
                        isClosable: true,
                      });
                    }
                  } else {
                    setErrorModalState({
                      show: true,
                      message: "Unknown error",
                    });

                    Sentry.captureException(error, {
                      extra: {
                        origin: "SWRConfig onError",
                      },
                    });
                  }
                },
              }}
            >
              <ErrorModalContext.Provider
                value={{
                  errorModalState,
                  setErrorModalState,
                }}
              >
                <Component {...pageProps} />
              </ErrorModalContext.Provider>
            </SWRConfig>
          </Layout>
        </ChakraProvider>
      </div>
    </SessionProvider>
  );
}

export default App;
