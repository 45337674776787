import { createContext, useState } from "react";
import useStateRef from "react-usestateref";

const initialState = {
  currentSuggestion: undefined as string | undefined,
  lastSuggestion: undefined as string | undefined,
};

type ChatState = typeof initialState;
export type UseChatState = ReturnType<typeof useChatState>;
export const ChatStateContext = createContext<UseChatState>({} as UseChatState);

export function useChatState() {
  const [chatState, setChatState, chatStateRef] =
    useStateRef<ChatState>(initialState);

  const setChatStatePartial = (chatState: Partial<ChatState>) => {
    setChatState((prev) => ({
      ...prev,
      ...chatState,
    }));
  };

  return {
    chatState,
    chatStateRef,
    setChatStatePartial,
  };
}
