import en_US1 from "./master1";
import en_US2 from "./master2";
import en_US3 from "./master3";

// Generated locales
import da_DK1 from "./generated/da-DK1";
import da_DK2 from "./generated/da-DK2";
import da_DK3 from "./generated/da-DK3";

import pt_BR1 from "./generated/pt-BR1";
import pt_BR2 from "./generated/pt-BR2";
import pt_BR3 from "./generated/pt-BR3";

import pl_PL1 from "./generated/pl-PL1";
import pl_PL2 from "./generated/pl-PL2";
import pl_PL3 from "./generated/pl-PL3";

import zh_CN1 from "./generated/zh-CN1";
import zh_CN2 from "./generated/zh-CN2";
import zh_CN3 from "./generated/zh-CN3";

import fr_FR1 from "./generated/fr-FR1";
import fr_FR2 from "./generated/fr-FR2";
import fr_FR3 from "./generated/fr-FR3";

import it_IT1 from "./generated/it-IT1";
import it_IT2 from "./generated/it-IT2";
import it_IT3 from "./generated/it-IT3";

import es_MX1 from "./generated/es-MX1";
import es_MX2 from "./generated/es-MX2";
import es_MX3 from "./generated/es-MX3";

import jp_JP1 from "./generated/jp-JP1";
import jp_JP2 from "./generated/jp-JP2";
import jp_JP3 from "./generated/jp-JP3";

import ko_KR1 from "./generated/ko-KR1";
import ko_KR2 from "./generated/ko-KR2";
import ko_KR3 from "./generated/ko-KR3";

import nl_NL1 from "./generated/nl-NL1";
import nl_NL2 from "./generated/nl-NL2";
import nl_NL3 from "./generated/nl-NL3";

import he_IL1 from "./generated/he-IL1";
import he_IL2 from "./generated/he-IL2";
import he_IL3 from "./generated/he-IL3";

import de_DE1 from "./generated/de-DE1";
import de_DE2 from "./generated/de-DE2";
import de_DE3 from "./generated/de-DE3";

// Manual overrides
import o_da_DK from "./overrides/da-DK";
import o_pt_BR1 from "./overrides/pt-BR1";
import o_pt_BR2 from "./overrides/pt-BR2";
import o_pt_BR3 from "./overrides/pt-BR3";
import o_pl_PL1 from "./overrides/pl-PL1";
import o_pl_PL2 from "./overrides/pl-PL2";
import o_zh_CN from "./overrides/zh-CN";

import o_fr_FR from "./overrides/fr-FR";
import o_it_IT from "./overrides/it-IT";
import o_es_MX from "./overrides/es-MX";
import o_jp_JP from "./overrides/jp-JP";
import o_ko_KR from "./overrides/ko-KR";
import o_nl_NL from "./overrides/nl-NL";
import o_he_IL from "./overrides/he-IL";
import o_de_DE from "./overrides/de-DE";

const en_US = { ...en_US1, ...en_US2, ...en_US3 };
const da_DK = { ...da_DK1, ...da_DK2, ...da_DK3, ...o_da_DK };
const pt_BR = {
  ...pt_BR1,
  ...pt_BR2,
  ...pt_BR3,
  ...o_pt_BR1,
  ...o_pt_BR2,
  ...o_pt_BR3,
};
const pl_PL = { ...pl_PL1, ...pl_PL2, ...pl_PL3, ...o_pl_PL1, ...o_pl_PL2 };
const zh_CN = { ...zh_CN1, ...zh_CN2, ...zh_CN3, ...o_zh_CN };

const fr_FR = { ...fr_FR1, ...fr_FR2, ...fr_FR3, ...o_fr_FR };
const it_IT = { ...it_IT1, ...it_IT2, ...it_IT3, ...o_it_IT };
const es_MX = { ...es_MX1, ...es_MX2, ...es_MX3, ...o_es_MX };
const jp_JP = { ...jp_JP1, ...jp_JP2, ...jp_JP3, ...o_jp_JP };
const ko_KR = { ...ko_KR1, ...ko_KR2, ...ko_KR3, ...o_ko_KR };
const nl_NL = { ...nl_NL1, ...nl_NL2, ...nl_NL3, ...o_nl_NL };
const he_IL = { ...he_IL1, ...he_IL2, ...he_IL3, ...o_he_IL };
const de_DE = { ...de_DE1, ...de_DE2, ...de_DE3, ...o_de_DE };

export type AppLocale = typeof en_US;

export const locales: Record<string, AppLocale> = {
  en_US,
  pt_BR,
  es_MX,
  de_DE,
  fr_FR,
  it_IT,
  zh_CN,
  jp_JP,
  ko_KR,
  nl_NL,
  he_IL,
  pl_PL,
  da_DK,
};

export type Locale =
  | "en-US"
  | "zh-CN"
  | "pt-BR"
  | "pl-PL"
  | "da-DK"
  | "fr-FR"
  | "it-IT"
  | "es-MX"
  | "jp-JP"
  | "ko-KR"
  | "nl-NL"
  | "he-IL"
  | "de-DE";

export const overrides: Record<string, Partial<AppLocale>> = {
  da_DK: o_da_DK,
  pt_BR: { ...o_pt_BR1, ...o_pt_BR2, ...o_pt_BR3 },
  pl_PL: { ...o_pl_PL1, ...o_pl_PL2 },
  zh_CN: o_zh_CN,
  fr_FR: o_fr_FR,
  it_IT: o_it_IT,
  es_MX: o_es_MX,
  jp_JP: o_jp_JP,
  ko_KR: o_ko_KR,
  nl_NL: o_nl_NL,
  he_IL: o_he_IL,
  de_DE: o_de_DE,
};

const localesWithOverrides = {
  ...locales,
  ...overrides,
} as Record<string, AppLocale>;

// e.g. [["en_US", en_US.language]]
export const uiLanguageSelectorLabels = Object.entries(
  localesWithOverrides
).map(([key, value]) => [key, value.language]);

export const uiLanguageKeys = Object.keys(localesWithOverrides);
