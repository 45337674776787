import { fetchApi } from "../services/fetch-api";
import { AppError } from "../utils/app-error";
import useSWR from "swr";
import { UserGroups } from "../pages/api/groups/member";
import { useMemo } from "react";

export function useUserGroups() {
  const response = useSWR<UserGroups, AppError>(`/groups/member`, fetchApi);
  const hasGroups = useMemo(() => {
    if (response.data === undefined) {
      return false;
    }

    return (
      response.data?.memberGroups.length > 0 ||
      response.data?.facilitatorGroups.length > 0
    );
  }, [response.data]);

  return {
    hasGroups,
    groups: response.data,
    groupsError: response.error,
    groupsAreLoading: response.isLoading,
  };
}
