import { createContext, useEffect, useMemo, useState } from "react";
import { landingLocales, landingOverrides } from "../localization/landing";
import { LandingLocale } from "../localization/landing";
import { logger } from "../utils/logger";
import { langCodeToLocale } from "./use-localization";

export type LandingLocalization = ReturnType<typeof useLandingLocalization>;

/**
 * @deprecated - use server side localeFromHeader instead
 */
export const LandingLocalizationContext = createContext<LandingLocalization>(
  {} as LandingLocalization
);

export function useLandingLocalization() {
  const defaultLocale = langCodeToLocale("en-US");
  const [localeKey, setLocaleKey] = useState<string>(defaultLocale);

  useEffect(() => {
    Object.keys(landingLocales).forEach((key) => {
      if (key.includes(navigator.language)) {
        setLocaleKey(langCodeToLocale(key));
      }
    });
  }, []);

  const locale: LandingLocale = useMemo(() => {
    const locale = landingLocales[localeKey];
    const override = landingOverrides[localeKey];

    if (!locale) {
      logger.error(`Locale not found: ${localeKey}`);
      return landingLocales[defaultLocale];
    }

    return {
      ...locale,
      ...override,
    };
  }, [localeKey, defaultLocale]);

  return { l: locale };
}
