import { useRef, useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { useSettings } from "../../../hooks/use-settings";
import { logger } from "../../../utils/logger";

export function useAudioPulse(
  audioSource: HTMLAudioElement | MediaStream | undefined,
  isRecording: boolean
) {
  let interval = useRef<NodeJS.Timeout>();
  const { settings } = useSettings();
  const [audioPulse, setAudioPulse] = useState<number>(0);
  const [hasPulse, setHasPulse, hasPulseRef] = useStateRef(false);

  useEffect(() => {
    const threshold = settings.autoSubmitTranscription ? 15 : 5;

    if (isRecording && audioPulse > threshold) {
      setHasPulse(true);
    }

    if (!isRecording) {
      setHasPulse(false);
    }
  }, [isRecording, audioPulse, settings, setHasPulse]);

  useEffect(() => {
    if (!audioSource) {
      return; // Exit early if there's no audio element
    }

    const audioCtx = new AudioContext();
    let sourceNode: MediaElementAudioSourceNode | MediaStreamAudioSourceNode;

    if (audioSource instanceof HTMLAudioElement) {
      sourceNode = audioCtx.createMediaElementSource(audioSource);
    } else if (audioSource instanceof MediaStream) {
      sourceNode = audioCtx.createMediaStreamSource(audioSource);
    } else {
      logger.error("Invalid audio source type");
      return;
    }

    //const sourceNode = audioCtx.createMediaElementSource(audioElement);
    const analyser = audioCtx.createAnalyser();

    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    sourceNode.connect(analyser);
    // only relevant if you use it for read aloud
    // sourceNode.connect(audioCtx.destination); // so you can hear the audio

    interval.current = setInterval(() => {
      analyser.getByteTimeDomainData(dataArray);

      let minVal = 255;
      let maxVal = 0;
      for (let i = 0; i < bufferLength; i++) {
        minVal = Math.min(minVal, dataArray[i]);
        maxVal = Math.max(maxVal, dataArray[i]);
      }

      const percentage = ((maxVal - minVal) / 128) * 100;
      setAudioPulse(percentage);
    }, 100);

    // Cleanup function
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }

      // Disconnect the audio nodes
      sourceNode.disconnect(analyser);
      //sourceNode.disconnect(audioCtx.destination);

      analyser.disconnect();

      // Close the AudioContext
      audioCtx.close();
    };
  }, [audioSource]);

  return { audioPulse, hasPulseRef };
}
