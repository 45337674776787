const l = {
  language: "日本語",
  "app.error": "エラー",
  "app.close": "閉じる",
  "app.start": "スタート",
  "app.noConversations": "まだ会話がありません",
  "app.conversation": "会話",
  "app.conversations": "会話",
  "app.loading": "読み込み中...",
  "app.tryAgain": "もう一度お試しください",
  "app.signOut": "サインアウト",
  "app.next": "次へ",
  "app.previous": "前へ",
  "app.finish": "完了",
  "app.cancel": "キャンセル",
  "app.restart": "再起動",
  "layout.unableToConnect": "サーバーに接続できません",
  "layout.home": "ホーム",
  "layout.chat": "デイリーセッション",
  "layout.wordbook": "単語帳",
  "layout.pronunciation": "発音",
  "layout.programs": "プログラム",
  "layout.settings": "設定",
  "layout.account": "アカウント",
  "layout.manageAccount": "アカウント管理",
  "layout.leaveReview": "レビューを書く",
  "dashboard.title": "ホーム",
  "dashboard.subtitle": "チューターとのやり取り方法を選択してください。",
  "dashboard.aiTutors.title": "AIチューター",
  "dashboard.aiTutors.description":
    "選択されたチューターは、次のチャットセッションであなたをお待ちしています。 またお会いしましょう！",
  "dashboard.template.freeTalk.title": "新しいセッション",
  "dashboard.template.freeTalk.description": "チューターと何でも話してください",
  "dashboard.template.rolePlay.title": "ロールプレイ",
  "dashboard.template.rolePlay.description":
    "さまざまな役割を演じ、シナリオを演じる",
  "dashboard.template.topics.title": "トピック",
  "dashboard.template.topics.description":
    "お好きなトピックについて話しましょう",
  "dashboard.template.caseStudy.title": "ケースディスカッション",
  "dashboard.template.caseStudy.description":
    "ケース(事例)を議論し、それについての質問に答える",
  "dashboard.template.groupDiscussion.title": "グループディスカッション",
  "dashboard.template.groupDiscussion.description":
    "リーダーレスのグループディスカッションの練習をする",
  "dashboard.template.quiz.title": "クイズ",
  "dashboard.template.quiz.description":
    "興味を引くクイズで単語や文章の練習をする",
  "dashboard.template.game.title": "ゲーム",
  "dashboard.template.game.description": "楽しい言葉のゲームで語彙の練習をする",
  "dashboard.template.modal.curated": "キュレーション",
  "dashboard.template.modal.custom": "カスタム",
  "dashboard.template.modal.inputContent": "自分のコンテンツを入力",
  "dashboard.conversations.description": "あなたのすべての会話リスト",
  "chat.index.failedConnection":
    "サーバーに接続できないため、メッセージを送信できません",
  "chat.index.audioFailedToSend": "音声メッセージの送信エラー",
  "chat.index.usageCapMessageTrialTitle": "すごい！順調ですね！",
  "chat.index.usageCapMessageTrialText":
    "7日間の無料トライアルを最大限に活用されたようですね 。素晴らしい！あいにく、この期間の文字数の上限に達しました。今すぐこのまま続けたいですか？ いつでもプランをアップグレードして、途中から続けることができます。",
  "chat.index.usageCapMessageTrialFooterText":
    "私たちはあなたの旅をあらゆる局面でサポートいたします。",
  "chat.index.usageCapMessagePlanTitle": "ご注意ください！",
  "chat.index.usageCapMessagePlanText":
    "今月はすべての機能をたくさん楽しんでいただき、うれしいです！ あいにく現在のプランの使用上限に達しました。 シームレスな体験を維持するために、プランのオプションを確認する時間をぜひお取りください。",
  "chat.index.usageCapMessagePlanFooterText":
    "急がなくても大丈夫です。現在のプランは来月リセットされます。 私たちはあなたがここにいて嬉しく思います！",
  "chat.index.visitAccount": "アカウントを訪問",
  "chat.index.noMicTitle": "マイクが許可されていません",
  "chat.index.noMicText":
    "ブラウザによってマイクへのアクセスがブロックされています",
  "chat.asideMenu.voice": "音声",
  "chat.asideMenu.text": "テキスト",
  "chat.asideMenu.newChat": "新しいチャット",
  "chat.asideMenu.pastConversations": "過去の会話",
  "chat.asideMenu.noConversations": "会話なし",
  "chat.asideMenu.autoTranslate": "自動翻訳",
  "chat.asideMenu.showRomanization": "ローマ字表示",
  "chat.asideMenu.multiLingualSpeech": "多言語発話",
  "chat.suggestions.titleSm": "言うべきことの提案",
  "chat.suggestions.titleXs": "提案",
  "chat.suggestions.regenerate": "再生成",
  "chat.suggestions.generating": "生成中...",
  "chat.suggestions.failed":
    "提案の取得に失敗しました。再生成してみてください。",
  "chat.conversation.tryAgain": "どうぞもう一度お試しください",
  "chat.conversation.cancel": "キャンセル",
  "chat.speakInput.speak": "話す",
  "chat.speakInput.recording": "録音中",
  "chat.textInput.sendMessage": "メッセージを送る",
  "chat.autoSubmitSwitch.handsFree": "ハンズフリー",
  "chat.shortcuts.hold": "押し続ける",
  "chat.shortcuts.press": "押す",
  "chat.shortcuts.release": "放す",
  "chat.shortcuts.toRecord": "録音する",
  "chat.shortcuts.toSend": "送信する",
  "chat.shortcuts.toCancel": "キャンセルする",
  "chat.userMessageActions.speechPrecision": "発話の精度",
  "chat.userMessageActions.speechPrecisionDescription":
    "発話の精度は、チューターがあなたのメッセージをどれだけ自信を持って解釈したかを示します。パーセンテージが高いほど、発言内容の理解が正確になります。",
  "chat.userMessageActions.speechPrecisionShowInfo": "情報を表示",
  "chat.userMessageActions.speechPrecisionHideInfo": "情報を非表示",
  "chat.userMessageActions.improveMyAnswer": "答えを改善する",
  "chat.userMessageActions.retry": "再試行",
  "chat.feedbackMessageButton.improveMyAnswer": "最後の答えを改善する",
  "chat.feedbackMessageButton.feedbackOn": "フィードバック：",
  "chat.feedbackMessageButton.speechPrecision":
    "発話の精度は、チューターがあなたのメッセージをどれだけ自信を持って解釈したかを示します。パーセンテージが高いほど、発言内容の理解が正確になります。",
  "chat.assessmentButton.title": "会話の評価",
  "chat.assessmentButton.close": "閉じる",
  "chat.assessmentButton.analyzing": "会話全体を分析中",
  "chat.assessmentButton.tooShort": "会話が短すぎて評価できません",
  "settings.title": "設定",
  "settings.subtitle": "チューターとの体験をカスタマイズする。",
  "settings.language": "言語",
  "settings.yourLanguage": "あなたの言語",
  "settings.uiLanguage": "UI言語",
  "settings.practiceLanguage": "練習言語",
  "settings.proficiency": "熟練度",
  "settings.tutor": "チューター",
  "settings.name": "名前",
  "settings.talkingSpeed": "話す速度",
  "settings.speechInput": "音声入力",
  "settings.precisionVoiceInputDescription":
    "主要なロマンス諸語とゲルマン語派（英語、ドイツ語、スペイン語、フランス語、イタリア語）だけでなく、韓国語、タイ語、日本語などのアジア言語でも優れた精度を発揮します。 クロストーク方式を使用して複数言語の音声認識を有効にしたい場合は、標準エンジンを選択してください。",
  "settings.standardVoiceInputDescription":
    "すべての言語に対応する多機能エンジン。クロストークや多言語音声認識に最適です。",
  "settings.autoPunctuation": "自動句読点",
  "settings.autoPunctuationDescription": "停止すると自動的に句読点を追加します",
  "settings.multiLingualSpeechRecognition": "多言語音声認識",
  "settings.multiLingualSpeechRecognitionDescription":
    "チューターは、練習言語と母国語の両方の音声を認識します",
  "settings.translations": "翻訳",
  "settings.autoTranslate": "自動翻訳",
  "settings.autoTranslateDescription":
    "講師のメッセージをあなたの言語に自動翻訳します",
  "settings.showRomanization": "ローマ字表示",
  "settings.showRomanizationDescription":
    "表記言語の最新のチャットメッセージをローマ字で表示する",
  "wordbook.title": "単語帳",
  "wordbook.subtitle":
    "学んだ単語を追跡します。チューターがそれらを使って記憶力を高めるお手伝いをしてくれます",
  "wordbook.noWord": "選択された言語で保存された単語はありません",
  "wordbook.yourPracticeLanguage": "あなたの練習言語",
  "wordbook.word": "単語",
  "wordbook.addToWordbook": "単語帳に追加",
  "wordbook.pleaseEnterIn": "この言語で単語を1つ入力してください：",
  "wordbook.selectWord": "単語を1つ選択",
  "pronunciation.title": "発音",
  "pronunciation.subtitle": "発音と流暢さの練習をする",
  "pronunciation.intro":
    "チューターは、次の通り設定されているあなたの習熟度に合わせて適切な文章を生成します：",
  "pronunciation.startPracticing": "練習を始める",
  "pronunciation.done": "完了",
  "pronunciation.cancel": "キャンセル",
  "pronunciation.tryAgain": "もう一度お試しください",
  "pronunciation.wordByWord.loading": "読み込み中...",
  "pronunciation.wordByWord.stopReading": "読むのをやめる",
  "pronunciation.wordByWord.readSentence": "文を読む",
  "pronunciation.wordByWord.newSentence": "新しい文",
  "pronunciation.wordByWord.retrySentence": "文を再試行",
  "pronunciation.saveProgress.progressSaved":
    "あなたの発音の進捗が保存されました",
  "pronunciation.saveProgress.progressNotSaved":
    "発音の進捗を保存できませんでした。接続を確認して、もう一度お試しください。",
  "pronunciation.saveProgress.saveProgress":
    "進捗を追跡し、後で再試行するために文とスコアを保存します。",
  "pronunciation.saveProgress.saved": "保存されました",
  "pronunciation.saveProgress.saveSentenceAndAssessment": "文と評価を保存",
  "pronunciation.assessmentStats.noResults": "表示する結果がありません",
  "pronunciation.assessmentStats.pronunciation": "発音",
  "pronunciation.assessmentStats.pronunciationScore": "発音スコア",
  "pronunciation.assessmentStats.pronunciationDescription":
    "発音スコアは、特定の発言における発音の質を表します。精度スコア・流暢さスコア・完全性スコアを特定の重み付けで組み合わせることで計算されます。",
  "pronunciation.assessmentStats.accuracy": "精度",
  "pronunciation.assessmentStats.accuracyScore": "発音の精度",
  "pronunciation.assessmentStats.accuracyDescription":
    "発音の精度は、話された単語がネイティブスピーカーのそれにどれほど一致しているかを測定します。音素レベルの精度に基づいて、音節・単語・全文の精度スコアが評価目標に応じて調整されます。",
  "pronunciation.assessmentStats.completeness": "完全性",
  "pronunciation.assessmentStats.completenessScore": "完全性",
  "pronunciation.assessmentStats.completenessDescription":
    "発話の完全性は、発音された単語の数を参照テキストの総単語数と比較することで判断されます。",
  "pronunciation.assessmentStats.fluency": "流暢さ",
  "pronunciation.assessmentStats.fluencyScore": "流暢さ",
  "pronunciation.assessmentStats.fluencyDescription":
    "特定の発話の流暢さ。流暢さは、母語話者による単語間の無音の休止に発話がどれだけ合致するかを示します。",
  "pronunciation.assessmentStats.keepPracticing": "練習を続けてください！",
  "pronunciation.assessmentStats.makingProgress": "上達しています！",
  "pronunciation.assessmentStats.wellDone": "よくできました！",
  "pronunciation.assessmentStats.almostThere": "もう少しです！",
  "pronunciation.assessmentStats.excellentJob": "素晴らしいですね！",
  "pronunciation.history.saveAssessments": "保存された評価",
  "pronunciation.history.noAssessments": "まだ評価がありません",
  "pronunciation.history.score": "スコア",
  "pronunciation.history.tryAgain": "再試行",
  "intro.title": "{serviceName}へようこそ",
  "intro.subtitle": "いくつかの質問から始めましょう",
  "intro.userLanguageTitle": "言語",
  "intro.userLanguageSubtitle": "あなたの母語は何ですか？",
  "intro.practiceLanguageTitle": "言語",
  "intro.practiceLanguageSubtitle": "どの言語を練習したいですか？",
  "intro.proficiencyTitle": "習熟度の説明",
  "intro.proficiencyDescription":
    "{practiceLanguage}の習熟度はどのくらいですか？",
  "intro.completionModal.title": "すべて準備が整いました！",
  "intro.completionModal.buttonText": "話し始める時間です",
  "hooks.useConversation.noSpeech":
    "スピーチが検出されなかったか、音量が低すぎます",
  "hooks.useAssessment.unableToFetchPinyin": "ピンインを取得できません",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "発音評価サーバーに接続できません",
  "hooks.useAssessment.noSpeech": "スピーチが検出されませんでした",
  "hooks.useAssessment.retryError": "エラー",
  "hooks.useAssessment.retryErrorDescription": "保存された評価を読み込めません",
  "hooks.useWordbook.deleteConfirm": "この単語を削除してもよろしいですか？",
  "hooks.useWordbook.unableToDeleteWord":
    "単語を削除できませんでした。数秒後にもう一度お試しください。",
  "components.conversationDownloadButton.export": "チャットをエクスポート",
  "components.micPermissionButton.notAllowed":
    "マイクへのアクセスが許可されていません",
  "components.micPermissionButton.turnOnMicAndSound":
    "マイクとサウンドをオンにする",
  "components.micPermissionButton.pleaseAllow":
    "マイクへのアクセスを許可してください",
  "components.languageSelector.managedByOrganization":
    "組織によって管理されています",
  "components.languageSelector.selectLanguage": "言語を選択",
  "components.languageSelector.fullList": "完全なリストを表示",
  "components.languageSelector.simpleList": "簡略リストを表示",
  "components.proficiencySelector.helpMeChoose": "選ぶのを手伝って",
  "components.proficiencySelector.descriptionsTitle": "習熟度の説明",
  "components.proficiencySelector.descriptionsSubtitle":
    "様々な言語習熟度レベルの説明は下記の通りです。",
  "components.proficiencySelector.beginnerLabel": "初心者",
  "components.proficiencySelector.beginnerDescription":
    "相手が積極的で明確に意思疎通をしてくれる場合、実用的なニーズを満たすために基本的な表現を使用できます。",
  "components.proficiencySelector.elementaryLabel": "初級",
  "components.proficiencySelector.elementaryDescription":
    "重要なトピックを理解し、日常的なことについて効果的に意思疎通をして、その背景や必要性を説明できます。",
  "components.proficiencySelector.intermediateLabel": "中級",
  "components.proficiencySelector.intermediateDescription":
    "一般的なトピックについて基本的な情報を理解して意思疎通ができ、旅行の場面ではやりくりし、個人的な経験・意見・計画を表現できます。",
  "components.proficiencySelector.upperIntermediateLabel": "中上級",
  "components.proficiencySelector.upperIntermediateDescription":
    "複雑な実用的・理論的なテキストを理解し、母語話者と流暢に意思疎通ができ、包括的なテキストを生成し、時事問題について自分の考えを述べられます。",
  "components.proficiencySelector.advancedLabel": "上級",
  "components.proficiencySelector.advancedDescription":
    "暗示的な意味を持つ複雑なテキストを理解し、流暢かつ堪能に意思疎通ができ、複雑なトピックに関して理路整然かつ、うまく構成されたテキストを生成できます。",
  "components.proficiencySelector.close": "閉じる",
  "components.readSpeedSlider.speed": "速度",
  "components.readSpeedSlider.verySlow": "非常に遅い",
  "components.readSpeedSlider.slow": "遅い",
  "components.readSpeedSlider.normal": "普通",
  "components.readSpeedSlider.fast": "速い",
  "components.readSpeedSlider.veryFast": "非常に速い",
  "components.speechRecognitionSelector.voiceInputEngine": "音声入力エンジン",
  "components.speechRecognitionSelector.default": "標準",
  "components.speechRecognitionSelector.alternative": "精度",
  "components.saveWord.save": "保存",
  "components.saveWord.notUnderstood":
    "AIがこの単語を理解しませんでした。もう一度お試しください。",
  "components.saveWord.failed": "保存に失敗しました。もう一度お試しください。",
  "components.saveWord.savedToWordbook": "単語帳に保存されました",
  "components.wordInformation.content.description": "説明",
  "components.wordInformation.content.sentences": "文",
  "components.textWithActions.readAloud": "音読する",
  "components.textWithActions.saveWord": "単語を保存",
  "components.textWithActions.learnMore": "もっと学ぶ",
  "components.pwaPromptIos.title": "{app}をデバイスにインストール",
  "components.pwaPromptIos.step1": "共有アイコンをタップ",
  "components.pwaPromptIos.step2": "[ホーム画面に追加] を選択",
  "components.pwaPromptIos.step3": "App Storeは不要です！",
  "components.pwaPromptAndroid.title": "アプリをインストール",
  "chat.userMessage.perfectSentence1":
    "素晴らしい文です！その調子で頑張ってください！",
  "chat.userMessage.perfectSentence2": "よくできました！",
  "chat.userMessage.perfectSentence3": "お見事です！",
  "settings.liveFeedback": "即時フィードバック",
  "settings.liveFeedbackDescription":
    "各メッセージの後に回答の改善方法についてのフィードバックを表示します。",
  "dashboard.template.dailySession.title": "デイリーセッション",
  "dashboard.template.dailySession.with": "チューター名：",
  "dashboard.template.dailySession.description":
    "今日の会話に参加して言語を練習し、語学力をアップさせましょう",
  "dashboard.template.dailySession.button": "デイリーセッションを開始",
  "feedbackCard.loader.analyzeGrammar": "文法を分析中...",
  "feedbackCard.loader.analyzeVocabulary": "語彙を分析中...",
  "feedbackCard.loader.analyzeStructure": "文の構造を分析中...",
  "feedbackCard.error.message":
    "申し訳ありませんが、フィードバックの処理中に問題が発生しました。リフレッシュしてもう一度お試しください。",
  "feedbackCard.improvedAnswerLabel": "改善された回答",
  "feedbackCard.grammarLabel": "文法",
  "feedbackCard.vocabularyLabel": "語彙",
  "feedbackCard.structureLabel": "構造",
};

export default l;
