import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
} from "@chakra-ui/react";
import { createContext, ReactNode, useContext } from "react";
import { BiErrorCircle } from "react-icons/bi";

export interface ErrorModalState {
  message: string;
  show: boolean;
  onClose?: () => void;
  onCloseMessage?: string;
  heading?: string;
}

interface Props {
  state?: ErrorModalState;
}

const defaultState: ErrorModalState = {
  show: false,
  message: "",
  onClose: undefined,
  onCloseMessage: "",
  heading: "",
};

export type ErrorModalContextType = {
  errorModalState?: ErrorModalState;
  setErrorModalState: (errorModalState: ErrorModalState) => void;
};

export const ErrorModalContext = createContext<ErrorModalContextType>({
  errorModalState: defaultState,
  setErrorModalState: () => undefined,
});

export default function ErrorModal(props: Props = { state: defaultState }) {
  const state = props.state ?? defaultState;

  const onClose = () => {
    if (state.onClose) {
      state.onClose();
    } else {
      window.location.reload();
    }
  };

  return (
    <Modal onClose={onClose} size={"lg"} isOpen={state.show}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent>
        <ModalHeader color={"red.500"}>
          <Flex>
            <BiErrorCircle fontSize={"30px"} style={{ marginRight: "10px" }} />{" "}
            {state.heading ?? "Error"}
          </Flex>
        </ModalHeader>
        <ModalBody>{state.message}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>
            {state.onCloseMessage ?? "Refresh and try again"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
