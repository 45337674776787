const l = {
  language: "עברית",
  "app.error": "שגיאה",
  "app.close": "סגירה",
  "app.start": "התחלה",
  "app.noConversations": "אין עדיין שיחות",
  "app.conversation": "שיחה",
  "app.conversations": "שיחות",
  "app.loading": "טוען...",
  "app.tryAgain": "יש לנסות שנית",
  "app.signOut": "התנתקות",
  "app.next": "הבא",
  "app.previous": "הקודם",
  "app.finish": "סיום",
  "app.cancel": "ביטול",
  "app.restart": "הפעלה מחדש",
  "layout.unableToConnect": "לא ניתן להתחבר לשרת",
  "layout.home": "דף הבית",
  "layout.chat": "מפגש יומי",
  "layout.wordbook": "ספר מילים",
  "layout.pronunciation": "הגייה",
  "layout.programs": "תוכניות",
  "layout.settings": "הגדרות",
  "layout.account": "חשבון",
  "layout.manageAccount": "ניהול חשבון",
  "layout.leaveReview": "כתיבת חוות דעת",
  "dashboard.title": "דף הבית",
  "dashboard.subtitle": "יש לבחור כיצד תרצה/י לתקשר עם המורה שלך.",
  "dashboard.aiTutors.title": "מורי AI (בינה מלאכותית)",
  "dashboard.aiTutors.description":
    "המורה הנבחר שלך יחכה לך במפגש הצ'אט הבא. נתראה בקרוב!",
  "dashboard.template.freeTalk.title": "מפגש חדש",
  "dashboard.template.freeTalk.description":
    "דבר/י עם המורה שלך על כל דבר שתרצה/י",
  "dashboard.template.rolePlay.title": "משחק תפקידים",
  "dashboard.template.rolePlay.description":
    "שים/י את עצמך בתפקידים שונים ושחק/י את התרחישים",
  "dashboard.template.topics.title": "נושא",
  "dashboard.template.topics.description": "בוא/י נדבר על נושא לפי בחירתך",
  "dashboard.template.caseStudy.title": "דיון במקרה",
  "dashboard.template.caseStudy.description": "דיון במקרה ומענה על שאלות לגביו",
  "dashboard.template.groupDiscussion.title": "דיון קבוצתי",
  "dashboard.template.groupDiscussion.description":
    "תרגול דיונים קבוצתיים ללא מנהיג",
  "dashboard.template.quiz.title": "חידון",
  "dashboard.template.quiz.description":
    "תרגול מילים ומשפטים באמצעות חידונים מרתקים",
  "dashboard.template.game.title": "משחקים",
  "dashboard.template.game.description":
    "תרגול אוצר מילים באמצעות משחקי מילים מהנים",
  "dashboard.template.modal.curated": "מבחר",
  "dashboard.template.modal.custom": "מותאם אישית",
  "dashboard.template.modal.inputContent": "יש להזין את התוכן שלך",
  "dashboard.conversations.description": "רשימת השיחות המלאה שלך",
  "chat.index.failedConnection": "לא ניתן ליצור קשר עם השרת ולשלוח את ההודעה",
  "chat.index.audioFailedToSend": "שגיאה בשליחת הודעה קולית",
  "chat.index.usageCapMessageTrialTitle": "וואו, את/ה על הגל!",
  "chat.index.usageCapMessageTrialText":
    "נראה שניצלת את תקופת הניסיון החינמית של 7 הימים במלואה - זה מדהים! הגעת למגבלת התווים לתקופה זו. רוצה להמשיך מיד? ניתן לשדרג את התוכנית שלך בכל עת ולהמשיך בדיוק מהמקום בו הפסקת.",
  "chat.index.usageCapMessageTrialFooterText":
    "אנחנו כאן כדי לתמוך במסע שלך, בכל שלב.",
  "chat.index.usageCapMessagePlanTitle": "לתשומת ליבך!",
  "chat.index.usageCapMessagePlanText":
    "השתמשת בתכונות רבות החודש, ואנחנו נרגשים! הגעת למגבלת השימוש לתוכנית הנוכחית. כדי לשמור על חוויה חלקה, אנו ממליצים לך לעיין באפשרויות של התוכנית שלנו.",
  "chat.index.usageCapMessagePlanFooterText":
    "אין לחץ - התוכנית הנוכחית שלך תתאפס בחודש הבא. אנחנו שמחים שאת/ה איתנו!",
  "chat.index.visitAccount": "ביקור בחשבון",
  "chat.index.noMicTitle": "השימוש במיקרופון אסור",
  "chat.index.noMicText": "הגישה למיקרופון נחסמה על ידי הדפדפן שלך",
  "chat.asideMenu.voice": "קול",
  "chat.asideMenu.text": "טקסט",
  "chat.asideMenu.newChat": "צ'אט חדש",
  "chat.asideMenu.pastConversations": "שיחות קודמות",
  "chat.asideMenu.noConversations": "אין שיחות",
  "chat.asideMenu.autoTranslate": "תרגום אוטומטי",
  "chat.asideMenu.showRomanization": "הצגת תעתיק לשוני",
  "chat.asideMenu.multiLingualSpeech": "דיבור רב-לשוני",
  "chat.suggestions.titleSm": "הצעה מה לומר",
  "chat.suggestions.titleXs": "הצעה",
  "chat.suggestions.regenerate": "הפקה מחדש",
  "chat.suggestions.generating": "מייצר...",
  "chat.suggestions.failed": "נכשל בהשגת הצעה. יש לנסות להפיק מחדש.",
  "chat.conversation.tryAgain": "קדימה, נסה/י שנית",
  "chat.conversation.cancel": "ביטול",
  "chat.speakInput.speak": "דיבור",
  "chat.speakInput.recording": "הקלטה",
  "chat.textInput.sendMessage": "שליחת הודעה",
  "chat.autoSubmitSwitch.handsFree": "ללא ידיים",
  "chat.shortcuts.hold": "יש להחזיק",
  "chat.shortcuts.press": "יש ללחוץ",
  "chat.shortcuts.release": "יש לשחרר",
  "chat.shortcuts.toRecord": "כדי להקליט",
  "chat.shortcuts.toSend": "כדי לשלוח",
  "chat.shortcuts.toCancel": "כדי לבטל",
  "chat.userMessageActions.speechPrecision": "דיוק הדיבור",
  "chat.userMessageActions.speechPrecisionDescription":
    "דיוק הדיבור מציין את מידת הביטחון בה המורה פירש את ההודעה שלך. אחוז גבוה יותר פירושו דיוק גבוה יותר בהבנת הנאמר.",
  "chat.userMessageActions.speechPrecisionShowInfo": "הצגת מידע",
  "chat.userMessageActions.speechPrecisionHideInfo": "הסתרת מידע",
  "chat.userMessageActions.improveMyAnswer": "שיפור התשובה שלי",
  "chat.userMessageActions.retry": "נסה/י שנית",
  "chat.feedbackMessageButton.improveMyAnswer": "שיפור התשובה האחרונה שלי",
  "chat.feedbackMessageButton.feedbackOn": "משוב על",
  "chat.feedbackMessageButton.speechPrecision": "דיוק הדיבור",
  "chat.assessmentButton.title": "הערכת שיחה",
  "chat.assessmentButton.close": "סגירה",
  "chat.assessmentButton.analyzing": "ניתוח כל השיחה",
  "chat.assessmentButton.tooShort": "השיחה קצרה מדי כדי לבצע הערכה",
  "settings.title": "הגדרות",
  "settings.subtitle": "התאמה אישית של החוויה שלך עם המורה.",
  "settings.language": "שפה",
  "settings.yourLanguage": "השפה שלך",
  "settings.uiLanguage": "שפת הממשק",
  "settings.practiceLanguage": "שפת התרגול",
  "settings.proficiency": "בקיאות",
  "settings.tutor": "שם",
  "settings.name": "מורה",
  "settings.talkingSpeed": "מהירות דיבור",
  "settings.speechInput": "קלט דיבור",
  "settings.precisionVoiceInputDescription":
    "מספק דיוק יוצא דופן עבור השפות הלטיניות והגרמאניות העיקריות, לרבות אנגלית, גרמנית, ספרדית, צרפתית ואיטלקית, כמו גם שפות אסיאתיות כגון קוריאנית, תאילנדית ויפנית. אם ברצונך להשתמש בשיטת שיחה צולבת ולאפשר זיהוי דיבור רב-לשוני, יש לבחור במנוע הסטנדרטי.",
  "settings.standardVoiceInputDescription":
    "מנוע רב-תכליתי התומך בכל השפות שלנו. הוא אידיאלי לשיחות צולבות וזיהוי דיבור רב-לשוני.",
  "settings.autoPunctuation": "פיסוק אוטומטי",
  "settings.autoPunctuationDescription":
    "הוספה אוטומטית של סימני פיסוק כאשר ישנה השתהות",
  "settings.multiLingualSpeechRecognition": "זיהוי דיבור רב-לשוני",
  "settings.multiLingualSpeechRecognitionDescription":
    "המורה יזהה דיבור גם בשפת התרגול וגם בשפת האם שלך",
  "settings.translations": "תרגומים",
  "settings.autoTranslate": "תרגום אוטומטי",
  "settings.autoTranslateDescription":
    "תרגום אוטומטי של ההודעות מהמורה לשפה שלך",
  "settings.showRomanization": "הצגת תעתיק לשוני",
  "settings.showRomanizationDescription":
    "הצגת תעתיק לשוני של הודעת הצ'אט האחרונה עבור שפות תסריט",
  "wordbook.title": "ספר מילים",
  "wordbook.subtitle":
    "מעקב אחר המילים שלמדת; המורה שלך יעזור לך להשתמש בהן כדי לשפר את הזיכרון שלך",
  "wordbook.noWord": "אין מילים שמורות עבור השפה שנבחרה",
  "wordbook.yourPracticeLanguage": "שפת התרגול שלך",
  "wordbook.word": "מילה",
  "wordbook.addToWordbook": "הוספה לספר המילים",
  "wordbook.pleaseEnterIn": "יש להזין מילה ב-",
  "wordbook.selectWord": "יש לבחור מילה",
  "pronunciation.title": "הגייה",
  "pronunciation.subtitle": "תרגול הגייה ושטף דיבור",
  "pronunciation.intro":
    "המורה ייצור משפטים מתאימים, מותאמים לרמת הבקיאות שלך, שמוגדרת כ-",
  "pronunciation.startPracticing": "התחלת תרגול",
  "pronunciation.done": "בוצע",
  "pronunciation.cancel": "ביטול",
  "pronunciation.tryAgain": "יש לנסות שנית",
  "pronunciation.wordByWord.loading": "טוען...",
  "pronunciation.wordByWord.stopReading": "הפסקת קריאה",
  "pronunciation.wordByWord.readSentence": "קריאת משפט",
  "pronunciation.wordByWord.newSentence": "משפט חדש",
  "pronunciation.wordByWord.retrySentence": "חזרה על המשפט",
  "pronunciation.saveProgress.progressSaved": "התקדמות ההגייה שלך נשמרה",
  "pronunciation.saveProgress.progressNotSaved":
    "לא ניתן לשמור את התקדמות ההגייה שלך. יש לבדוק את החיבור שלך ולנסות שנית.",
  "pronunciation.saveProgress.saveProgress":
    "שמירת משפט וציון כדי לעקוב אחר ההתקדמות ולחזור עליו שנית מאוחר יותר.",
  "pronunciation.saveProgress.saved": "נשמר",
  "pronunciation.saveProgress.saveSentenceAndAssessment": "שמירת משפט והערכה",
  "pronunciation.assessmentStats.noResults": "אין תוצאה להצגה",
  "pronunciation.assessmentStats.pronunciation": "הגייה",
  "pronunciation.assessmentStats.pronunciationScore": "ציון הגייה",
  "pronunciation.assessmentStats.pronunciationDescription":
    "ציון ההגייה מייצג את איכות ההגייה בדיבור נתון. הוא מחושב על ידי שילוב של ציון דיוק, ציון שטף הדיבור וציון שלמות, כאשר כל אחד מהם משוקלל על פי משקל ספציפי.",
  "pronunciation.assessmentStats.accuracy": "דיוק",
  "pronunciation.assessmentStats.accuracyScore": "דיוק הגייה",
  "pronunciation.assessmentStats.accuracyDescription":
    "דיוק הגייה מודד עד כמה המילים המדוברות תואמות לאלו של דובר שפת אם. ציוני הדיוק עבור הברות, מילים וטקסט מלא מבוססים על דיוק ברמת הפונמה ומותאמים בהתאם למטרות ההערכה.",
  "pronunciation.assessmentStats.completeness": "שלמות",
  "pronunciation.assessmentStats.completenessScore": "שלמות",
  "pronunciation.assessmentStats.completenessDescription":
    "שלמות הדיבור נקבעת על ידי השוואת מספר המילים שנאמרו למספר הכולל של המילים בטקסט האמור.",
  "pronunciation.assessmentStats.fluency": "שטף דיבור",
  "pronunciation.assessmentStats.fluencyScore": "שטף דיבור",
  "pronunciation.assessmentStats.fluencyDescription":
    "שטף הדיבור הנתון. שטף הדיבור מציין עד כמה הדיבור תואם לשימוש של דובר שפת אם בהפסקות בין מילים.",
  "pronunciation.assessmentStats.keepPracticing": "המשך/י לתרגל!",
  "pronunciation.assessmentStats.makingProgress": "התקדמות יפה!",
  "pronunciation.assessmentStats.wellDone": "כל הכבוד!",
  "pronunciation.assessmentStats.almostThere": "כמעט הגעת לשם!",
  "pronunciation.assessmentStats.excellentJob": "עבודה מצוינת!",
  "pronunciation.history.saveAssessments": "הערכות שמורות",
  "pronunciation.history.noAssessments": "אין עדיין הערכות",
  "pronunciation.history.score": "ציון",
  "pronunciation.history.tryAgain": "יש לנסות שנית",
  "intro.title": "ברוך/י הבא/ה ל-{serviceName}",
  "intro.subtitle": "נתחיל עם מספר שאלות",
  "intro.userLanguageTitle": "שפה",
  "intro.userLanguageSubtitle": "מהי שפת האם שלך?",
  "intro.practiceLanguageTitle": "שפה",
  "intro.practiceLanguageSubtitle": "איזו שפה ברצונך לתרגל?",
  "intro.proficiencyTitle": "בקיאות",
  "intro.proficiencyDescription": "מהי רמת הבקיאות שלך ב-{practiceLanguage}?",
  "intro.completionModal.title": "הכל מוכן!",
  "intro.completionModal.buttonText": "הגיע הזמן להתחיל לדבר",
  "hooks.useConversation.noSpeech": "לא זוהה דיבור או שעוצמת הקול נמוכה מדי",
  "hooks.useAssessment.unableToFetchPinyin":
    "לא ניתן לאחזר את הפיניין (תעתיק לטיני למנדרינית)",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "לא ניתן להתחבר לשרת הערכת ההגייה",
  "hooks.useAssessment.noSpeech": "לא זוהה דיבור",
  "hooks.useAssessment.retryError": "שגיאה",
  "hooks.useAssessment.retryErrorDescription": "לא ניתן לטעון את ההערכה השמורה",
  "hooks.useWordbook.deleteConfirm": "האם את/ה בטוח/ה שברצונך למחוק מילה זו?",
  "hooks.useWordbook.unableToDeleteWord":
    "לא ניתן למחוק את המילה. יש לנסות שנית בעוד מספר שניות.",
  "components.conversationDownloadButton.export": "ייצוא צ'אט",
  "components.micPermissionButton.notAllowed": "גישה למיקרופון אינה מותרת",
  "components.micPermissionButton.turnOnMicAndSound": "הפעלת מיקרופון וצליל",
  "components.micPermissionButton.pleaseAllow": "יש לאפשר גישה למיקרופון",
  "components.languageSelector.managedByOrganization": "מנוהל על ידי הארגון",
  "components.languageSelector.selectLanguage": "בחירת שפה",
  "components.languageSelector.fullList": "הצגת רשימה מלאה",
  "components.languageSelector.simpleList": "הצגת רשימה מפושטת",
  "components.proficiencySelector.helpMeChoose": "עזור לי לבחור",
  "components.proficiencySelector.descriptionsTitle": "תיאור רמות הבקיאות",
  "components.proficiencySelector.descriptionsSubtitle":
    "הנה תיאורים של רמות הבקיאות השונות בשפה.",
  "components.proficiencySelector.beginnerLabel": "מתחיל",
  "components.proficiencySelector.beginnerDescription":
    "האדם יכול להשתמש בביטויים בסיסיים כדי לענות על צרכים מעשיים באמצעות תקשורת ברורה ונכונות מאחרים.",
  "components.proficiencySelector.elementaryLabel": "בסיסי",
  "components.proficiencySelector.elementaryDescription":
    "האדם מבין נושאים חשובים, מתקשר ביעילות בעניינים שגרתיים ויכול להסביר את הרקע והצרכים שלו.",
  "components.proficiencySelector.intermediateLabel": "בינוני",
  "components.proficiencySelector.intermediateDescription":
    "האדם מבין ומתקשר מידע בסיסי על נושאים נפוצים, מנהל סיטואציות הקשורות לנסיעות ומביע חוויות אישיות, דעות ותוכניות.",
  "components.proficiencySelector.upperIntermediateLabel": "בינוני-גבוה",
  "components.proficiencySelector.upperIntermediateDescription":
    "האדם מבין טקסטים מעשיים ותיאורטיים מורכבים, מתקשר באופן שוטף עם דוברי שפת אם, מחבר טקסט מקיף ומביע את נקודת המבט שלו על נושאים עכשוויים.",
  "components.proficiencySelector.advancedLabel": "מתקדם",
  "components.proficiencySelector.advancedDescription":
    "האדם מבין טקסטים מורכבים עם משמעות מרומזת, מתקשר באופן שוטף ובבקיאות ומחבר טקסט בנוי היטב ועקבי על נושאים מסובכים.",
  "components.proficiencySelector.close": "סגירה",
  "components.readSpeedSlider.speed": "מהירות",
  "components.readSpeedSlider.verySlow": "איטית מאוד ",
  "components.readSpeedSlider.slow": "איטית",
  "components.readSpeedSlider.normal": "רגילה",
  "components.readSpeedSlider.fast": "מהירה",
  "components.readSpeedSlider.veryFast": "מהירה מאוד ",
  "components.speechRecognitionSelector.voiceInputEngine": "מנוע קלט קולי",
  "components.speechRecognitionSelector.default": "סטנדרטי",
  "components.speechRecognitionSelector.alternative": "דיוק",
  "components.saveWord.save": "שמירה",
  "components.saveWord.notUnderstood":
    "הבינה המלאכותית (AI) לא הבינה את המילה הזו. יש לנסות שנית.",
  "components.saveWord.failed": "השמירה נכשלה. יש לנסות שנית.",
  "components.saveWord.savedToWordbook": "נשמר בספר המילים שלך",
  "components.wordInformation.content.description": "תיאור",
  "components.wordInformation.content.sentences": "משפטים",
  "components.textWithActions.readAloud": "קריאה בקול רם",
  "components.textWithActions.saveWord": "שמירת מילה",
  "components.textWithActions.learnMore": "מידע נוסף",
  "components.pwaPromptIos.title": "יש להתקין את {app} במכשיר שלך",
  "components.pwaPromptIos.step1": "יש להקיש על סמל השיתוף",
  "components.pwaPromptIos.step2": "יש לבחור 'הוספה למסך הבית'",
  "components.pwaPromptIos.step3": "אין צורך בחנות האפליקציות!",
  "components.pwaPromptAndroid.title": "התקנת האפליקציה",
  "chat.userMessage.perfectSentence1": "משפט מצוין! המשך/י כך!",
  "chat.userMessage.perfectSentence2": "עבודה טובה!",
  "chat.userMessage.perfectSentence3": "כל הכבוד!",
  "settings.liveFeedback": "משוב מיידי",
  "settings.liveFeedbackDescription":
    "מציג משוב על איך לשפר את התשובה שלך לאחר כל הודעה.",
  "dashboard.template.dailySession.title": "מפגש יומי",
  "dashboard.template.dailySession.with": "עם",
  "dashboard.template.dailySession.description":
    "הצטרף/י לשיחה היום כדי לתרגל ולשפר את כישורי השפה שלך",
  "dashboard.template.dailySession.button": "התחלת המפגש היומי",
  "feedbackCard.loader.analyzeGrammar": "ניתוח דקדוק...",
  "feedbackCard.loader.analyzeVocabulary": "ניתוח אוצר מילים...",
  "feedbackCard.loader.analyzeStructure": "ניתוח מבנה המשפט...",
  "feedbackCard.error.message":
    "אנו מצטערים, אך נתקלנו בבעיה במהלך עיבוד המשוב שלך. יש לרענן את העמוד ולנסות שנית.",
  "feedbackCard.improvedAnswerLabel": "תשובה משופרת",
  "feedbackCard.grammarLabel": "דקדוק",
  "feedbackCard.vocabularyLabel": "אוצר מילים",
  "feedbackCard.structureLabel": "מבנה",
};

export default l;
