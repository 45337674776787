import { props } from "cypress/types/bluebird";
import { useMemo, useEffect } from "react";
import { siteConfig } from "../configuration/config";
import settings from "../pages/api/settings";
import { useSettings } from "./use-settings";
import { useUser } from "./use-user";

export function useLanguageLock() {
  const {
    settings,
    saveSettingsPartial,
    settingsAreLoading,
    saveSettingsLoading,
  } = useSettings();
  const { systemUser } = useUser(false);

  const lockedPracticeLanguageCode =
    systemUser?.primaryOrganization?.practiceLanguageLock ||
    siteConfig.practiceLanguageLock;

  const lockedUserLanguageCode = siteConfig.userLanguageLock;

  useEffect(() => {
    if (
      !saveSettingsLoading &&
      !settingsAreLoading &&
      lockedPracticeLanguageCode &&
      lockedPracticeLanguageCode !== settings.practiceLanguage
    ) {
      saveSettingsPartial({ practiceLanguage: lockedPracticeLanguageCode });
    }
  }, [
    lockedPracticeLanguageCode,
    saveSettingsLoading,
    settingsAreLoading,
    settings.practiceLanguage,
    saveSettingsPartial,
  ]);

  useEffect(() => {
    if (
      !saveSettingsLoading &&
      !settingsAreLoading &&
      lockedUserLanguageCode &&
      lockedUserLanguageCode !== settings.userLanguage
    ) {
      saveSettingsPartial({ userLanguage: lockedUserLanguageCode });
    }
  }, [
    lockedUserLanguageCode,
    settingsAreLoading,
    saveSettingsLoading,
    settings.userLanguage,
    saveSettingsPartial,
  ]);

  return {
    lockedPracticeLanguageCode,
    lockedUserLanguageCode,
  };
}
