import {
  Localization,
  LocalizationContext,
  useLocalization,
} from "../hooks/use-localization";
import {
  TextSelectionContext,
  useTextSelection,
} from "../hooks/use-text-selection";
import {
  ChatStateContext,
  useChatState,
} from "../views/chat/hooks/use-chat-state";
import {
  TtsAudioContext,
  useTtsAudio,
} from "../views/chat/hooks/use-tts-audio";
import {
  useVoiceControl,
  VoiceControlContext,
} from "../views/chat/hooks/use-voice-control";

interface Props {
  children: React.ReactNode;
  localization: Localization;
}

export default function AppProvider(props: Props) {
  const ttsAudio = useTtsAudio();
  const voiceControl = useVoiceControl(ttsAudio);
  const textSelection = useTextSelection();
  const chatStateData = useChatState();

  return (
    <>
      <LocalizationContext.Provider value={props.localization}>
        <TtsAudioContext.Provider value={ttsAudio}>
          <VoiceControlContext.Provider value={voiceControl}>
            <TextSelectionContext.Provider value={textSelection}>
              <ChatStateContext.Provider value={chatStateData}>
                {props.children}
              </ChatStateContext.Provider>
            </TextSelectionContext.Provider>
          </VoiceControlContext.Provider>
        </TtsAudioContext.Provider>
      </LocalizationContext.Provider>
    </>
  );
}
