const l = {
  "settings.title": "Ustawienia",
  "settings.subtitle": "Dostosuj swoje doświadczenie z tutorialem.",
  "settings.language": "Język",
  "settings.yourLanguage": "Twój język",
  "settings.uiLanguage": "Język interfejsu",
  "settings.practiceLanguage": "Język treningu",
  "settings.proficiency": "Biegłość",
  "settings.tutor": "Tutor",
  "settings.name": "Imię",
  "settings.talkingSpeed": "Prędkość mówienia",
  "settings.speechInput": "Wejście głosowe",
  "settings.autoPunctuation": "Automatyczna interpunkcja",
  "settings.autoPunctuationDescription":
    "Automatyczne dodawanie interpunkcji Podczas pauz",
  "settings.multiLingualSpeechRecognition": "Wielojęzyczne rozpoznawanie mowy",
  "settings.multiLingualSpeechRecognitionDescription":
    "Tutor będzie rozpoznawał mowę zarówno w języku którego się uczysz, jak i w twoim ojczystym języku",
  "settings.translations": "Tłumaczenia",
  "settings.autoTranslate": "Automatyczne tłumaczenie",
  "settings.autoTranslateDescription":
    "Automatycznie tłumacz wiadomości od swojego nauczyciela na Twój język",
  "settings.showRomanization": "Pokaż romanizację",
  "settings.showRomanizationDescription":
    "Pokaż romanizację ostatniej wiadomości czatu dla języków skryptowych",
  "wordbook.title": "Leksykon",
  "wordbook.subtitle": "Śledź słowa, których się uczysz",
  "wordbook.noWord": " Brak zapisanych słów dla wybranego języka",
  "wordbook.yourPracticeLanguage": "Twój język, którego się uczysz",
  "wordbook.word": "Słowo",
  "wordbook.addToWordbook": "Dodaj do leksykonu",
  "wordbook.pleaseEnterIn": "Proszę wprowadzić słowo",
  "wordbook.selectWord": "Wybierz słowo",
  "pronunciation.title": "Wymowa",
  "pronunciation.subtitle": "Ćwicz wymowę i płynność",
  "pronunciation.intro":
    "Tutor wygeneruje odpowiednie zdania, dostosowane do Twojego poziomu biegłości, który jest ustawiony na",
  "pronunciation.startPracticing": "Rozpocznij ćwiczenie",
  "pronunciation.done": "Gotowe",
  "pronunciation.cancel": "Anuluj",
  "pronunciation.tryAgain": "Spróbuj ponownie",
  "pronunciation.wordByWord.loading": "Ładowanie...",
  "pronunciation.wordByWord.stopReading": "Przestań czytać",
  "pronunciation.wordByWord.readSentence": "Przeczytaj zdanie",
  "pronunciation.wordByWord.newSentence": "Nowe zdanie",
  "pronunciation.wordByWord.retrySentence": "Powtórz zdanie",
  "pronunciation.saveProgress.progressSaved":
    "Postęp twojej wymowy został zapisany",
  "pronunciation.saveProgress.progressNotSaved":
    "Nie można zapisać postępu twojej wymowy. Proszę sprawdzić połączenie internetowe i spróbój ponownie.",
  "pronunciation.saveProgress.saveProgress":
    "Zapisz zdanie i ocenę, aby śledzić postęp i spróbój ponownie później.",
  "pronunciation.saveProgress.saved": "Zapisane",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Zapisz zdanie i ocenę",
  "pronunciation.assessmentStats.noResults": "Brak wyników do wyświetlenia",
  "pronunciation.assessmentStats.pronunciation": "Wymowa",
  "pronunciation.assessmentStats.pronunciationScore": "Ocena wymowy",
  "pronunciation.assessmentStats.pronunciationDescription":
    "Ocena wymowy reprezentuje jakość wymowy w danej mowie. Jest obliczany poprzez połączenie wyników dokładności, płynności i kompletności, każdy przydzielony z określoną wagą.",
  "pronunciation.assessmentStats.accuracy": "Dokładność",
  "pronunciation.assessmentStats.accuracyScore": "Dokładność wymowy",
  "pronunciation.assessmentStats.accuracyDescription":
    "Dokładność wymowy mierzy jak dobrze wypowiedziane słowa pasują do tych wypowiedzianych przez native speakera. Wyniki dokładności dla sylab, słów i tekstów opierają się na dokładności na poziomie fonemów i są dostosowane do celów oceny.",
  "pronunciation.assessmentStats.completeness": "Ocena",
  "pronunciation.assessmentStats.completenessScore": "Wynik oceny",
  "pronunciation.assessmentStats.completenessDescription":
    "Ocena mowy jest określana poprzez porównanie liczby wypowiedzianych słów do całkowitej liczby słów w tekście referencyjnym.",
  "pronunciation.assessmentStats.fluency": "Płynność",
  "pronunciation.assessmentStats.fluencyScore": "Ocena płynności",
  "pronunciation.assessmentStats.fluencyDescription":
    "Płynność danej wypowiedzi. Płynność wskazuje jak blisko twoja wypowiedź jest podobna do sposobu mówienia przez native speakera. Analizie poddawane są wypowiadane przerwy między słowami.",
  "pronunciation.assessmentStats.keepPracticing": "Kontynuuj ćwiczenia!",
  "pronunciation.assessmentStats.makingProgress": "Rób postępy!",
  "pronunciation.assessmentStats.wellDone": "Dobrze zrobione!",
  "pronunciation.assessmentStats.excellentJob": "Doskonała praca!",
  "pronunciation.assessmentStats.almostThere": "Jeszcze poćwicz!",
  "pronunciation.history.saveAssessments": "Zapisane oceny",
  "pronunciation.history.noAssessments": "Brak ocen",
  "pronunciation.history.score": "Wynik",
  "pronunciation.history.tryAgain": "Spróbuj ponownie",
  "account.unableToLoad": "Nie można wczytać szczegółów użytkownika",
  "account.title": "Konto",
  "account.renewFailed": "Nie udało się odnowić subskrypcji",
  "account.reviewPaymentDetails": "Proszę sprawdzić szczegóły płatności",
  "account.subscriptionInactive": "Subskrypcja nieaktywna",
  "account.creditCardDeclined": "Transakcja kartą kredytową została odrzucona.",
  "account.updateCreditCard":
    "Aby kontynuować korzystanie z AI Talkio, proszę podać inny sposób płatności lub zaktualizuj dane płatności na",
  "account.subscriptionManagementPage": "stronie zarządzania subskrypcją",
  "account.questionContactUs":
    "Jeśli masz pytania lub uwagi, skontaktuj się z nami pod adresem",
  "account.billing": "Rozliczenie",
  "account.plan": "Plan",
  "account.amount": "Kwota",
  "account.usage": "Użycie",
  "account.usageDescription":
    "Limit użycia jest określany przez ogólną liczbę znaków używanych w rozmowach, obejmujących zarówno wprowadzony tekst wysłany do AI, jak i tekst wygenerowany przez AI w odpowiedzi. Limit ten jest resetowany co miesiąc. {limit} znaków to mniej więcej równowartość {estHours} godzin rozmowy.",
  "account.requestRefund": "Poproś o zwrot",
  "account.manageSubscription": "Zarządzaj subskrypcją",
  "account.subscriptionManagedBy": "Zarządzanie twoją subskrypcją",
  "account.contactAdmin":
    "Proszę skontaktować się z administratorem organizacji, aby zmienić lub anulować subskrypcję.",
  "account.subscriptionManagedByPartner": "Zarządzanie twoją subskrypcją",
  "account.contactPartner":
    "Proszę skontaktować się z {userPlanId}, aby zmienić lub anulować subskrypcję.",
  "account.goToSubscriptionManagement": "Przejdź do zarządzania subskrypcją",
  "account.feedback": "Opinie",
  "account.feedbackDescription":
    "Twoja opinia jest dla nas bezcenna! Jeśli masz sugestie lub obszary, które naszym zdaniem można usprawnić, nie wahaj się",
  "account.feedbackCallToAction": "podziel się swoimi przemyśleniami",
  "account.reviewDescription":
    "Ponadto, jeśli podoba Ci się Talkio, byłobyśmy wdzięczni za",
  "account.reviewCallToAction": "twoją opinię!",
  "account.signOutDescription":
    "Wyloguj się z Twojego konta, korzystając z poniższego przycisku.",
  "account.connection": "Połączenie",
  "intro.title": "Witaj w {serviceName}",
  "intro.subtitle": "Rozpocznijmy od kilku pytań",
  "intro.userLanguageTitle": "Język",
  "intro.userLanguageSubtitle": "Jaki jest Twój ojczysty język?",
  "intro.practiceLanguageTitle": "Język",
  "intro.practiceLanguageSubtitle": "Jaki język chcesz ćwiczyć?",
  "intro.proficiencyTitle": "Biegłość",
  "intro.proficiencyDescription":
    "Jaka jest Twoja biegłość w {practiceLanguage}",
  "intro.completionModal.title": "Jesteś gotowy!",
  "intro.completionModal.buttonText": "Czas zacząć rozmawiać",
  "hooks.useConversation.noSpeech":
    "Nie wykryto mowy lub zbyt niskie natężenie dźwięku",
  "hooks.useAssessment.unableToFetchPinyin": "Nie można pobrać pinyin",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "Nie można połączyć się z serwerem oceny wymowy",
  "hooks.useAssessment.noSpeech": "Nie wykryto mowy",
  "hooks.useAssessment.retryError": "Błąd",
  "hooks.useAssessment.retryErrorDescription":
    "Nie można załadować zapisanej oceny",
  "hooks.useWordbook.deleteConfirm": "Czy na pewno chcesz usunąć to słowo?",
  "hooks.useWordbook.unableToDeleteWord":
    "Nie można usunąć słowa. Spróbuj ponownie za kilka sekund.",
  "components.miniTranslator.translate": "Tłumacz",
  "components.miniTranslator.to": "do",
  "components.conversationDownloadButton.export": "Eksportuj czat",
  "components.micPermissionButton.notAllowed": "Brak dostępu do mikrofonu",
  "components.micPermissionButton.turnOnMicAndSound": "Włącz mikrofon i dźwięk",
  "components.micPermissionButton.pleaseAllow":
    "Proszę zezwolić na dostęp do mikrofonu",
  "components.languageSelector.managedByOrganization":
    "Zarządzane przez organizację",
  "components.languageSelector.selectLanguage": "Wybierz język",
  "components.languageSelector.fullList": "Pokaż pełną listę",
  "components.languageSelector.simpleList": "Pokaż uproszczoną listę",
  "components.proficiencySelector.helpMeChoose": "Pomóż mi wybrać",
  "components.proficiencySelector.descriptionsTitle": "Opisy zdolności",
  "components.proficiencySelector.descriptionsSubtitle":
    "Oto opisy różnych poziomów biegłości językowej.",
  "components.proficiencySelector.beginnerLabel": "Początkujący",
  "components.proficiencySelector.beginnerDescription":
    "Osoba może używać podstawowych wyrażeń do zaspokojenia praktycznych potrzeb przy klarownej komunikacji i chęci innych.",
  "components.proficiencySelector.elementaryLabel": "Elementarny",
  "components.proficiencySelector.elementaryDescription":
    "Osoba rozumie ważne tematy, efektywnie komunikuje się w sprawach rutynowych i potrafi wyjaśnić swoje pochodzenie i potrzeby.",
  "components.proficiencySelector.intermediateLabel": "Średniozaawansowany",
  "components.proficiencySelector.intermediateDescription":
    "Osoba rozumie i komunikuje podstawowe informacje na powszechnie występujące tematy, radzi sobie w sytuacjach podróżnych i wyraża swoje osobiste doświadczenia, opinie i plany.",
  "components.proficiencySelector.upperIntermediateLabel":
    "Wyższy-średniozaawansowany",
  "components.proficiencySelector.upperIntermediateDescription":
    "Osoba rozumie złożony tekst praktyczny i teoretyczny, komunikuje się biegle z native speakerami, jest wstanie stworzyć kompleksowy tekst i wyraża swoją opinię na aktualnie poruszane tematy.",
  "components.proficiencySelector.advancedLabel": "Zaawansowany",
  "components.proficiencySelector.advancedDescription":
    "Osoba rozumie złożone teksty z ukrytym znaczeniem, komunikuje się płynnie i biegłe i generuje dobrze skonstruowany i spójny tekst na skomplikowane tematy.",
  "components.proficiencySelector.close": "Zamknij",
  "components.readSpeedSlider.speed": "Prędkość",
  "components.readSpeedSlider.verySlow": "Bardzo wolno",
  "components.readSpeedSlider.slow": "Wolno",
  "components.readSpeedSlider.normal": "Normalnie",
  "components.readSpeedSlider.fast": "Szybko",
  "components.readSpeedSlider.veryFast": "Bardzo szybko",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Silnik wejścia głosowego",
  "components.speechRecognitionSelector.default": "Domyślny",
  "components.speechRecognitionSelector.alternative": "Alternatywny",
  "components.saveWord.save": "Zapisz",
  "components.saveWord.notUnderstood":
    "AI nie zrozumiał tego słowa. Spróbuj ponownie.",
  "components.saveWord.failed":
    "Nie udało się zapisać. Proszę spróbuj ponownie.",
  "components.saveWord.savedToWordbook": "zapisane w twoim leksykonie",
  "components.wordInformation.content.description": "Opis",
  "components.wordInformation.content.sentences": "Zdania",
  "components.textWithActions.readAloud": "Przeczytaj na głos",
  "components.textWithActions.saveWord": "Zapisz słowo",
  "components.textWithActions.learnMore": "Dowiedz się więcej",
};

export default l;
