const l = {
  language: "Português",
  "app.error": "Erro",
  "app.close": "Fechar",
  "app.start": "Iniciar",
  "app.noConversations": "Sem conversas ainda",
  "app.conversation": "Conversa",
  "app.conversations": "Mensagens",
  "app.loading": "Carregando...",
  "app.tryAgain": "Por favor, tente novamente",
  "app.signOut": "Sair",
  "app.next": "Próximo",
  "app.previous": "Anterior",
  "app.finish": "Finalizar",
  "layout.unableToConnect": "Não foi possível se conectar ao servidor",
  "layout.home": "Principal",
  "layout.chat": "Aula Diária",
  "layout.wordbook": "Livro de Palavras",
  "layout.pronunciation": "Pronúncia",
  "layout.programs": "Programas",
  "layout.settings": "Configurações",
  "layout.account": "Conta",
  "layout.manageAccount": "Gerenciar Conta",
  "layout.leaveReview": "Deixar um Comentário",
  "dashboard.title": "Principal",
  "dashboard.subtitle": "Escolha como deseja interagir com seu instrutor.",
  "dashboard.learningStreaks.title": "Sequências de Aprendizado",
  "dashboard.learningStreaks.description":
    "Acompanhe seu progresso e mantenha um hábito de aprendizado consistente",
  "dashboard.learningStreaks.dayStreak": "Sequência de dias",
  "dashboard.learningStreaks.longestStreak": "Sequência mais longa",
  "dashboard.learningStreaks.conversations": "Mensagens",
  "dashboard.learningStreaks.achievement1": "Iniciando a jornada",
  "dashboard.learningStreaks.achievement3": "Três dias de consistência",
  "dashboard.learningStreaks.achievement4": "Quatro dias de consistência",
  "dashboard.learningStreaks.achievement5":
    "Uma sequência sólida de cinco dias",
  "dashboard.learningStreaks.achievement6": "Uma sequência sólida de seis dias",
  "dashboard.learningStreaks.achievement7": "Uma semana de aprendizado",
  "dashboard.learningStreaks.achievement8": "Mais de uma semana de aprendizado",
  "dashboard.learningStreaks.achievement10": "Dois dígitos!",
  "dashboard.learningStreaks.achievement14": "Duas semanas de conhecimento",
  "dashboard.learningStreaks.achievement20": "Chegando aos 20 dias",
  "dashboard.learningStreaks.achievement25": "Um quarto de século!",
  "dashboard.learningStreaks.achievement30": "Um mês de dedicação",
  "dashboard.learningStreaks.achievement40": "Quarenta dias de sabedoria",
  "dashboard.learningStreaks.achievement50": "Meio caminho para cem",
  "dashboard.learningStreaks.achievement60": "Dois meses de persistência",
  "dashboard.learningStreaks.achievement75": "Três quartos de um século",
  "dashboard.learningStreaks.achievement90": "Três meses de compromisso",
  "dashboard.learningStreaks.achievement100": "Século de aprendizado",
  "dashboard.learningStreaks.achievement120": "Quatro meses, toque mágico!",
  "dashboard.learningStreaks.achievement150":
    "150 dias, uma fortaleza de conhecimento",
  "dashboard.learningStreaks.achievement180": "Meio ano de iluminação",
  "dashboard.learningStreaks.achievement200": "200 dias, voando alto",
  "dashboard.learningStreaks.achievement250": "Um quarto de milênio!",
  "dashboard.learningStreaks.achievement300": "300 dias, um exemplo brilhante",
  "dashboard.learningStreaks.conversation1": "Começando!",
  "dashboard.learningStreaks.conversation3": "Já três bate-papos!",
  "dashboard.learningStreaks.conversation5": "Cinco mensagens intensas!",
  "dashboard.learningStreaks.conversation10": "Dez discussões, bem feito!",
  "dashboard.learningStreaks.conversation15":
    "15 conversas! Encontrando seu ritmo!",
  "dashboard.learningStreaks.conversation16": "Encontrando seu ritmo!",
  "dashboard.learningStreaks.conversation20":
    "20 mergulhos profundos no aprendizado!",
  "dashboard.learningStreaks.conversation21":
    "Mergulhos profundos no aprendizado!",
  "dashboard.learningStreaks.conversation30":
    "30 bate-papos! O mundo é mais brilhante!",
  "dashboard.learningStreaks.conversation31": "O mundo é mais brilhante!",
  "dashboard.learningStreaks.conversation40":
    "40 conversas! Crescendo o conhecimento!",
  "dashboard.learningStreaks.conversation41": "Crescendo o conhecimento!",
  "dashboard.learningStreaks.conversation50": "Meio século de mensagens!",
  "dashboard.learningStreaks.conversation75": "75 mensagens esclarecedoras!",
  "dashboard.learningStreaks.conversation100":
    "Cem conversas! Verdadeiramente comprometido!",
  "dashboard.learningStreaks.conversation150":
    "150 conversas! Um marco de dedicação!",
  "dashboard.learningStreaks.conversation200":
    "200 conversas! Comunicador de classe mundial!",
  "dashboard.learningStreaks.conversation250":
    "250 conversas! Mestre dos diálogos!",
  "dashboard.learningStreaks.conversation300":
    "300 conversas! O futuro é brilhante!",
  "dashboard.learningStreaks.conversation400":
    "400 conversas! Fora deste mundo!",
  "dashboard.learningStreaks.conversation500": "500 mensagens! Legendário!",
  "dashboard.learningStreaks.conversation750":
    "750 conversas! Galáxia de conhecimento!",
  "dashboard.learningStreaks.conversation1000":
    "1000 conversas! Reino de sabedoria!",
  "dashboard.learningStreaks.conversation1500":
    "1500 discussões! Realeza da conversa!",
  "dashboard.learningStreaks.conversation2000":
    "2000 conversas! Estrela cadente do diálogo!",
  "dashboard.aiTutors.title": "Instrutor de IA",
  "dashboard.aiTutors.description":
    "Seu instrutor selecionado estará esperando por você na próxima sessão de chat. Até logo!",
  "dashboard.template.rolePlay.title": "Encenação",
  "dashboard.template.rolePlay.description":
    "Assuma diferentes papéis e interprete os cenários",
  "dashboard.template.topics.title": "Escolha o Assunto",
  "dashboard.template.topics.description":
    "Vamos falar sobre um tópico de sua escolha",
  "dashboard.template.caseStudy.title": "Debate de Ideias",
  "dashboard.template.caseStudy.description":
    "Discuta um caso e responda perguntas sobre ele",
  "dashboard.template.groupDiscussion.title": "Discussão em Grupo",
  "dashboard.template.groupDiscussion.description":
    "Pratique discussões em grupo sem líder",
  "dashboard.template.hkuProficiencyTest.title": "Teste de Proficiência",
  "dashboard.template.hkuProficiencyTest.description":
    "Prepare-se para o teste de proficiência de admissão",
  "dashboard.template.hkustAdmissionInterview.title": "Entrevista de Admissão",
  "dashboard.template.hkustAdmissionInterview.description":
    "Prepare-se para a entrevista de admissão",
  "dashboard.template.hku.title": "Domine o Processo de Entrevista da HKU",
  "dashboard.template.hku.description":
    "Enfrente com confiança as admissões da Universidade de Hong Kong com preparação focada para o teste de proficiência, e desenvolva suas habilidades na disciplina de discussão em grupo requerida.",
  "dashboard.template.hkust.title":
    "Pratique para o Sucesso na Entrevista da HKUST",
  "dashboard.template.hkust.description":
    "Pratique com perguntas autênticas de entrevista da Universidade de Ciência e Tecnologia de Hong Kong. Desenvolva suas habilidades em autoapresentação, articulação de motivação e lidar com as discussões em grupo sem líder.",
  "dashboard.template.modal.curated": "Curado",
  "dashboard.template.modal.custom": "Customizado",
  "dashboard.template.modal.inputContent": "Insira seu próprio conteúdo",
  "dashboard.conversations.description": "Sua lista completa de mensagens",
  "chat.index.failedConnection":
    "Não foi possível contatar o servidor e enviar a mensagem",
  "chat.index.audioFailedToSend": "Erro ao enviar mensagem de áudio",
  "chat.index.usageCapMessageTrialTitle": "Uau, Você Está no Caminho Certo!",
  "chat.index.usageCapMessageTrialText":
    "Parece que você aproveitou ao máximo sua avaliação gratuita de 7 dias - isso é incrível! Você atingiu o limite de caracteres para este período. Ansioso para continuar agora? Você pode optar por atualizar seu plano a qualquer momento e continuar de onde parou.",
  "chat.index.usageCapMessageTrialFooterText":
    "Estamos aqui para apoiar sua jornada, a cada passo do caminho.",
  "chat.index.usageCapMessagePlanTitle": "Atenção!",
  "chat.index.usageCapMessagePlanText":
    "Você tem desfrutado todos os recursos bastante neste mês, e estamos empolgados! Você atingiu o limite de uso para o plano atual. Para manter a experiência sem interrupções, considere reservar um momento para revisar nossas opções de planos.",
  "chat.index.usageCapMessagePlanFooterText":
    "Sem pressa, porém - seu plano atual será redefinido no próximo mês. Felizes por tê-lo conosco!",
  "chat.index.visitAccount": "Visitar Conta",
  "chat.index.noMicTitle": "Microfone não permitido",
  "chat.index.noMicText":
    "O acesso ao microfone está bloqueado pelo seu navegador",
  "chat.asideMenu.voice": "Voz",
  "chat.asideMenu.text": "Texto",
  "chat.asideMenu.newChat": "Nova Conversa",
  "chat.asideMenu.pastConversations": "Conversas anteriores",
  "chat.asideMenu.noConversations": "Sem conversas",
  "chat.asideMenu.autoTranslate": "Tradução Automática",
  "chat.asideMenu.showRomanization": "Mostrar Romanização",
  "chat.asideMenu.multiLingualSpeech": "Fala Multilíngue",
  "chat.suggestions.titleSm": "Sugestão sobre o que dizer",
  "chat.suggestions.titleXs": "Sugestão",
  "chat.suggestions.regenerate": "Regenerar",
  "chat.suggestions.failed":
    "Falha ao obter sugestão. Por favor, tente regenerar.",
  "chat.speakInput.speak": "Falar",
  "chat.speakInput.recording": "Gravando",
  "chat.textInput.sendMessage": "Enviar uma mensagem",
  "chat.autoSubmitSwitch.handsFree": "Mãos-livres",
  "chat.shortcuts.hold": "Segure",
  "chat.shortcuts.press": "Pressione",
  "chat.shortcuts.release": "Solte",
  "chat.shortcuts.toRecord": "para gravar",
  "chat.shortcuts.toSend": "para enviar",
  "chat.shortcuts.toCancel": "para cancelar",
  "chat.userMessageActions.speechPrecision": "Precisão de Fala",
  "chat.userMessageActions.improveMyAnswer": "Melhorar Minha Resposta",
  "chat.userMessageActions.retry": "Tentar Novamente",
  "chat.feedbackMessageButton.improveMyAnswer":
    "Melhorar Minha Última Resposta",
  "chat.feedbackMessageButton.feedbackOn": "Feedback em",
  "chat.feedbackMessageButton.speechPrecision": "Precisão de Fala",
  "chat.assessmentButton.title": "Avaliação da Conversa",
  "chat.assessmentButton.close": "Fechar",
  "chat.assessmentButton.analyzing": "Analisando toda a conversa",
  "chat.assessmentButton.tooShort": "Conversa é muito curta para avaliar",
};

export default l;
