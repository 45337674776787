import { useEffect, useState } from "react";
import { AppError } from "../utils/app-error";

export function useRetries(
  isFetching: boolean,
  error: AppError | undefined,
  retryCount = 3
) {
  const [errorCount, setErrorCount] = useState(0);
  const [failed, setFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const newCount = errorCount + 1;

    if (newCount === retryCount) {
      setFailed(true);
    }

    if (error) {
      setErrorCount(newCount);
    } else {
      setErrorCount(0);
      setFailed(false);
    }
  }, [error]);

  useEffect(() => {
    if (errorCount === 0) {
      setIsLoading(isFetching);
    } else if (errorCount !== retryCount) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFetching, errorCount, retryCount]);

  return {
    isLoading,
    failed,
  };
}
