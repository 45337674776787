import {
  Text,
  Button,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Link,
} from "@chakra-ui/react";
import { captureException } from "@sentry/nextjs";
import React from "react";
import { siteConfig } from "../configuration/config";
import { logger } from "../utils/logger";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logger.error("Client side error", errorInfo);

    captureException(error, {
      extra: {
        origin: "ErrorBoundary",
        errorInfo,
      },
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Flex
          w="full"
          h="full"
          alignItems={"center"}
          justifyContent="center"
          flexDir="column"
          maxW="700px"
          p={3}
          mx="auto"
        >
          <Heading size="xs" textAlign={"center"}>
            An unexpected error has occurred <br /> please try the following
            steps
          </Heading>

          <OrderedList p={8}>
            <ListItem>Refresh the page</ListItem>
            <ListItem>Check your internet connection</ListItem>
            <ListItem>Ensure your browser is up to date</ListItem>
            <ListItem>Clear your browser cookies and cache</ListItem>
          </OrderedList>

          <Text mb={8} textAlign={"center"}>
            If the issue persists, please contact support at{" "}
            <Link
              textDecor={"underline"}
              href={`mailto:${siteConfig.adminEmail}`}
            >
              {siteConfig.adminEmail}
            </Link>
          </Text>

          <Button
            colorScheme="brand.tertiary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </Flex>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
