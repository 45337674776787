import { PromptTemplateDTO } from "../../../database/entity/PromptTemplate";

export const topics: PromptTemplateDTO[] = [
  {
    type: "topic",
    status: "listed",
    slug: "experiencias-viagem-destinos-favoritos",
    title: "Experiências de viagem e destinos favoritos",
    description:
      "Compartilhe suas histórias de viagem e lugares favoritos para visitar.",
    prompt:
      "Discuss your travel experiences and share your favorite destinations along with the stories behind them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "hobbies-interesses",
    title: "Hobbies e interesses",
    description: "Fale sobre seus hobbies e interesses pessoais.",
    prompt:
      "Talk about your hobbies and interests, and what makes them special to you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "comida-culinaria",
    title: "Comida e culinária",
    description: "Fale sobre seus alimentos favoritos e técnicas culinárias.",
    prompt:
      "Share your love for food and cooking, including your favorite recipes and techniques.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "metas-pessoais-aspiracoes",
    title: "Metas pessoais e aspirações",
    description: "Compartilhe suas metas pessoais e aspirações futuras.",
    prompt:
      "Discuss your personal goals and aspirations, and how you plan to achieve them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "cultura-pop-entretenimento",
    title: "Cultura pop e entretenimento",
    description:
      "Fale sobre as últimas novidades em cultura pop e entretenimento.",
    prompt:
      "Talk about current trends in pop culture and entertainment, and your thoughts on them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "familia-relacionamentos",
    title: "Família e relacionamentos",
    description: "Fale sobre a dinâmica familiar e relacionamentos.",
    prompt:
      "Share your experiences and views on family dynamics and relationships.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "esportes-atletismo",
    title: "Esportes e atletismo",
    description: "Discuta seus esportes favoritos e atividades atléticas.",
    prompt:
      "Talk about your favorite sports, athletic activities, and what they mean to you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "aspiracoes-carreira-satisfacao-trabalho",
    title: "Aspirações de carreira e satisfação no trabalho",
    description:
      "Compartilhe suas metas de carreira e pensamentos sobre satisfação no trabalho.",
    prompt:
      "Discuss your career aspirations and what makes you feel satisfied in your job.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "saude-fitness",
    title: "Saúde e fitness",
    description: "Discuta dicas de saúde e rotinas de fitness.",
    prompt:
      "Share your health tips, fitness routines, and how they impact your life.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "tecnologia-inovacao",
    title: "Tecnologia e inovação",
    description:
      "Fale sobre as últimas novidades em tecnologia e ideias inovadoras.",
    prompt:
      "Discuss the latest advancements in technology and innovative ideas that excite you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "livros-literatura",
    title: "Livros e literatura",
    description: "Discuta seus livros favoritos e obras literárias.",
    prompt:
      "Share your favorite books and literary works, and why they resonate with you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "filosofia-questoes-existenciais",
    title: "Filosofia e questões existenciais",
    description:
      "Compartilhe seus pensamentos sobre filosofia e questões existenciais.",
    prompt:
      "Discuss philosophical ideas and existential questions that intrigue you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "musica-musicos",
    title: "Música e músicos",
    description: "Fale sobre sua música e músicos favoritos.",
    prompt:
      "Share your favorite music and musicians, and how they influence your life.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "arte-artistas",
    title: "Arte e artistas",
    description: "Discuta sua arte e artistas favoritos.",
    prompt:
      "Talk about your favorite art pieces and artists, and what you appreciate about them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "educacao-aprendizado",
    title: "Educação e aprendizado",
    description:
      "Compartilhe suas opiniões sobre educação e o processo de aprendizado.",
    prompt:
      "Discuss your thoughts on education and the most effective ways to learn.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "questoes-ambientais-sustentabilidade",
    title: "Questões ambientais e sustentabilidade",
    description: "Discuta questões ambientais e práticas de sustentabilidade.",
    prompt:
      "Share your views on environmental issues and the importance of sustainability.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "espiritualidade-religiao",
    title: "Espiritualidade e religião",
    description: "Fale sobre espiritualidade e crenças religiosas.",
    prompt:
      "Discuss your spiritual journey and religious beliefs, and how they shape your life.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "financas-pessoais-gestao-dinheiro",
    title: "Finanças pessoais e gestão de dinheiro",
    description:
      "Compartilhe dicas sobre finanças pessoais e gestão de dinheiro.",
    prompt:
      "Talk about personal finance strategies and tips for effective money management.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "cinema-producao-filmes",
    title: "Cinema e produção de filmes",
    description:
      "Discuta seus filmes favoritos e técnicas de produção de filmes.",
    prompt:
      "Share your favorite films and discuss the techniques used in filmmaking.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "jardinagem-cuidado-plantas",
    title: "Jardinagem e cuidado com plantas",
    description: "Compartilhe dicas sobre jardinagem e cuidado com plantas.",
    prompt: "Discuss your gardening experiences and share tips for plant care.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "historia-eventos-historicos",
    title: "História e eventos históricos",
    description: "Discuta eventos históricos significativos e seu impacto.",
    prompt:
      "Talk about important historical events and their lasting impact on society.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "video-games-cultura-jogos",
    title: "Vídeo games e cultura de jogos",
    description: "Fale sobre seus vídeo games favoritos e a cultura de jogos.",
    prompt:
      "Share your favorite video games and discuss the culture surrounding gaming.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "fotografia-viagem-videografia",
    title: "Fotografia de viagem e videografia",
    description: "Compartilhe dicas sobre fotografia de viagem e videografia.",
    prompt:
      "Discuss your experiences with travel photography and videography, and share tips.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "moda-tendencias-estilo",
    title: "Moda e tendências de estilo",
    description: "Discuta as últimas tendências em moda e estilo.",
    prompt:
      "Talk about current fashion trends and share your style inspirations.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "ciencia-descobertas-cientificas",
    title: "Ciência e descobertas científicas",
    description: "Discuta descobertas científicas recentes e avanços.",
    prompt:
      "Share your thoughts on recent scientific discoveries and their potential impact.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "psicologia-comportamento-humano",
    title: "Psicologia e comportamento humano",
    description: "Fale sobre o estudo da psicologia e comportamento humano.",
    prompt:
      "Discuss insights from psychology and how they explain human behavior.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "projetos-diy-artesanato",
    title: "Projetos DIY e artesanato",
    description:
      "Compartilhe seus projetos DIY favoritos e ideias de artesanato.",
    prompt: "Discuss your DIY projects and share creative crafting ideas.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "animais-estimacao-cuidados-animais",
    title: "Animais de estimação e cuidados com animais",
    description:
      "Fale sobre seus animais de estimação e dicas de cuidados com animais.",
    prompt:
      "Share stories about your pets and provide tips on caring for animals.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "tecnicas-cozinha-receitas",
    title: "Técnicas de cozinha e receitas",
    description: "Compartilhe suas técnicas de cozinha favoritas e receitas.",
    prompt:
      "Discuss your favorite cooking techniques and share delicious recipes.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "exploracao-espacial-astronomia",
    title: "Exploração espacial e astronomia",
    description: "Fale sobre exploração espacial e descobertas astronômicas.",
    prompt:
      "Discuss the latest in space exploration and exciting astronomical discoveries.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "linguas-linguistica",
    title: "Línguas e linguística",
    description: "Discuta línguas e o estudo da linguística.",
    prompt:
      "Talk about different languages and the fascinating field of linguistics.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "voluntariado-servico-comunitario",
    title: "Voluntariado e serviço comunitário",
    description:
      "Compartilhe suas experiências com voluntariado e serviço comunitário.",
    prompt:
      "Discuss your volunteer work and the importance of community service.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "atividades-ao-ar-livre-esportes-aventura",
    title: "Atividades ao ar livre e esportes de aventura",
    description:
      "Fale sobre suas atividades ao ar livre e esportes de aventura favoritos.",
    prompt:
      "Share your experiences with outdoor activities and adventure sports.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "decoracao-casa-design-interiores",
    title: "Decoração de casa e design de interiores",
    description:
      "Discuta ideias de decoração de casa e dicas de design de interiores.",
    prompt: "Share your home decor ideas and tips for interior design.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "automoveis-automobilismo",
    title: "Automóveis e automobilismo",
    description: "Fale sobre carros e experiências de automobilismo.",
    prompt: "Discuss your favorite cars and share your motoring experiences.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "arte-digital-design-grafico",
    title: "Arte digital e design gráfico",
    description:
      "Compartilhe seu trabalho e dicas em arte digital e design gráfico.",
    prompt:
      "Discuss your digital art projects and provide tips on graphic design.",
  },
];
