const l = {
  language: "Español",
  "app.error": "Error",
  "app.close": "Cerrar",
  "app.start": "Iniciar",
  "app.noConversations": "Aún no hay conversaciones",
  "app.conversation": "Conversación",
  "app.conversations": "Conversaciones",
  "app.loading": "Cargando...",
  "app.tryAgain": "Por favor, inténtelo de nuevo",
  "app.signOut": "Cerrar sesión",
  "app.next": "Siguiente",
  "app.previous": "Anterior",
  "app.finish": "Terminar",
  "app.cancel": "Cancelar",
  "app.restart": "Reiniciar",
  "layout.unableToConnect": "No se puede conectar al servidor",
  "layout.home": "Inicio",
  "layout.chat": "Sesión diaria",
  "layout.wordbook": "Vocabulario",
  "layout.pronunciation": "Pronunciación",
  "layout.programs": "Programas",
  "layout.settings": "Configuración",
  "layout.account": "Cuenta",
  "layout.manageAccount": "Administrar cuenta",
  "layout.leaveReview": "Dejar una reseña",
  "dashboard.title": "Inicio",
  "dashboard.subtitle": "Seleccione cómo quiere interactuar con su tutor.",
  "dashboard.aiTutors.title": "Tutores de IA",
  "dashboard.aiTutors.description":
    "El tutor seleccionado le estará esperando en la próxima sesión de chat. ¡Hablamos pronto!",
  "dashboard.template.freeTalk.title": "Nueva sesión",
  "dashboard.template.freeTalk.description":
    "Hable de lo que quiera con su tutor",
  "dashboard.template.rolePlay.title": "Juego de roles",
  "dashboard.template.rolePlay.description":
    "Póngase en diferentes roles y represente los escenarios",
  "dashboard.template.topics.title": "Tema",
  "dashboard.template.topics.description": "Hablemos de un tema de su elección",
  "dashboard.template.caseStudy.title": "Discusión de casos",
  "dashboard.template.caseStudy.description":
    "Discuta un caso y responda preguntas al respecto",
  "dashboard.template.groupDiscussion.title": "Discusión en grupo",
  "dashboard.template.groupDiscussion.description":
    "Practique discusiones en grupo sin líder",
  "dashboard.template.quiz.title": "Cuestionario",
  "dashboard.template.quiz.description":
    "Practique palabras y frases con cuestionarios interactivos",
  "dashboard.template.game.title": "Juegos",
  "dashboard.template.game.description":
    "Practique vocabulario con divertidos juegos de palabras",
  "dashboard.template.modal.curated": "Curado",
  "dashboard.template.modal.custom": "Personalizado",
  "dashboard.template.modal.inputContent": "Introduzca su propio contenido",
  "dashboard.conversations.description": "Su lista completa de conversaciones",
  "chat.index.failedConnection":
    "No se puede contactar con el servidor y enviar el mensaje",
  "chat.index.audioFailedToSend": "Error al enviar el mensaje de audio",
  "chat.index.usageCapMessageTrialTitle": "¡Wow, está en racha!",
  "chat.index.usageCapMessageTrialText":
    "Parece que ha aprovechado al máximo su prueba gratuita de 7 días - ¡es increíble! Ha alcanzado el límite de caracteres para este período. ¿Desea continuar ahora mismo? Puede actualizar su plan en cualquier momento y seguir justo donde lo dejó.",
  "chat.index.usageCapMessageTrialFooterText":
    "Estamos aquí para acompañarlo en cada paso del camino.",
  "chat.index.usageCapMessagePlanTitle": "¡Atención!",
  "chat.index.usageCapMessagePlanText":
    "Ha disfrutado mucho de todas las funciones este mes, ¡y nos encanta! Ha alcanzado su límite de uso para el plan actual. Para que la experiencia siga siendo fluida, tómese un momento para revisar nuestras diferentes opciones de planes.",
  "chat.index.usageCapMessagePlanFooterText":
    "No hay prisa - su plan actual se restablecerá el próximo mes. Nos alegramos de tenerle con nosotros.",
  "chat.index.visitAccount": "Ir a la cuenta",
  "chat.index.noMicTitle": "Micrófono no permitido",
  "chat.index.noMicText":
    "El acceso al micrófono está bloqueado por su navegador",
  "chat.asideMenu.voice": "Voz",
  "chat.asideMenu.text": "Texto",
  "chat.asideMenu.newChat": "Nuevo chat",
  "chat.asideMenu.pastConversations": "Conversaciones antiguas",
  "chat.asideMenu.noConversations": "No hay conversaciones",
  "chat.asideMenu.autoTranslate": "Traducción automática",
  "chat.asideMenu.showRomanization": "Mostrar romanización",
  "chat.asideMenu.multiLingualSpeech": "Habla multilingüe",
  "chat.suggestions.titleSm": "Sugerencia sobre qué decir",
  "chat.suggestions.titleXs": "Sugerencia",
  "chat.suggestions.regenerate": "Regenerar",
  "chat.suggestions.generating": "Generando...",
  "chat.suggestions.failed":
    "No se pudo obtener la sugerencia. Por favor, intente regenerar.",
  "chat.conversation.tryAgain": "Adelante, inténtelo de nuevo",
  "chat.conversation.cancel": "Cancelar",
  "chat.speakInput.speak": "Hablar",
  "chat.speakInput.recording": "Grabación",
  "chat.textInput.sendMessage": "Enviar un mensaje",
  "chat.autoSubmitSwitch.handsFree": "Manos libres",
  "chat.shortcuts.hold": "Mantener",
  "chat.shortcuts.press": "Presionar",
  "chat.shortcuts.release": "Publicar",
  "chat.shortcuts.toRecord": "para grabar",
  "chat.shortcuts.toSend": "para enviar",
  "chat.shortcuts.toCancel": "para cancelar",
  "chat.userMessageActions.speechPrecision": "Precisión del habla",
  "chat.userMessageActions.speechPrecisionDescription":
    "La precisión del habla indica con qué seguridad el tutor ha interpretado su mensaje. Un porcentaje más alto significa una mayor precisión en la comprensión de lo que se dijo.",
  "chat.userMessageActions.speechPrecisionShowInfo": "Mostrar información",
  "chat.userMessageActions.speechPrecisionHideInfo": "Ocultar información",
  "chat.userMessageActions.improveMyAnswer": "Mejorar mi respuesta",
  "chat.userMessageActions.retry": "Reintentar",
  "chat.feedbackMessageButton.improveMyAnswer": "Mejorar mi última respuesta",
  "chat.feedbackMessageButton.feedbackOn": "Comentarios sobre",
  "chat.feedbackMessageButton.speechPrecision": "Precisión del habla",
  "chat.assessmentButton.title": "Evaluación de la conversación",
  "chat.assessmentButton.close": "Cerrar",
  "chat.assessmentButton.analyzing": "Analizando toda la conversación",
  "chat.assessmentButton.tooShort":
    "La conversación es demasiado corta para evaluarla",
  "settings.title": "Cofiguración",
  "settings.subtitle": "Personalice su experiencia con el tutor.",
  "settings.language": "Idioma",
  "settings.yourLanguage": "Su idioma",
  "settings.uiLanguage": "Idioma de la interfaz",
  "settings.practiceLanguage": "Idioma de práctica",
  "settings.proficiency": "Nivel de competencia",
  "settings.tutor": "Tutor",
  "settings.name": "Nombre",
  "settings.talkingSpeed": "Velocidad de habla",
  "settings.speechInput": "Entrada de voz",
  "settings.precisionVoiceInputDescription":
    "Ofrece una precisión excepcional para los principales idiomas latinos y germánicos, incluidos inglés, alemán, español, francés e italiano, así como idiomas asiáticos como coreano, tailandés y japonés. Si desea utilizar el método de conversación cruzada y habilitar el reconocimiento de voz multilingüe, seleccione el motor Estándar.",
  "settings.standardVoiceInputDescription":
    "Motor versátil que admite todos nuestros idiomas. Es ideal para la conversación cruzada y el reconocimiento de voz multilingüe.",
  "settings.autoPunctuation": "Puntuación automática",
  "settings.autoPunctuationDescription":
    "Automáticamente añade los signos de puntuación cuando hace una pausa ",
  "settings.multiLingualSpeechRecognition": "Reconocimiento de voz multilingüe",
  "settings.multiLingualSpeechRecognitionDescription":
    "El tutor reconocerá el habla tanto en el idioma de práctica como en su idioma nativo",
  "settings.translations": "Traducciones",
  "settings.autoTranslate": "Traducción automática",
  "settings.autoTranslateDescription":
    "Traduce automáticamente los mensajes del tutor a tu idioma",
  "settings.showRomanization": "Mostrar romanización",
  "settings.showRomanizationDescription":
    "Muestra la romanización del último mensaje de chat para los idiomas con escritura",
  "wordbook.title": "Vocabulario",
  "wordbook.subtitle":
    "Haga un seguimiento de las palabras que aprende; su tutor le ayudará a utilizarlas para mejorar su memoria.",
  "wordbook.noWord": "No hay palabras guardadas para el idioma elegido",
  "wordbook.yourPracticeLanguage": "Su idioma de práctica",
  "wordbook.word": "Palabra",
  "wordbook.addToWordbook": "Agregar al Vocabulario",
  "wordbook.pleaseEnterIn": "Por favor ingrese una palabra en",
  "wordbook.selectWord": "Seleccione una palabra",
  "pronunciation.title": "Pronunciación",
  "pronunciation.subtitle": "Practique la pronunciación y la fluidez",
  "pronunciation.intro":
    "El tutor generará frases adecuadas, adaptadas a su nivel de competencia, que está configurado como",
  "pronunciation.startPracticing": "Comience a practicar",
  "pronunciation.done": "Listo",
  "pronunciation.cancel": "Cancelar",
  "pronunciation.tryAgain": "Por favor, inténtelo de nuevo",
  "pronunciation.wordByWord.loading": "Cargando...",
  "pronunciation.wordByWord.stopReading": "Dejar de leer",
  "pronunciation.wordByWord.readSentence": "Leer frase",
  "pronunciation.wordByWord.newSentence": "Nueva frase",
  "pronunciation.wordByWord.retrySentence": "Reintentar frase",
  "pronunciation.saveProgress.progressSaved":
    "Su progreso de pronunciación ha sido guardado",
  "pronunciation.saveProgress.progressNotSaved":
    "No se pudo guardar su progreso de pronunciación. Por favor, verifique su conexión e inténtelo de nuevo.",
  "pronunciation.saveProgress.saveProgress":
    "Guarde la frase y la puntuación para hacer un seguimiento de su progreso, y vuelva a intentarlo más tarde.",
  "pronunciation.saveProgress.saved": "Guardado",
  "pronunciation.saveProgress.saveSentenceAndAssessment":
    "Guardar frase y evaluación",
  "pronunciation.assessmentStats.noResults": "No hay resultados para mostrar",
  "pronunciation.assessmentStats.pronunciation": "Pronunciación",
  "pronunciation.assessmentStats.pronunciationScore":
    "Puntuación de pronunciación",
  "pronunciation.assessmentStats.pronunciationDescription":
    "La Puntuación de Pronunciación representa la calidad de la pronunciación en el discurso. Se calcula combinando la Puntuación de Precisión, la Puntuación de Fluidez y la Puntuación de Integridad, a cada una de las cuales se le asigna un peso específico.",
  "pronunciation.assessmentStats.accuracy": "Precisión",
  "pronunciation.assessmentStats.accuracyScore":
    "Precisión de la pronunciación",
  "pronunciation.assessmentStats.accuracyDescription":
    "La precisión de la pronunciación mide hasta qué punto las palabras pronunciadas coinciden con las de un hablante nativo. Las puntuaciones de precisión para sílabas, palabras y texto completo, se basan en la precisión a nivel de fonema y se ajustan de acuerdo con los objetivos de la evaluación.",
  "pronunciation.assessmentStats.completeness": "Integridad",
  "pronunciation.assessmentStats.completenessScore": "Integridad",
  "pronunciation.assessmentStats.completenessDescription":
    "La integridad del discurso se determina comparando el número de palabras pronunciadas con el número total de palabras en el texto de referencia.",
  "pronunciation.assessmentStats.fluency": "Fluidez",
  "pronunciation.assessmentStats.fluencyScore": "Fluidez",
  "pronunciation.assessmentStats.fluencyDescription":
    "La fluidez del discurso. La fluidez indica hasta qué punto el discurso se asemeja al uso que hace un hablante nativo de las pausas entre palabras.",
  "pronunciation.assessmentStats.keepPracticing": "¡Siga practicando!",
  "pronunciation.assessmentStats.makingProgress": "¡Está progresando!",
  "pronunciation.assessmentStats.wellDone": "¡Bien hecho!",
  "pronunciation.assessmentStats.almostThere": "¡Ya casi está!",
  "pronunciation.assessmentStats.excellentJob": "¡Excelente trabajo!",
  "pronunciation.history.saveAssessments": "Evaluaciones guardadas",
  "pronunciation.history.noAssessments": "Aún no hay evaluaciones",
  "pronunciation.history.score": "Puntuación",
  "pronunciation.history.tryAgain": "Inténtelo de nuevo",
  "intro.title": "Bienvenido a {serviceName}",
  "intro.subtitle": "Comencemos con algunas preguntas",
  "intro.userLanguageTitle": "Idioma",
  "intro.userLanguageSubtitle": "¿Cuál es su idioma nativo?",
  "intro.practiceLanguageTitle": "Idioma",
  "intro.practiceLanguageSubtitle": "¿Qué idioma quiere practicar?",
  "intro.proficiencyTitle": "Nivel de competencia",
  "intro.proficiencyDescription":
    "¿Cuál es su nivel de competencia en {practiceLanguage}?",
  "intro.completionModal.title": "¡Ya está todo listo!",
  "intro.completionModal.buttonText": "Es hora de empezar a hablar",
  "hooks.useConversation.noSpeech":
    "No se detectó el habla o el volumen es demasiado bajo",
  "hooks.useAssessment.unableToFetchPinyin": "No se pudo obtener pinyin",
  "hooks.useAssessment.unableToCreateSpeechRecognizer":
    "No se puede conectar al servidor de evaluación de pronunciación",
  "hooks.useAssessment.noSpeech": "No se detectó el habla",
  "hooks.useAssessment.retryError": "Error",
  "hooks.useAssessment.retryErrorDescription":
    "No se pudo cargar la evaluación guardada",
  "hooks.useWordbook.deleteConfirm":
    "¿Está seguro de que desea eliminar esta palabra?",
  "hooks.useWordbook.unableToDeleteWord":
    "No se pudo eliminar la palabra. Por favor, inténtelo de nuevo en unos segundos.",
  "components.conversationDownloadButton.export": "Exportar chat",
  "components.micPermissionButton.notAllowed":
    "Acceso al micrófono no permitido",
  "components.micPermissionButton.turnOnMicAndSound":
    "Activar micrófono y sonido",
  "components.micPermissionButton.pleaseAllow":
    "Por favor, permita el acceso al micrófono",
  "components.languageSelector.managedByOrganization":
    "Gestionado por la organización",
  "components.languageSelector.selectLanguage": "Seleccionar idioma",
  "components.languageSelector.fullList": "Mostrar lista completa",
  "components.languageSelector.simpleList": "Mostrar lista simplificada",
  "components.proficiencySelector.helpMeChoose": "Ayúdeme a elegir",
  "components.proficiencySelector.descriptionsTitle":
    "Descripciones de nivel de competencia",
  "components.proficiencySelector.descriptionsSubtitle":
    "Aquí están las descripciones de los diferentes niveles de competencia lingüística.",
  "components.proficiencySelector.beginnerLabel": "Principiante",
  "components.proficiencySelector.beginnerDescription":
    "La persona puede usar expresiones básicas para satisfacer necesidades prácticas con comunicación clara y buena disposición de los demás.",
  "components.proficiencySelector.elementaryLabel": "Elemental",
  "components.proficiencySelector.elementaryDescription":
    "La persona entiende temas importantes, se comunica eficazmente en asuntos rutinarios, y puede explicar sus orígenes y necesidades.",
  "components.proficiencySelector.intermediateLabel": "Intermedio",
  "components.proficiencySelector.intermediateDescription":
    "La persona entiende y puede comunicar información básica sobre temas comunes, gestiona situaciones de viaje, y expresa experiencias personales, opiniones y planes.",
  "components.proficiencySelector.upperIntermediateLabel":
    "Intermedio superior",
  "components.proficiencySelector.upperIntermediateDescription":
    "La persona entiende textos teórico-prácticos complejos, se comunica fluidamente con hablantes nativos, genera textos completos, y expresa su punto de vista sobre temas actuales.",
  "components.proficiencySelector.advancedLabel": "Avanzado",
  "components.proficiencySelector.advancedDescription":
    "La persona comprende textos complejos con significados implícitos, se comunica con fluidez y destreza, y genera textos bien construidos y coherentes sobre temas complicados.",
  "components.proficiencySelector.close": "Cerrar",
  "components.readSpeedSlider.speed": "Velocidad",
  "components.readSpeedSlider.verySlow": "Muy lento",
  "components.readSpeedSlider.slow": "Lento",
  "components.readSpeedSlider.normal": "Normal",
  "components.readSpeedSlider.fast": "Rápido",
  "components.readSpeedSlider.veryFast": "Muy rápido",
  "components.speechRecognitionSelector.voiceInputEngine":
    "Motor de entrada de voz",
  "components.speechRecognitionSelector.default": "Estándar",
  "components.speechRecognitionSelector.alternative": "Precisión",
  "components.saveWord.save": "Guardar",
  "components.saveWord.notUnderstood":
    "La IA no entendió esta palabra. Por favor, inténtelo de nuevo.",
  "components.saveWord.failed":
    "No se pudo guardar. Por favor, inténtelo de nuevo.",
  "components.saveWord.savedToWordbook": "guardado en su vocabulario",
  "components.wordInformation.content.description": "Descripción",
  "components.wordInformation.content.sentences": "Frases",
  "components.textWithActions.readAloud": "Leer en voz alta",
  "components.textWithActions.saveWord": "Guardar palabra",
  "components.textWithActions.learnMore": "Aprender más",
  "components.pwaPromptIos.title": "Instale {app} en su dispositivo",
  "components.pwaPromptIos.step1": "Toque el ícono de compartir",
  "components.pwaPromptIos.step2":
    "Seleccione 'Agregar a la pantalla de inicio'",
  "components.pwaPromptIos.step3": "¡No se necesita App Store!",
  "components.pwaPromptAndroid.title": "Instalar la aplicación",
  "chat.userMessage.perfectSentence1": "¡Gran frase! ¡Siga así!",
  "chat.userMessage.perfectSentence2": "¡Buen trabajo!",
  "chat.userMessage.perfectSentence3": "¡Bien hecho!",
  "settings.liveFeedback": "Comentarios instantáneos",
  "settings.liveFeedbackDescription":
    "Muestra comentarios sobre cómo mejorar su respuesta después de cada mensaje.",
  "dashboard.template.dailySession.title": "Sesión diaria",
  "dashboard.template.dailySession.with": "con",
  "dashboard.template.dailySession.description":
    "Únase a la conversación de hoy para practicar y mejorar sus habilidades lingüísticas",
  "dashboard.template.dailySession.button": "Comenzar sesión diaria",
  "feedbackCard.loader.analyzeGrammar": "Analizando gramática...",
  "feedbackCard.loader.analyzeVocabulary": "Analizando vocabulario...",
  "feedbackCard.loader.analyzeStructure":
    "Analizando estructura de la oración...",
  "feedbackCard.error.message":
    "Lo sentimos, pero tuvimos un problema al procesar sus comentarios. Por favor, actualice para volver a intentarlo",
  "feedbackCard.improvedAnswerLabel": "Respuesta mejorada",
  "feedbackCard.grammarLabel": "Gramática",
  "feedbackCard.vocabularyLabel": "Vocabulario",
  "feedbackCard.structureLabel": "Estructura",
};

export default l;
